import React, { useRef } from 'react';
import { toPng } from 'html-to-image';
import ChartEntreprise from '../Bilan Entreprise';

function CreeImage() {
  const ref = useRef();

  const downloadImage = () => {
    toPng(ref.current)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'my-image.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  };

  return (
    <div>
      <div style={{ display: 'none' }} ref={ref}>
          <ChartEntreprise/>
      </div>
      <button style={{marginTop : "5vh"}} onClick={downloadImage}>Download image</button>
    </div>
  );
}

export default CreeImage;

