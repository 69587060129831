import React, { createContext, useContext, useState } from 'react';

const FormDataContext = createContext();

export const FormDataProvider = ({ children }) => {
    const [formData, setFormData] = useState({
    avion: 'vide', // 'vide', 'en cours', 'fini'
    voiture: 'vide',
    train: 'vide',
    tc: 'vide',
    placement: 'vide',
    alimentaire: 'vide',
    domicile: 'vide',
    dechet: 'vide',
    });

    return (
    <FormDataContext.Provider value={{ formData, setFormData }}>
        {children}
    </FormDataContext.Provider>
    );
};

export const useFormData = () => {
    return useContext(FormDataContext);
};
