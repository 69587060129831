import React, { useEffect, useState, useContext, useRef } from 'react';
import "./BilanPart.css"
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/Index";
import Graphique from "../../components/Bilan/index"
import CardPetiteDroite from '../../components/CardPetiteDroite';
import SousTitre from '../../components/SousTitre';
import ChiffreSousChiffre from '../../components/CardPetiteDroite/Chiffre';
import ListDeroule from '../../components/Form/ListDeroule';
import TitreH2 from '../../components/TitreH2/index';
import TitreBG from '../../components/TitreBG/index';
import PlanetIcon from "../../components/img/planete-terre.png";
import TShirtIcon from "../../components/img/t-shirt.png";
import ArbreIcon from "../../components/img/arbre.png";
import Ligne1 from '../../components/Bilan/Ligne1';
import Ligne2 from '../../components/Bilan/Ligne2';
import Bouton from '../../components/Form/Bouton';
import CardBilan from '../../components/Bilan/CardBilan';
import Band1 from '../../components/Bilan/Band1';
import Band2 from '../../components/Bilan/Band2';
import { useNavigate } from 'react-router-dom';
import Popup from '../../components/Popup';
import StepperLine from '../../components/StepperLine/index-test';
import { AccountCircle } from '@mui/icons-material';
import html2canvas from 'html2canvas';

import { useFormProgress } from '../../components/FormProgressContext';
import { ConsoDictContext } from '../../components/BilanInter/consoDictContext';
import {useAppelDict} from '../../components/BilanInter/appelDictContext'
import AppelAPIAvion from '../../components/Api/AppelAPIAvion';
import AppelAPIVoiture from '../../components/Api/AppelAPIVoiture';
import AppelAPIMoto from '../../components/Api/AppelAPIMoto';
import AppelAPITaxi from '../../components/Api/AppelAPITaxi';
import AppelAPITrain from '../../components/Api/AppelAPITrain';
import AppelAPITC2 from '../../components/Api/AppelAPITC2';
import AppelAPITC from '../../components/Api/AppelAPITC';
import AppelAPIAlimentaire from '../../components/Api/AppelAPIAlimentaire';
import AppelAPIDomicile from '../../components/Api/AppelAPIDomicile';
import AppelAPIDechet from '../../components/Api/AppelAPIDechet';


const BilanPart = () => {
    const navigate = useNavigate();
    const { formStatus, setFormStatus } = useFormProgress();
    const [rapportComparaison, setRapportComparasion] = useState("");
    const { sumDict } = useContext(ConsoDictContext);
    const graphContainerRef = useRef(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('wptoken'));
    const [username, setUsername] = useState(localStorage.getItem('wpusername'));
    const { appelDict, setAppelDict } = useAppelDict();
    const [elementsToRender, setElementsToRender] = useState([]);
    const [lancerSave, setLancerSave] = useState(true);
    const [bcSent, setbcSent] = useState(false)


    useEffect(() => {
        // Cette fonction s'exécute une seule fois, au premier montage du composant
        if(!bcSent){
            
            console.log("Début du traitement du dictionnaire");
            console.log(appelDict)
            const newElements = [];
            Object.entries(appelDict).forEach(([classe, elements]) => {
                console.log(`Classe : ${classe}`)
                elements.forEach((appelAPI, index) => {
                    let categorie = ""
                    let apiUrl = ""
                    let data = ""
                    Object.entries(appelAPI).forEach(([classeAppel, appelData]) => {
                        if (classeAppel == "apiUrl"){
                            apiUrl = appelData
                        }
                        else if (classeAppel != "apiUrl"){
                            categorie = classeAppel
                            data = appelData
                        }
                    })
                    console.log(categorie)

                    if(categorie == "appelAvion"){
                        setLancerSave(true)
                        newElements.push(<AppelAPIAvion filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelVoiture"){
                        setLancerSave(true)
                        newElements.push(<AppelAPIVoiture filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelMoto"){
                        setLancerSave(true)
                        newElements.push(<AppelAPIMoto filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelTaxi"){
                        setLancerSave(true)
                        newElements.push(<AppelAPITaxi filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelTrain"){
                        setLancerSave(true)
                        newElements.push(<AppelAPITrain filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelTC"){
                        setLancerSave(true)
                        newElements.push(<AppelAPITC filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelTC2"){
                        setLancerSave(true)
                        newElements.push(<AppelAPITC2 filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelAlimentaire"){
                        setLancerSave(true)
                        newElements.push(<AppelAPIAlimentaire filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelDomicile"){
                        setLancerSave(true)
                        newElements.push(<AppelAPIDomicile filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelDomicileMaison"){
                        setLancerSave(true)
                        newElements.push(<AppelAPIDomicile filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelDechet"){
                        setLancerSave(true)
                        newElements.push(<AppelAPIDechet filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    });
            });
            setElementsToRender(newElements);
            console.log("Fin du traitement du dictionnaire");
            setbcSent(true)
        }
    }, []); // Dépendance vide : exécute seulement au montage


    
    const EQUIVALENCES = {
    tourDuMonde: {constante: 0.218, unit: 40000}, // KgCO2eq/km
    tShirt: {constante: 6.43, unit: 1000}, // KgCO2eq/an
    absorbtionArbre: {constante: 25, unit: 100} //KgCO2e/an
    }
    const imgLinks = {
    img1: "https://www.goodplanet.info/wp-content/uploads/2022/04/001-MA1605N-3310.jpg",
    img2: "https://www.goodplanet.org/wp-content/uploads/2017/04/Paralaxe-INDE-1024x512.jpg",
    img3: "https://www.goodplanet.org/wp-content/uploads/2023/11/GoodPlanet-300-%C3%97-250-px-2.gif",
    }
    const links = {
    lnk1: "https://www.goodplanet.info/2022/04/04/lessentiel-nouveau-rapport-giec-solutions-attenuer-le-changement-climatique/",
    lnk2: "https://www.goodplanet.org/fr/programmes/action-carbone-solidaire/",
    lnk3: "https://www.goodplanet.org/fr/institut-de-formation-myplanet/",
    }
    const textLinks = {
    txt1: "L’essentiel sur le nouveau rapport du Giec consacré aux solutions pour réduire les émissions de gaz à effet de serre et atténuer le changement climatique",
    txt2: "Découvrir les projets de réduction des émissions de GES/d'action pour le climat de FGP",
    txt3: "Se former vous pour agir et réduire l'impact de votre activité professionnelle",
    }

    const franceNumbers = {
    consoTotalAvion: 0.42,
    consoTotalTC: 0.18,
    consoTotalTrain: 0.05,
    consoTotalVoiture: 2.1,
    consoTotalDomicile: 1.3,
    consoTotalDechet: 1.15,
    consoTotalPlacement: 0.09,
    consoTotalAlimentation: 2.45,
    consoTotalServiceP: 1.3
    };

    const generateGraphImage = async () => {
    const node = graphContainerRef.current;
    const scale = 2;
    if (node) {
        const canvas = await html2canvas(node, {
        scale,
        useCORS: true,
        height: node.clientHeight + 100,
        });
        const imageUrl = canvas.toDataURL('image/jpeg');
        setImageUrl(imageUrl);
    }
    return imageUrl;
    };

    const sumFrance = Object.values(franceNumbers).reduce((acc, curr) => acc + curr, 0);

    const getConsoFromDict = (sumDict, consoType) => {
    const voyages = sumDict.find(item => item.consoType === consoType);

    if (!voyages || !voyages.sumConso) {

        return (0).toFixed(1);

    } else if (typeof voyages.sumConso === 'number' || typeof voyages.sumConso === 'string') {

        return voyages.sumConso;

    } else {
        const totalConso = Object.values(voyages.sumConso).reduce((total, voyage) => {
        return total + (voyage.conso ? parseFloat(voyage.conso) : 0)
        }, 0);

        return totalConso.toFixed(1)
    }
    };

    const getTotalConsumption = (sumDict) => {
    return (1.3 + sumDict.reduce((total, item) => {
        return total + (getConsoFromDict(sumDict, item.consoType) ? parseFloat(getConsoFromDict(sumDict, item.consoType)) : 0);
    }, 0)).toFixed(1);
    };
    const totalConsumption = getTotalConsumption(sumDict);

    const handleListDerouleChange = (selectedValue) => {
    // Logique de gestion du changement de valeur du ListDeroule
    console.log("ListDeroule value:", selectedValue);
    };

    const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/bilan-2');
    window.scrollTo(0, 0);
    };

    const handleButtonClickBilanFinal = (event) => {
    event.preventDefault();
    navigate('/bilan-4');
    window.scrollTo(0, 0);
    };

    const handleButtonClickBilanFinancier = (event) => {
    event.preventDefault();
    navigate('/placement');
    }

    const [isPopupOpen, setPopupOpen] = useState(false);

    const handleButtonClickConnexion = (event) => {
    event.preventDefault();
    navigate("/Connection")
    }

    const handleButtonClickDeconnexion = (event) => {
    event.preventDefault();
    localStorage.removeItem('wptoken');
    localStorage.removeItem('wpusername');
    setToken(null);
    setUsername(null);
    }

    useEffect(() => {
    setPopupOpen(true);
    }, []);

    // useEffect(() => {
    //     generateGraphImage();
    // })


    const handlePopupClose = () => {
    setPopupOpen(false);
    };

    useEffect(() => {
    // Mettre à jour le statut du formulaire
    setFormStatus({
        ...formStatus,
        bilan: 'en cours',
    });
    }, []);

    useEffect(() => {
    setToken(localStorage.getItem('wptoken'));
    setUsername(localStorage.getItem('wpusername'));
    }, []);

    const getEquivalence = (key, totalConsumption) => {
    const consoTotale = totalConsumption;
    const consoConstante = EQUIVALENCES[key].constante;
    const equi = (consoTotale * 1000 / consoConstante) / EQUIVALENCES[key].unit;
    return equi.toFixed(1);
    }

    return (
    <div className='page'>     
        <div style={{zIndex : "999"}}>
        {!username && (
        <Popup isOpen={isPopupOpen} onClose={handlePopupClose}>
            <div className='row col-12 d-flex justify-content-between align-items-center'>
            <div className="col-8">
                <TitreH2 Titre="Bilan de vos émissions annuelles" className="titre" couleur="#000000" />
            </div>
            {username ? (
                <div className="col-auto d-flex align-items-center">
                    <div className='circle-bilan-disconnect' onClick={handleButtonClickDeconnexion}>
                    <AccountCircle style={{ fontSize: '40px', color: 'green' }} />
                    <div className='text-bilan'>Bienvenue {username}</div>
                    <Bouton titre="Déconnexion" onClick={handleButtonClickDeconnexion} />
                    </div>
                </div>
            ) : (
                <div className="col-auto d-flex align-items-center">
                <div className='circle-bilan' onClick={handleButtonClickConnexion}>
                    <AccountCircle style={{ fontSize: '40px' }} />
                    <div className='text-bilan'>Connectez-vous ou créez un compte</div>
                </div>
                </div>
            )}
            </div>
            <div className="row col-6" style={{marginTop : "10vh"}}>
                    <CardPetiteDroite
                    className="emission-card"
                    color={{ backgroundColor: "black" }}                
                    contenu={
                        <div >
                        <ChiffreSousChiffre
                        Titre={"Vos émissions"}
                        Chiffre={totalConsumption}
                        SousChiffre={"Tonnes de CO₂"}
                        style={{
                            padding:"1px",
                            height: "65%",     
                            marginTop: "1px",
                        }}
                        />
                        </div>
                    }
                    />
                </div>
            <div style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            }}>
            <div className='row col-12 d-flex justify-content-center'>
            {/* <div className='col-lg-6 col-12 d-flex justify-content-center'>
                <Bouton titre="Réduire mes émissions" onClick={handleButtonClick} /> 
            </div> */}
            <div className='col-lg-6 col-12 d-flex justify-content-center'>
                <Bouton titre="Voir mon bilan sans créer de compte" onClick={handlePopupClose} />
            </div>
            </div>
            </div>
        </Popup>
        )}
        </div>
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLine />
        
        <div className='col-10 offset-1'>
        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>
        <div className="row">

            <div className="col-10">
                <TitreH2 Titre="Bilan de vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
            
        </div>

        <div className="row">

            <div>
                {elementsToRender}
            </div>

            <div className="col-9">


                <h1 className = "h31">Répartition de vos émissions</h1>

                <div ref={graphContainerRef}>

                <SousTitre SousTitre="Mes émissions " />

                <p className = "p1">A vos émissions annuelles, 1,3 tCO₂ s’ajoutent liées au fonctionnement de nos services publics. </p>

                <Graphique sumDict={sumDict} franceNumbers={franceNumbers} />
                </div>
            </div>


            <div className="col-3">
                <div className="row col-12" style={{marginTop : "3vh"}}>
                <CardPetiteDroite
                    color={{ backgroundColor: "black", height : "150px" }}
                    taille={{ height : "150px"}}                    
                    contenu={
                    <ChiffreSousChiffre
                        Titre={"Vos émissions"}
                        Chiffre={totalConsumption}
                        SousChiffre={"Tonnes de CO₂"}
                        style={{
                        height: "80%",     
                        marginTop: "10px",
                        fontSize:"12px",
                        }}
                    />
                    }
                />
                </div>

                <div className="row justify-content-center">
                {username ? (
                    <div className="col-auto d-flex align-items-center">
                    <div className='circle-bilan-disconnect' onClick={handleButtonClickDeconnexion}>
                        <AccountCircle style={{ fontSize: '40px', color: 'green' }} />
                        <div className='text-bilan'>Bienvenue {username}</div>
                        <Bouton titre="Déconnexion" onClick={handleButtonClickDeconnexion} />
                    </div>
                    </div>
                ) : (
                    <div className="col-auto d-flex align-items-center">
                    <div className='circle-bilan' onClick={handleButtonClickConnexion}>
                        <AccountCircle style={{ fontSize: '40px' }} />
                        <div className='text-bilan'>Connectez-vous ou créez un compte</div>
                    </div>
                    </div>
                )}
                </div>
                
                {token && (
                <div className="row offset-1" style={{marginTop : "5vh"}}>
                    <ListDeroule
                    titre="Se comparer à"
                    value="National"
                    onChange={handleListDerouleChange}
                    text="Mon rapport 2023"
                    storageKey="listDerouleValue"
                    mots = {["Mon rapport 2022", "Mon rapport 2021"]}
                    />
                </div>
                )}
                <div className="row col-10 offset-1"    style={{marginTop : "5vh"}}>
                <CardPetiteDroite
                    color={{ backgroundColor: "black", height : "100px" }}
                    taille={{ height : "100px"}}
                    contenu={
                    <ChiffreSousChiffre
                        Chiffre={sumFrance}
                        SousChiffre={"Tonnes de CO₂"}                    
                        style={{
                        height: "80%",     
                        marginTop: "10px"
                        }}
                    />
                    }
                />
                </div>
                <div 
                className="row offset-1"
                style={{
                marginTop : "3vh",
                fontFamily: 'Montserrat',
                fontStyle: "normal",
                fontWeight: "800",
                fontSize: "10px",
                lineHeight: "12px",
                fontVariant: "all-small-caps",
                }}    
                >

                <Bouton titre = "Mon empreinte totale"    onClick={handleButtonClickBilanFinal}/>
                <Bouton titre = "Calculer mon empreinte financière"    onClick={handleButtonClickBilanFinancier}/>

                </div>
            </div>

            </div>

            <div className="row" style={{marginTop : "8vh", backgroundColor: "#F5F5F5"}}>
                <div className="col-4">
                <Ligne1 logo = {PlanetIcon} titre = {"Tour du monde en voiture thermique"} titregras={"tour(s) du monde"} floatNumber={getEquivalence("tourDuMonde", totalConsumption)} />
                </div>
                <div className="col-4"> 
                <Ligne1 logo = {TShirtIcon} titre = {"T-shirts en coton produits"} titregras={"milliers de t-shirt(s)"} floatNumber={getEquivalence("tShirt", totalConsumption)}/>
                </div>
                <div className="col-4">
                <Ligne1 logo = {ArbreIcon} titre = {"Arbres nécessaires pour absorber"} titregras={"centaine(s) d'arbres"} floatNumber={getEquivalence("absorbtionArbre", totalConsumption)}/>
                </div>
            </div>

            <div className="row justify-content-center" style={{marginTop : "5vh"}}>
                <div className="col-4">
                <Bouton titre="Réduire mes émissions" onClick={handleButtonClick}/>
                </div>
                <div className="col-4">
                <Bouton titre = {"VOIR LE Bilan détaillé"} onClick={handleButtonClickBilanFinal}/>
                </div>
            </div>

            <div className='row col-12' style={{marginTop : "1vh"}}>
                <Band1 generateGraphImage={generateGraphImage} totalConsumption={totalConsumption} />
            </div>

            <div className="row justify-content-center" style={{marginTop : "5vh"}}>
                <div className="col-12">
                <h1 className = "h31">Contribution à la neutralité carbone</h1>
                </div>
                <div className="col-12">
                <p className='p1'>
                L’Accord de Paris est un traité international adopté par 196 parties lors de la COP21 de Paris en 2015. L’objectif est de limiter le réchauffement climatique en deçà de 2°C, et de tout faire pour le maintenir à 1,5°C par rapport à l’ère préindustriel. Pour se faire, Etat, individus et entreprises doivent travailler main dans la main pour réduire les émissions de gaz à effet de serre et augmenter les puits de carbone dans l’objectif d’atteindre une neutralité carbone mondial.    
                </p>
                </div>
            </div>

            <div className='row col-12'>
                <CardBilan/>
            </div>

            <div className="row" style={{marginTop : "10vh"}}>
                <div className="col-4">
                <Ligne2 text = {textLinks.txt1} lien={links.lnk1} image={imgLinks.img1}/>
                </div>
                <div className="col-4">
                <Ligne2 text = {textLinks.txt2} lien={links.lnk2} image={imgLinks.img2}/>
                </div>
                <div className="col-4">
                <Ligne2 text = {textLinks.txt3} lien={links.lnk3} image={imgLinks.img3}/>
                </div>
            </div>

            <div className='row col-12' style={{marginTop : "5vh"}}>
                <Band2/>
            </div>
            
        </div>

        <div className="row justify-content-center">
            <div className="col-8">
                {/* Pied de page */}
                <Footer contenue="Cet outil est une version en test, pour nous partager vos remarques & suggestions vous pouvez nous écrire à entreprise@goodplanet.org" />
            </div>
            </div>

        </div>
    </div>
    );
};

export default BilanPart;

