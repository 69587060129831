import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../Particulier/Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import Compteur from '../../../components/Compteur/Compteur';
import CheckboxChoixUnique from '../../../components/Form/checkBox/checkbox';
import CheckboxChoixUniqueAdapt from '../../../components/Form/checkBox/checkbox-adapt';
import imageEnergie from "../../../components/img/Energie.png"
import { Padding } from '@mui/icons-material';
import Bouton from '../../../components/Form/Bouton';
import FormDomicile from '../../../components/Form/Form Particulier/FormDomicile';
import FormLieu from '../../../components/Form/Form Event/FormLocal';
import FormDeplacementEquipe from '../../../components/Form/Form Event/FormDeplacementEquipe';
import StepperLineEvent from '../../../components/StepperLine/index-event';

const DeplacemenetEquipeEvent = () => {
    const navigate = useNavigate();
    const [consommationConnue, setConsommationConnue] = useState(false);
    const reponses = ['≤5    A', '6 à 10    B', '11 à 20 C', '21 à 35 D', '36 à 55 E', '56 à 80 F', '> 80 G'];



    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLineEvent/>

        <div className="container">
            <div className="row">
            <div className="row col-10 offset-1">
                <Titre Titre="Déplacement - Equipes organisatrices"    place="start"/>
            </div>
            </div>

            <div className="row col-10 offset-1">
            <FormDeplacementEquipe/>
            </div>
            {/* <div className="row col-12">
            <div className="col-6"><Compteur titre="Nombre de personnes vivant avec vous dans le logement"/></div>
            </div>
            <div className="row col-12">
            <div className="col-6"><Compteur titre="Surface du logement"/></div>
            </div>
            <div className="row">
            <div className="col-12"><CheckboxChoixUnique onChange={(value) => setConsommationConnue(value)} titre="Connaissez-vous la catégorie de votre logement dans l’étiquette climat (kgeqCO2/m².an) ?" rep1="oui" rep2="non"/></div>
            </div>

            <div className="row col-12">
            <div className="col-4"><CheckboxChoixUniqueAdapt titre="Quelle est votre étiquette climat ?" reponses={reponses} /></div>
            <div className="col-6"><img src={imageEnergie} /></div>
            <div className="col-2" style={{height : "150px", paddingLeft : "50px"}} >
                <div className='p3' style={{backgroundColor : "#EBE085", padding : "10%"}}>
                l’étiquette climat pour connaître la quantité de gaz à effet de serre émise
                (à droite sur l’image) n’est pas à confondre avec l’étiquette energie à gauche. 
                </div>
            </div>
            </div> */}

                
            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>Déplacements liés aux événements</div>
        <p>Cette catégorie prend en compte les déplacements des organisateurs, des participants et des intervenants jusqu’au lieu de l’événement et du lieu d’hébergement, s’il y en a un, au lieu de l’événement. Les émissions générées par les déplacements prennent en compte la partie amont du carburant (extraction, transport, raffinerie) ainsi que sa combustion. Pour les versions de la Base Carbone antérieures à la 21.0, la fabrication du véhicule n’est pas incluse dans les facteurs d’émissions.</p>
        <p>Pour évaluer la consommation d’énergie liée aux déplacements domicile-travail, vous pouvez réaliser une enquête auprès d’un échantillon représentatif d’organisateurs et d’intervenants que vous extrapolerez à l’ensemble. Il faut indiquer le total des déplacements en prêtant attention à l’unité : lorsque l’unité mentionnée est kgCO2e/passager.km et non kgCO2e/km, il s’agit d’un transport en commun dont le facteur d’émission a été rapporté à l’empreinte carbone d’un individu.</p>
    </div>
} />

            </div>
            </div>

            <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div>

        </div>
        </div>
    </div>
    </>
    );
};

export default DeplacemenetEquipeEvent;

