import React from 'react';
import CheckboxLabels from './index.js';

const CheckboxLabelsWithLinks = ({ title, labels, links, checked, onChange }) => {
  const handleLinkClick = (url) => {
    window.open(url, '_blank');
  };

  const labelsWithLinks = labels.map((label, index) => {
    if (links[index]) {
      return {
        key: label.key,
        label: (
          <span key={index}>
            {label.label.split(' ').map((word, wordIndex, words) => {
              if (word.toLowerCase() === 'cgu' || word.toLowerCase() === 'statistiques') {
                return (
                  <span
                    key={wordIndex}
                    style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => handleLinkClick(links[index])}
                  >
                    {word}
                  </span>
                );
              }
              return `${word}${wordIndex < words.length - 1 ? ' ' : ''}`;
            })}
          </span>
        )
      };
    }
    return label;
  });

  return <CheckboxLabels title={title} labels={labelsWithLinks} checked={checked} onChange={onChange} />;
};

export default CheckboxLabelsWithLinks;