import React from 'react';
import './CardDroite.css';
import { colors } from '@mui/material';

const CardPetiteDroite = ({ contenu, onClick, color, taille, backgroundImage }) => {
  const cardStyle = {
    ...taille,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none', 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white' 
  };

  return (
    <div className="default-card-droit" onClick={onClick} style={cardStyle}>
      <div className="rectangle-61" style={color}>
        <div className="rectangle-78">
          {contenu}
        </div>
      </div>
    </div>
  );
}

export default CardPetiteDroite;

