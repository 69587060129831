import React from 'react';
import './GraphiqueEntreprise.css';
import Card from '../Card';
import ContenueCardBilan from './ContenuCardBilan';

const CardBilan = ({}) => {
  // Données pour les éléments du graphique
  
  return (
      <div>
        <Card color={"#EBE085"} contenu={<ContenueCardBilan/>}/>
      </div>
  );
};

export default CardBilan;
