import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../Particulier/Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import Compteur from '../../../components/Compteur/Compteur';
import CheckboxChoixUnique from '../../../components/Form/checkBox/checkbox';
import CheckboxChoixUniqueAdapt from '../../../components/Form/checkBox/checkbox-adapt';
import imageEnergie from "../../../components/img/Energie.png"
import { Padding } from '@mui/icons-material';
import Bouton from '../../../components/Form/Bouton';
import FormDomicile from '../../../components/Form/Form Particulier/FormDomicile';
import FormLieu from '../../../components/Form/Form Event/FormLocal';
import FormRepas from '../../../components/Form/Form Event/FormRepas';
import StepperLineEvent from '../../../components/StepperLine/index-event';

const Repas = () => {
    const navigate = useNavigate();
    const [consommationConnue, setConsommationConnue] = useState(false);
    const reponses = ['≤5    A', '6 à 10    B', '11 à 20 C', '21 à 35 D', '36 à 55 E', '56 à 80 F', '> 80 G'];



    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLineEvent/>

        <div className="container">
            <div className="row">
            <div className="col-12">
                <Titre Titre="Restauration"    place="start"/>
            </div>
            </div>

            <div className="row col-10 offset-1">
            <FormRepas/>
            </div>
            
                
            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>Alimentation</div>
        <p>Les émissions liées à notre alimentation sont très dépendantes du type de repas consommé. C’est pourquoi, afin de pouvoir évaluer les émissions liées à l’alimentation, l’ADEME a calculé les facteurs d’émissions de différents repas à partir de menus types, respectant les apports nutritionnels nécessaires pour un adulte (entrée, plat, dessert).</p>
        <p>Voici les repas types pris en compte :</p>
        <ul>
            <li>Végétarien</li>
            <li>Classique (poulet)</li>
            <li>Classique (bœuf)</li>
            <li>À dominante végétale (poulet)</li>
            <li>À dominante végétale (bœuf)</li>
        </ul>
        <p>Les repas type poisson et végétalien ne figurent pas dans la base carbone. Ainsi, le facteur d’émission d’un repas à base de poisson a été calculé à partir d’un menu classique en remplaçant le plat de viande par un plat de poisson. De même, le facteur d’émission d’un repas végétalien a été calculé à partir du menu végétarien en remplaçant les œufs par un substitut végétalien.</p>
        <p>Les facteurs d’émissions du petit déjeuner, du buffet, du goûter et du panier repas, sont calculés à partir de menus type. Il prend en compte les aliments présents dans le menu ainsi qu’un grammage moyen pour un adulte.</p>
        <ul>
            <li>Petit déjeuner : 1 café, 1 verre de jus de fruits et 1 croissant.</li>
            <li>Un panier repas : 1 sandwich jambon beurre emmental, une pomme, une barre chocolatée, une bouteille d’eau (50cl).</li>
            <li>Un buffet par personne : Une quinzaine de pièces salées et 7 à 8 bouchées sucrées dont des toasts/canapés salés, quiches lorraines, éclairs, madeleine, tartelette aux fruits.</li>
            <li>Un goûter : Une pièce de pâtisserie, 1 jus de fruit et des pièces salées.</li>
        </ul>
    </div>
} />

            </div>
            </div>

            <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div>

        </div>
        </div>
    </div>
    </>
    );
};

export default Repas;

