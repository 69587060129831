import React from 'react';
import "./ContenueLogo.css"

const ContenueLogo = ({ Logo, Titre, tailleLogo, SousTitre }) => {
  // Détermine la classe de colonne en fonction de la taille du logo
  const getColSize = () => {
    return tailleLogo === 6 ? "col-6" : "col-4";
  };

  return (
    <div className="containerLogo">
      <div className="container">
        {/* Row pour le titre */}
        <div className="row justify-content-center">
          <div className="col-10">
            {/* Titre */}
            <div className='h21 Avion'>{Titre}</div>
          </div>
        </div>
        {/* Row pour le sous-titre */}
        <div className="row justify-content-center">
          <div className="col-12">
            {/* Sous-titre */}
            <h2 className='Avion h61'>{SousTitre}</h2>
          </div>
        </div>
        {/* Row pour le logo */}
        <div className="row justify-content-center align-items-center">
          <div className={"col-6 col-md-3 justify-content-center align-items-center"}>
            {/* Logo */}
            <img src={Logo} className="ImageAvion" alt="Image" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContenueLogo;


