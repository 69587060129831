import React, { createContext, useState, useContext } from 'react';

// Créer le contexte
const appelDictContext = createContext();

export const AppelDictProvider = ({ children }) => {
    const [appelDict, setAppelDict] = useState({});

    return (
        <appelDictContext.Provider value={{ appelDict, setAppelDict }}>
            {children}
        </appelDictContext.Provider>
    );
};

// Custom hook pour utiliser le contexte
export const useAppelDict = () => useContext(appelDictContext);
