import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../Particulier/Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import CarreLogoCompteur from '../../../components/CarreLogo/CarreLogoCompteur';
import LogoCereale from "../../../components/img/cereale.png";
import LogoCont from "../../../components/img/continental.png";
import LogoSale from "../../../components/img/sale.png";
import LogoVege from "../../../components/img/vege.png";
import LogoVegetalien from "../../../components/img/vegetalien.png";
import LogoPoisson from "../../../components/img/poisson.png";
import LogoViande from "../../../components/img/viande.png";
import LogoVolail from "../../../components/img/volaille.png";
import LogoThe from "../../../components/img/the.png";
import LogoCafe from "../../../components/img/cafe.png";
import LogoBiere from "../../../components/img/biere.png";
import LogoJus from "../../../components/img/jus.png";
import LogoSodas from "../../../components/img/soda.png";


import SousTitre from '../../../components/SousTitre';
import TitreH2 from '../../../components/TitreH2';
import Compteur from '../../../components/Compteur/Compteur';
import FormAchat from '../../../components/Form/Form Entreprise/FormAchat';
import StepperLineEntreprise from '../../../components/StepperLine/index-entreprise';

const Achat2 = () => {
    const navigate = useNavigate();


    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLineEntreprise/>

        <div className="container">
            <div className="row">
            <div className="row col-10 offset-1">
                <Titre Titre="Achats de biens et services "    place="start"/>
            </div>
            </div>

            <div className="row col-10 offset-1">
        <FormAchat/>
        </div>
                
            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>Consommables et Papier</div>
        <p>Pour les consommables, les émissions sont calculées en utilisant des ratios monétaires, sauf pour le papier où il est possible de renseigner le nombre de ramettes. Il est admis qu’une ramette de papier A3 correspond à 2 ramettes de papier A4 et que le grammage moyen est de 80g/m². À titre informatif, un collaborateur d’une entreprise du secteur tertiaire consomme en moyenne 75 kg de papier par an.</p>
    </div>
} />

            </div>
            </div>

            <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME. " />
            </div>
            </div>

        </div>
        </div>
    </div>
    </>
    );
};

export default Achat2;

