import React from "react";
import "./SousTitre.css";

function SousTitre(props) {
    const { SousTitre, place } = props;
    const styleTitre = {
    justifyContent: place === 'center' ? 'center' : 'start',
    };

    return (
    <div className={`h41 vert ${place === 'center' ? 'text-center' : ''}`} style={styleTitre}>
        {SousTitre}
    </div>
    );
}

export default SousTitre;

