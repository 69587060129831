import React, { useEffect, useContext } from 'react';
import { useState } from 'react';
import './BilanInter.css';
import LogoAvion from "../img/LogoAvion.png";
import LogoVoiture from "../img/LogoVoiture.png";
import LogoTrain from "../img/train.png";
import LogoTC from "../img/TC.png";
import LogoAl from "../img/Al.png";
import LogoDom from "../img/dom.png";
import LogoDechet from "../img/dechet.png";
import LogoPlacement from "../img/Visite.png";

import AppelAPIBilanAvion from '../Api/APIBilan/APIBilanAvion';
import AppelAPIBilanVoiture from '../Api/APIBilan/APIBilanVoiture';
import AppelAPIBilanMoto from '../Api/APIBilan/APIBilanMoto';
import AppelAPIBilanVLoc from '../Api/APIBilan/APIBilanVLoc';
import AppelAPIBilanTaxi from '../Api/APIBilan/APIBilanTaxi';
import AppelAPIBilanTrain from '../Api/APIBilan/APIBilanTrain';
import AppelAPIBilanTC from '../Api/APIBilan/APIBilanTC';
import AppelAPIBilanPlacement from '../Api/APIBilan/APIBilanPlacement';
import AppelAPIBilanAlimentaire from '../Api/APIBilan/APIBilanAlimentaire';
import AppelAPIBilanDomicile from '../Api/APIBilan/APIBilanDomicile';
import AppelAPIBilanDechet from '../Api/APIBilan/APIBilanDechet';
import Titre from '../Titre';
import SousTitre from '../SousTitre';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { ConsoDictContext } from '../../components/BilanInter/consoDictContext';


const BilanFinal = ({ numeroEtape, handleDeleteVoyage, voyage }) => {

  const [apiResponseAvion, setApiResponseAvion] = useState(null);
  const [apiResponseVoiture, setApiResponseVoiture] = useState(null);
  const [apiResponseMoto, setApiResponseMoto] = useState(null);
  const [apiResponseVLoc, setApiResponseVLoc] = useState(null);
  const [apiResponseTaxi, setApiResponseTaxi] = useState(null);
  const [apiResponseTrain, setApiResponseTrain] = useState(null);
  const [apiResponseTC, setApiResponseTC] = useState(null);
  const [apiResponsePlacement, setApiResponsePlacement] = useState(null);
  const [apiResponseAlimentaire, setApiResponseAlimentaire] = useState(null);
  const [apiResponseDomicile, setApiResponseDomicile] = useState(null);
  const [apiResponseDechet, setApiResponseDechet] = useState(null);
  const [sumAvion, setSumAvion] = useState(0);
  const [sumVoiture, setSumVoiture] = useState(0);
  const [sumTrain, setSumTrain] = useState(0);
  const [sumTC, setSumTC] = useState(0);
  const [sumPlacement, setSumPlacement] = useState(0);
  const [sumAlimentaire, setSumAlimentaire] = useState(0);
  const [sumDomicile, setSumDomicile] = useState(0);
  const [sumDechet, setSumDechet] = useState(0);
  const [sumTotal, setSumTotal] = useState(0);

  const { sumDict } = useContext(ConsoDictContext)
  
  const getConsoFromDict = (sumDict, consoType) => {
    const voyages = sumDict.find(item => item.consoType === consoType);

    if (!voyages || !voyages.sumConso) {

      return (0).toFixed(1);

    } else if (typeof voyages.sumConso === 'number' || typeof voyages.sumConso === 'string') {

      return voyages.sumConso;

    } else {
      const totalConso = Object.values(voyages.sumConso).reduce((total, voyage) => {
        return total + (voyage.conso ? parseFloat(voyage.conso) : 0)
      }, 0);

      return totalConso.toFixed(1)
    }
  };

  const getTotalConsumption = (sumDict) => {
    return sumDict.reduce((total, item) => {
      return total + (getConsoFromDict(sumDict, item.consoType) ? parseFloat(getConsoFromDict(sumDict, item.consoType)) : 0);
    }, 0).toFixed(1);
  };

  const afficherVoyages = (sumDict, consoType) => {
    const voyages = sumDict.find(item => item.consoType === consoType);

    if (typeof voyages === 'object') {
      const voyageInfo = Object.values(voyages.sumConso).map((voyage, index) => {
        const info = voyage.info;
        const consoVoyage = (voyage.conso == null ? "0":(voyage.conso).toFixed(1))
        return (
          <div className="containerB" key={index}>
            <div className="contentB row">
              <span className="textB col-7 offset-1">{info}</span>
              {/* <div className="textB col-4"> X - test tonne(s) de CO2</div> */}
              <div className="textB col-4"> {consoVoyage} tonne(s) de CO₂</div>
              <div className="icon-container float-end">
                <DeleteOutlinedIcon className="Icon" onClick={() => handleDeleteVoyage(index)} />
              </div>
            </div>
          </div>
        )
      });
      return voyageInfo;
    }
  }
  
//   useEffect(()=>{
//   <AppelAPIBilanAvion Lancer= {true} setApiResponse={setApiResponseAvion}/>;
//   <AppelAPIBilanVoiture Lancer= {true} setApiResponse={setApiResponseVoiture}/>;
//   <AppelAPIBilanMoto Lancer= {true} setApiResponse={setApiResponseMoto}/>;
//   <AppelAPIBilanVLoc Lancer= {true} setApiResponse={setApiResponseVLoc}/>;
//   <AppelAPIBilanTaxi Lancer= {true} setApiResponse={setApiResponseTaxi}/>;
//   <AppelAPIBilanTrain Lancer= {true} setApiResponse={setApiResponseTrain}/>;
//   <AppelAPIBilanTC Lancer= {true} setApiResponse={setApiResponseTC}/>;
//   <AppelAPIBilanPlacement Lancer= {true} setApiResponse={setApiResponsePlacement}/>;
//   <AppelAPIBilanAlimentaire Lancer= {true} setApiResponse={setApiResponseAlimentaire}/>;
//   <AppelAPIBilanDomicile Lancer= {true} setApiResponse={setApiResponseDomicile}/>;
//   <AppelAPIBilanDechet Lancer= {true} setApiResponse={setApiResponseDechet}/>;


//   const apiResponseAvionString=JSON.stringify({apiResponseAvion});
//   const apiResponseAvionParsed=JSON.parse(apiResponseAvionString);
//   setSumAvion(apiResponseAvion ? (apiResponseAvionParsed.responseAPI.ConsoTotalVoyage):0);

  

//   if (apiResponseVoiture || apiResponseMoto || apiResponseVLoc || apiResponseTaxi) {
//     // Vérifier si l'objet responseAPIVoiture contient la clé "responseAPI" et la clé "ConsoTotalVoiture"
//     if (apiResponseVoiture.responseAPI && apiResponseVoiture.responseAPI.ConsoTotalVoiture) {
//       setSumVoiture(apiResponseVoiture.responseAPI.ConsoTotalVoiture);
//     }
//     else if (apiResponseMoto.responseAPI && apiResponseVoiture.responseAPI.ConsoTotalMoto) {
//       setSumVoiture(apiResponseMoto.responseAPI.consoTotalMoto);
//     }
//     else if (apiResponseVLoc.responseAPI && apiResponseVLoc.responseAPI.ConsoVoitureLoc) {
//       setSumVoiture(apiResponseVLoc.responseAPI.ConsoVoitureLoc);
//     }
//     else if (apiResponseTaxi.responseAPI && apiResponseTaxi.responseAPI.ConsoTotalTaxi) {
//       setSumVoiture(apiResponseTaxi.responseAPI.ConsoTotalTaxi);
//     }
//   }


//   const apiResponseTrainString=JSON.stringify({apiResponseTrain});
//   const apiResponseTrainParsed=JSON.parse(apiResponseTrainString);
//   setSumTrain(apiResponseTrain ? (apiResponseTrainParsed.responseAPI.ConsoTrain):0);

//   const apiResponseTCString=JSON.stringify({apiResponseTC});
//   const apiResponseTCParsed=JSON.parse(apiResponseTCString);
//   setSumTC(apiResponseTC ? (apiResponseTCParsed.responseAPI.ConsoTC):0);


//   const apiResponsePlacementString=JSON.stringify({apiResponsePlacement});
//   const apiResponsePlacementParsed=JSON.parse(apiResponsePlacementString);
//   setSumPlacement(apiResponsePlacement ? (apiResponsePlacementParsed.responseAPI.ConsoPlacements):0);


//   const apiResponseAlimentaireString=JSON.stringify({apiResponseAlimentaire});
//   const apiResponseAlimentaireParsed=JSON.parse(apiResponseAlimentaireString);
//   setSumAlimentaire(apiResponseAlimentaire ? (apiResponseAlimentaireParsed.responseAPI.ConsoAlimentaire):0);


//   const apiResponseDomicileString=JSON.stringify({apiResponseDomicile});
//   const apiResponseDomicileParsed=JSON.parse(apiResponseDomicileString);
//   setSumDomicile(apiResponseDomicile ? (apiResponseDomicileParsed.responseAPI.ConsoDomicile):0);

//   const apiResponseDechetString=JSON.stringify({apiResponseDechet});
//   const apiResponseDechetParsed=JSON.parse(apiResponseDechetString);
//   setSumDechet(apiResponseDomicile ? (apiResponseDechetParsed.responseAPI.ConsoTotalDechetConsommation):0);


//   setSumTotal(sumAvion + sumTrain + sumTC + sumPlacement + sumAlimentaire + sumDomicile + sumDechet);
// },[]);

  const collection = [
    {
      type: 'sumAvion',
      text: `Bilan avion : ${getConsoFromDict(sumDict, 'sumAvion')} tonnes de CO₂ par an`,
      image: LogoAvion
    },
    {
      type: 'sumVoiture',
      text: `Bilan voiture : ${getConsoFromDict(sumDict, 'sumVoiture')} tonnes de CO₂ par an`,
      image: LogoVoiture
    },
    {
      type: 'sumTrain',
      text: `Bilan train : ${getConsoFromDict(sumDict, 'sumTrain')} tonnes de CO₂ par an`,
      image: LogoTrain
    },
    {
      text: `Bilan transport en commun : ${getConsoFromDict(sumDict, 'sumTC')} tonnes de CO₂ par an`,
      image: LogoTC
    },
    {
      text: `Bilan alimentation : ${getConsoFromDict(sumDict, 'sumAlim')} tonnes de CO₂ par an`,
      image: LogoAl
    },
    {
      text: `Bilan domicile : ${getConsoFromDict(sumDict, 'sumDomicile')} tonnes de CO₂ par an`,
      image: LogoDom
    },
    {
      text: `Bilan consommation & déchets : ${getConsoFromDict(sumDict, 'sumDechet')} tonnes de CO₂ par an`,
      image: LogoDechet
    },
    // Ajoutez les autres étapes de la collection ici
  ];
  
  const collectionAffichee = collection.slice(0, numeroEtape);

  return (
    <div className='BilanInt'>
      <div className="containerC">
        <div className="contentB">
        <span className="textB" style={{fontWeight : "800", fontSize : "24px"}}>Bilan total : {getTotalConsumption(sumDict)} tonnes de CO₂ par an</span>
        </div>
      </div>

      {collectionAffichee.map((item, index) => (
        <div key={index}>
          <div className="containerB">
            <div className="contentB">
              <div className="imageCont">
              <img className="imageLogo" src={item.image} alt="Image centrée" />
              </div>
              <span className="textB" style={{fontWeight : "600"}}>{item.text}</span>
            </div>
          </div>
        
          {item.type && (
            <div>
              {afficherVoyages(sumDict, item.type)}
            </div>
          )}
        </div>

      ))}

    </div>
  );
}

export default BilanFinal;

