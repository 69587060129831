import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import './form.css';
import Ajout from '../Ajout';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Compteur from '../../Compteur/Compteur';
import CheckboxChoixUnique from '../checkBox/checkbox';
import Hebergement from './Hebergement';
import Titre from '../../Titre';

function FormHebergement({ lien }) {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Soumission du formulaire
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
  };

  // Gestion du clic sur le bouton
  const handleButtonClick = (event) => {
    event.preventDefault();
    //navigate(lien);
  };

  

  return (
    <form onSubmit={handleSubmit}>
      

      <Titre Titre="Hébergement des équipes organisatrices"  place="start"/>
      <Hebergement/>

      <div style={{marginTop : "5vh"}}>
      <Titre Titre="Hébergement des participants"  place="start"/>
      <Hebergement/>
      </div>

      <div className="row">
        <div className="col-12">
          <Bouton titre="CALCULER" />
        </div>
      </div>

      {formSubmitted && (
        <div>
          <div className="row">
            <div className="col-12">
              {/* Composant BilanInter */}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

export default FormHebergement;
