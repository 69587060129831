import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../Particulier/Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import CarreLogoCompteur from '../../../components/CarreLogo/CarreLogoCompteur';
import LogoCereale from "../../../components/img/cereale.png";
import LogoCont from "../../../components/img/continental.png";
import LogoSale from "../../../components/img/sale.png";
import LogoVege from "../../../components/img/vege.png";
import LogoVegetalien from "../../../components/img/vegetalien.png";
import LogoPoisson from "../../../components/img/poisson.png";
import LogoViande from "../../../components/img/viande.png";
import LogoVolail from "../../../components/img/volaille.png";
import LogoThe from "../../../components/img/the.png";
import LogoCafe from "../../../components/img/cafe.png";
import LogoBiere from "../../../components/img/biere.png";
import LogoJus from "../../../components/img/jus.png";
import LogoSodas from "../../../components/img/soda.png";


import SousTitre from '../../../components/SousTitre';
import TitreH2 from '../../../components/TitreH2';
import Compteur from '../../../components/Compteur/Compteur';
import FormDeplacement from '../../../components/Form/Form Entreprise/Deplacement';
import FormVoyageCollaborateur from '../../../components/Form/Form Entreprise/FormVoyageCol';
import StepperLineEntreprise from '../../../components/StepperLine/index-entreprise';

const VoyageCol1 = () => {
    const navigate = useNavigate();


    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLineEntreprise/>

        <div className="container">
            <div className="row">
            <div className="row col-10 offset-1">
                <Titre Titre="Distance parcourue en avion par les collaborateurs dans le cadre de leur travail"    place="start"/>
            </div>
            </div>
            <div className="row col-10 offset-1">
         <FormVoyageCollaborateur/>
         </div>

                
            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>Émissions liées aux déplacements en avion</div>
        <p>Les émissions prises en compte pour les déplacements en avion sont celles liées à la consommation de carburant (amont et combustion), la fabrication du véhicule (pour la version 21.0 et les suivantes de la Base Carbone) et les trainées de condensation (plus d’informations dans Scope 3 &gt; Transport de personnes &gt; Aérien). Les facteurs d’émissions choisis correspondent à des valeurs moyennes à court (&lt;1000 km), moyen (1000-3500 km) et long courrier (&gt; 3500 km).</p>

        <p>Les trainées de condensation se forment à la sortie d’un réacteur par condensation de la vapeur d’eau si l’environnement est suffisamment froid et humide. En persistant dans le ciel, ces trainées blanches s’assimilent à des nuages qui empêchent le rayonnement terrestre, accentuant ainsi le phénomène de réchauffement. En intégrant les trainées dans le calcul des émissions, ce ne sont pas seulement les émissions liées à la combustion du kérosène qui sont prises en compte mais bien l’ensemble des impacts carbone d’un voyage en avion.</p>
        
        <p>Dans le cadre de l’information GES des prestations de transport, les émissions liées aux trainées de condensation ne sont pas obligatoires (article 75 de la loi Grenelle II - bilan GES réglementaire). C’est pourquoi la valeur du facteur d’émission peut être divisée par deux dans certains calculs ne prenant pas en compte les trainées de condensation. Ce calculateur ayant un but pédagogique, nous avons privilégié l’exhaustivité de la méthodologie de l'ADEME à la réglementation, afin de sensibiliser à l’impact carbone des déplacements aériens.</p>
        
        <p>Indiquer la distance totale à vol d’oiseau parcourue par l’ensemble de vos salariés dans le cadre des déplacements professionnels en avion en veillant à distinguer les vols ainsi que les escales dans les catégories suivantes :</p>
        <ul>
            <li>Court courrier : &lt; 1000 km</li>
            <li>Moyen-courrier : 1000 - 3500 km</li>
            <li>Long courrier : &gt; 3500 km</li>
        </ul>
        <p>Par exemple, un vol Paris - New-York direct est à renseigner dans les vols long courrier alors qu’un vol Paris-New-York avec une escale à Londres est à renseigner en court courrier pour le Paris-Londres et en long-courrier pour le Londres – New-York.</p>
    </div>
} />


            </div>
            </div>

            <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div>

        </div>
        </div>
    </div>
    </>
    );
};

export default VoyageCol1;

