import React from 'react';
import './StepperLine-test.css';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TrainIcon from '@mui/icons-material/Train';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HouseIcon from '@mui/icons-material/House';
import RecyclingIcon from '@mui/icons-material/Recycling';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import SurveyIcon from '@mui/icons-material/Assignment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useNavigate } from 'react-router-dom'; // import the useNavigate hook
import { useFormProgress } from '../FormProgressContext';
import { Assignment } from '@mui/icons-material';

const StepperLine = () => {
    const navigate = useNavigate(); // instantiate the navigate function
    const { formStatus } = useFormProgress();

    const getColor = (status) => {
    switch (status) {
        case 'fini': return 'green';
        case 'en cours': return 'orange';
        default: return 'grey';
    }
    };

    const colorBilan = (status) => {
    switch (status) {
        case 'en cours' : return 'blue';
        default: return 'grey';
    }
    }

    return (
    <div className="containerT">

        <div className="circle" onClick={() => navigate('/Introduction')}>
        <InfoOutlinedIcon sx={{ color: getColor(formStatus.intro) }} />
        <div className="textT" style={{color: getColor(formStatus.intro)}}>Introduction</div>
        </div>

        <div className="lineT line-between"></div>
        <div className="circle" onClick={() => navigate('/Particulier')}>
        <FlightIcon sx={{ color: getColor(formStatus.avion) }} />
        <div className="textT" style={{color: getColor(formStatus.avion)}}>Avion</div>
        </div>

        <div className="lineT line-between"></div>
        <div className="circle" onClick={() => navigate('/Voiture-1')}>
        <DirectionsCarIcon sx={{ color: getColor(formStatus.voiture) }}/>
        <div className="textT"    style={{color: getColor(formStatus.voiture)}}>Voiture</div>
        </div>

        <div className="lineT line-between"></div>
        <div className="circle" onClick={() => navigate('/Train')}>
        <TrainIcon sx={{ color: getColor(formStatus.train) }} />
        {/* <div className="line-down"></div> */}
        <div className="textT" style={{color: getColor(formStatus.train)}}>Train</div>
        </div>

        <div className="lineT line-between"></div>
        <div className="circle" onClick={() => navigate('/TC')}>
        <PedalBikeIcon sx={{ color: getColor(formStatus.tc) }} />
        {/* <div className="line-down"></div> */}
        <div className="textT" style={{color: getColor(formStatus.tc)}}>Autres transports</div>
        </div>

        <div className="lineT line-between"></div>
        <div className="circle" onClick={() => navigate('/Alimentaire')}>
        <RestaurantIcon sx={{ color: getColor(formStatus.alimentaire) }} />
        {/* <div className="line-down"></div> */}
        <div className="textT" style={{color: getColor(formStatus.alimentaire)}}>Alimentation</div>
        </div>

        <div className="lineT line-between"></div>
        <div className="circle" onClick={() => navigate('/Domicile')}>
        <HouseIcon sx={{ color: getColor(formStatus.domicile) }} />
        <div className="line-down"></div>
        <div className="textT" style={{color: getColor(formStatus.domicile)}}>Domicile</div>
        </div>

        <div className="lineT line-between"></div>
        <div className="circle" onClick={() => navigate('/Dechet')}>
        <RecyclingIcon sx={{ color: getColor(formStatus.dechet) }} />
        {/* <div className="line-down"></div> */}
        <div className="textT" style={{color: getColor(formStatus.dechet)}}>Consommation et déchets</div>
        </div>

        <div className="lineT line-between"></div>
        <div className="circle" onClick={() => navigate('/bilan')}>
        <SurveyIcon sx={{ color: colorBilan(formStatus.bilan) }} />
        <div className="line-down"></div>
        <div className="textT" style={{color: colorBilan(formStatus.bilan)}}>Empreinte totale</div>
        </div>

        <div className="lineT line-between"></div>
        <div className="circle" onClick={() => navigate('/placement')}>
        <AccountBalanceWalletIcon sx={{ color: getColor(formStatus.placement) }} />
        <div className="line-down"></div>
        <div className="textT" style={{color: getColor(formStatus.placement)}}>Empreinte financière</div>
        </div>
    </div>
    );
}

export default StepperLine;
