import React from 'react';
import './GraphiqueEntreprise.css';

const ChartEntreprise = () => {
  // Données pour les éléments du graphique
  const data = [
    { label: 'Energie', color: '#EBE085', heightCol: 100, heightCol2: 80, subDivs: 500, titre : "2,5t" },
    { label: 'Deplacements domicile-travail', color: '#68C4A5', heightCol: 150, heightCol2: 120, subDivs: 500, titre : "2,5t" },
    { label: 'Déplacements professionnels', color: '#FBAB3B', heightCol: 200, heightCol2: 160, subDivs: 500, titre : "2,5t" },
    { label: 'Fret', color: '#024C4A', heightCol: 250, heightCol2: 200, subDivs: 500, titre : "2,5t" },
    { label: 'Numérique', color: '#313179', heightCol: 300, heightCol2: 240, subDivs: 500, titre : "2,5t" },
    { label: 'Immobilisation', color: '#C14118', heightCol: 250, heightCol2: 280, subDivs: 500, titre : "2,5t" },
    { label: 'Achats de biens et services', color: '#8C8C8C', heightCol: 200, heightCol2: 300, subDivs: 500, titre : "2,5t" },
  ];

  return (
    <div className="chart">
      <div className="legend">
        <div className="row row1">
        <span className="legend-text col-10">Vous</span>
        <div className="legend-box col-1" style={{backgroundColor : 'black'}}>
        </div>
        </div>
        <div className="row">
        <span className="legend-text col-10">Moyenne francaise</span>
        <div className="legend-box col-1">
        <div class="subdiv"></div>
          <div class="subdiv"></div>
          <div class="subdiv"></div>
          <div class="subdiv"></div>
          <div class="subdiv"></div>
          <div class="subdiv"></div>
          <div class="subdiv"></div>
          <div class="subdiv"></div>
          </div>
        </div>
      </div>

      {data.map((item, index) => (
        <div className="Lab" key={index}>
          
          <div className="bar">
            <div className="Col" style={{ height: item.heightCol, backgroundColor: item.color, borderColor : item.color}}>
              <span className="column-text">{item.titre}</span>
            </div>
            
            <div className="ColAf">
            <span className="column-text">{item.titre}</span>

            <div className="Col-2" style={{ height: item.heightCol2 }}>
              {[...Array(item.subDivs)].map((_, subIndex) => (
                <div className="subdiv" key={subIndex} style={{ backgroundColor: item.color }}></div>
              ))}
            </div>
          
          </div>
          </div>
          <label className="labelBar">{item.label}</label>
        </div>
      ))}
    </div>
  );
};

export default ChartEntreprise;
