import React, { Component } from 'react';
import axios from 'axios';

class MyComponent extends Component {
  constructor() {
    super();
    this.state = {
      apiResponse: null,
      error: null
    };
  }

  handleApiCall = async () => {
    try {
      const tokenResponse = await axios.post(
        'https://login.microsoftonline.com/31b31443-2e71-4ddd-9a16-410af6d41e72/oauth2/v2.0/token',
        new URLSearchParams({
          grant_type: 'client_credentials',
          client_id: "936bba49-c55f-4640-a41e-5543df5b753a",
          client_secret: "BgI8Q~n1xrT-qlpoM-1GHa5JSW6IuAEL0dm8sb2l",
          scope: "https://dev-goodplanet.crm12.dynamics.com/.default"
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      const accessToken = tokenResponse.data.access_token;

      const apiResponse = await axios.get('https://dev-goodplanet.crm12.dynamics.com/api/data/v9.1/aps_TestRequeteSite', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      this.setState({ apiResponse: apiResponse.data, error: null });
    } catch (error) {
      this.setState({ apiResponse: null, error: error.message });
    }
  }

  render() {
    const { apiResponse, error } = this.state;

    return (
      <div>
        <button onClick={this.handleApiCall}>Créer Token et Appeler l'API</button>
        <div>
          {apiResponse && (
            <div>
              <h3>Réponse de l'API :</h3>
              <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
            </div>
          )}
          {error && (
            <div>
              <h3>Erreur :</h3>
              <p>{error}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MyComponent;
