import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';
import { IdBCContext } from '../IdBCContext';

const AppelAPILinkContactBilanCarbone = ({mail, lancerLink, setLancerLink, }) => {
    const { token, fetchToken } = useAuth();
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { idBC } = useContext(IdBCContext);
  
    
    useEffect(() => {
      // Vérifier si le token est disponible avant de faire la requête
      if (token && lancerLink) { 
        fetchData();
       }
    }, [token, lancerLink]); // Se ré-exécute lorsque `token` change
  
    const fetchData = async () => {
    setLoading(true);
    const apiUrl = 'https://dev-goodplanet.crm12.dynamics.com/api/data/v9.1/aps_Contact_LinkBilanCarbone';
    const data = {
        IdBC: idBC,
        email: mail
    };
    const options = {
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
    };
    
    try {
        const resultLinkContactBilanCarbone = await axios.post(apiUrl, data, options);

        // Mettre à jour la réponse de l'API dans le composant parent
        // setApiResponse(resultLinkContactBilanCarbone.data);
        setLancerLink(false);

        } catch (error) {
        console.error("Erreur lors de l'envoi de la requête :", error);
        setResponse('Erreur lors de la récupération des données.');
        } finally {
        setLoading(false);
    }
    };


    return (null);
    };
  
  export default AppelAPILinkContactBilanCarbone;
