import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import "./compteur.css";

const Compteur = ({ titre, unit = "", onChange, val = "0", block, italique }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [valeur, setValeur] = useState(val);
  const [inputWidth, setInputWidth] = useState('60px');

  useEffect(() => {
    const numDigits = valeur.length;
    if(numDigits < 2)
    {
      setInputWidth(`${numDigits * 20}px`);
    }
    else
    {
      setInputWidth(`${numDigits * 10}px`);
    }
  }, [valeur]);

  const handleChangeValeur = (event) => {
    const newValue = event.target.value.replace(/\D/g, ''); // Supprimer les caractères non numériques
    setValeur(newValue);
    onChange(newValue);
  };

  const handleClickMinus = (event) => {
    event.preventDefault();
    if (parseInt(valeur) > 0) {
      const newValue = parseInt(valeur) - 1;
      setValeur(String(newValue));
      onChange(String(newValue));
    }
  };

  const handleClickPlus = (event) => {
    event.preventDefault();
    if (!block || (block && parseInt(valeur) < parseInt(val))) {
      const newValue = parseInt(valeur) + 1;
      setValeur(String(newValue));
      onChange(String(newValue));
    }
  };

  const titreStyle = {
    fontStyle: italique ? 'italic' : 'normal',
    fontSize: italique ? "10px" : "",
  };

  return (
    <div className="Compteur">

      <div className='p4 col-12 col-lg justify-content-start' style={titreStyle}>{titre}</div>

      <div className="ajout-input-container col-lg col-12" style={{ textAlign: 'right' }}>
        <button className="ajout-button" onClick={handleClickMinus} disabled={parseInt(valeur) === 0}>
          -
        </button>
        <input
          type='text'
          className="ajout-input"
          value={valeur}
          onChange={handleChangeValeur}
          pattern="[0-9]*"
          style={{ width: inputWidth, maxWidth: '100%' }}
          disabled={block}
        />
        <button className="ajout-button" onClick={handleClickPlus} disabled={block}>
          +
        </button>
        <div className='unitCompteur' style={titreStyle}>{unit}</div>
      </div>

    </div>
  );
};

export default Compteur;
