import React, { useEffect, useState, useContext } from 'react';
import "./BilanPart.css"
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/Index";
import Graphique from "../../components/Bilan/index"
import CardPetiteDroite from '../../components/CardPetiteDroite';
import SousTitre from '../../components/SousTitre';
import ChiffreSousChiffre from '../../components/CardPetiteDroite/Chiffre';
import ListDeroule from '../../components/Form/ListDeroule';
import TitreH2 from '../../components/TitreH2/index';
import TitreBG from '../../components/TitreBG/index';
import image1 from "../../components/img/planet.png";
import image2 from "../../components/img/Trainbilan.png"
import logolegume from "../../components/img/Logolegume.png"
import logoAvion from "../../components/img/LogoAvion.png"
import Ligne1 from '../../components/Bilan/Ligne1';
import Ligne2 from '../../components/Bilan/Ligne2';
import Bouton from '../../components/Form/Bouton';
import CardBilan from '../../components/Bilan/CardBilan';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import Band1 from '../../components/Bilan/Band1';
import Band2 from '../../components/Bilan/Band2';
import CarreLogo from '../../components/CarreLogo/CarreLogo';
import Ensavoir from '../../components/Ensavoir';

import train from "../../components/img/train.png";
import voiture from "../../components/img/voiture.png";
import vetement from "../../components/img/vetement.png";
import vege from    "../../components/img/vege.png";
import mail from "../../components/img/mail.png";
import tel from "../../components/img/tel.png";
import heberge from "../../components/img/heberge.png";
import ecran from "../../components/img/ecran.png";
import thermometre from "../../components/img/thermometre.png";
import economie from "../../components/img/economie-denergie.png";
import gaz from "../../components/img/ChauffageUrbain.png";
import StepperLine from '../../components/StepperLine/index-test';

import { ConsoDictContext } from '../../components/BilanInter/consoDictContext';


const BilanPart2 = () => {
    const { sumDict } = useContext(ConsoDictContext)
    const handleListDerouleChange = (selectedValue) => {
    // Logique de gestion du changement de valeur du ListDeroule
    console.log("ListDeroule value:", selectedValue);
    };
    const [consoEvitee, setConsoEvitee] = useState(0);

    const styles = {
    tabbedList: {
        listStyle: 'none',
        paddingLeft: 0,
        counterReset: 'list-counter',
    },
    tabbedListItem: {
        counterIncrement: 'list-counter',
        marginLeft: '1em',
        position: 'relative',
    }}

    const getConsoFromDict = (sumDict, consoType) => {
    const voyages = sumDict.find(item => item.consoType === consoType);

    if (!voyages || !voyages.sumConso) {

        return (0).toFixed(1);

    } else if (typeof voyages.sumConso === 'number' || typeof voyages.sumConso === 'string') {

        return voyages.sumConso;

    } else {
        const totalConso = Object.values(voyages.sumConso).reduce((total, voyage) => {
        return total + (voyage.conso ? parseFloat(voyage.conso) : 0)
        }, 0);

        return totalConso.toFixed(1)
    }
    };

    const getTotalConsumption = (sumDict) => {
    return (1.3 + sumDict.reduce((total, item) => {
        return total + (getConsoFromDict(sumDict, item.consoType) ? parseFloat(getConsoFromDict(sumDict, item.consoType)) : 0);
    }, 0)).toFixed(1);
    };

    const [consoReduite, setConsoReduite] = useState(getTotalConsumption(sumDict))

    const handleToggle = (co2, isChecked) => {
    setConsoEvitee(prevCO2 => isChecked ? prevCO2 + co2 : prevCO2 - co2);
    };

    useEffect(() => {
    if (getTotalConsumption(sumDict) - (consoEvitee / 1000) >= 1.3) {
        setConsoReduite((getTotalConsumption(sumDict) - (consoEvitee / 1000)).toFixed(1))
    } else {
        setConsoReduite((1.3).toFixed(1))
    }
    }, [consoEvitee])

    return (
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLine />

        <div className='col-10 offset-1'>
        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>
        <div className="row">

            <div className="col-10">
                <TitreH2 Titre="Réduire mes émissions" className="titre" couleur="#000000"/>
            </div>
            
        </div>

        <div className="row">
            
            <div className="col-9">
                <h1 className = "h31">Objectif 2 tonnes</h1>

                {/* <SousTitre SousTitre="Mes émissions" /> */}

                <p className = "p1">Pour repecter les engagements de l'Accord de Paris de 2015, cela implique à la fois une réduction drastique des émissions de GES d'origines anthropiques et une augmentation des puits de carbonne (forêts, océans, sols). Cet objectif de neutralité a été décliné à l'échelle française dans le cadre de la Stratégie natioanle Bas Carbone.</p>
                <p className = "p1">Plus concrètement, pour atteindre ces objectifs, il faudrait que chaque habitant affiche une empreinte carbone équivalente à 2 tonnes de CO₂e par an au niveau mondial.</p>
                <p className = "p1">Pour atteindre 2 tonnes de CO₂e par an, chaque geste compte ! Quels seront vos prochains engagements ?</p>

            </div>
            
            <div className="col-3">
                <div className="row col-12" style={{marginTop : "3vh"}}>
                <CardPetiteDroite
                    color={{ backgroundColor: "black", height : "150px" }}
                    taille={{ height : "150px"}}                    
                    contenu={
                    <ChiffreSousChiffre
                        Titre={"Vos émissions"}
                        Chiffre={getTotalConsumption(sumDict)}
                        SousChiffre={"Tonnes de CO₂"}
                        style={{
                        height: "80%",     
                        marginTop: "10px",
                        fontSize:"12px",
                        }}
                    />
                    }
                />
                </div>
                <div>
                <p style={{fontSize: '12px'}}>1 tonne = 1 000 kilogrammes (kg)</p>
                </div>
            </div>
        </div>

            <div className='row justify-content-center' style={{marginTop : "5vh"}}>
                <div className='col'><CarreLogo titre="Refuser un aller-retour en avion Paris - Bali" titregras="-3 800 kg CO₂ par an" logo={logoAvion} value={3800} onToggle={handleToggle}/></div>
                <div className='col'><CarreLogo titre="Faire une aller-retour Paris-Barcelone en TGV au lieu d'en avion" titregras="-377 kg CO₂ par an" logo={train} value={377} onToggle={handleToggle}/></div>
                <div className='col'><CarreLogo titre="Se passer de voiture pour les trajets < 5 km " titregras="-500 kg CO₂ par an" logo={voiture} value={500} onToggle={handleToggle}/></div>
                <div className='col'><CarreLogo titre="Appliquer l'écoconduite" titregras="-44 kg CO₂ par an	" logo={voiture} value={44} onToggle={handleToggle}/></div>
                <div className='col'><CarreLogo titre="Passer d'un régime 'classique' à flexitarien" titregras="-530 kg CO₂ par an	" logo={logolegume} value={530} onToggle={handleToggle}/></div>
            </div>

            <div className='row justify-content-center' style={{marginTop : "5vh"}}>
                <div className='col'><CarreLogo titre="Passer d'un régime 'classique' à végétarien" titregras="-800 kg CO₂ par an" logo={vege} value={800} onToggle={handleToggle}/></div>
                <div className='col'><CarreLogo titre="Passer d'un régime flexitarien à végétalien	" titregras="-480 kg CO₂ par an" logo={logolegume} value={480} onToggle={handleToggle}/></div>
                <div className='col'><CarreLogo titre="Rénover son logement de 42 m² chauffé au gaz (passage d'une classe énergie D à B)" titregras="-1 800 kg CO₂ par an" logo={gaz} value={1800} onToggle={handleToggle}/></div>
                <div className='col'><CarreLogo titre="Baisser de 1°C la température des pièces" titregras="-300 kg CO₂ par an" logo={thermometre} value={300} onToggle={handleToggle}/></div>
                <div className='col'><CarreLogo titre="Débrancher les appareils en veille" titregras="-50 kg CO₂ par an" logo={economie} value={50} onToggle={handleToggle}/></div>
            </div>

            <div className='row justify-content-center' style={{marginTop : "5vh"}}>
                <div className='col'><CarreLogo titre="Acheter des vêtements d'occasion à la place de neufs" titregras="-52 kg CO₂ par an" logo={vetement} value={52} onToggle={handleToggle}/></div>
                <div className='col'><CarreLogo titre="Acheter un ordinateur reconditionné à la place d’un neuf" titregras="-27 kg CO₂ par an" logo={ecran} value={27} onToggle={handleToggle}/></div>
                <div className='col'><CarreLogo titre="Allonger de 2 ans la durée de vie d’un Smartphone" titregras="-16 kg CO₂ par an" logo={tel} value={16} onToggle={handleToggle}/></div>
                <div className='col'><CarreLogo titre="Diviser par deux le visionnage de vidéos en streaming" titregras="-82 kg CO₂ par an" logo={heberge} value={82} onToggle={handleToggle}/></div>
                <div className='col'><CarreLogo titre="Supprimer 10 000 mails" titregras="-2 kg CO₂ par an" logo={mail} value={2} onToggle={handleToggle}/></div>
            </div>

            <div className='row' style={{
                height: '10vh', // or any specific height
                display: 'flex',
                alignItems: 'center',
                marginTop: '5vh'
            }}>
                <div className="col-12">
                    <TitreH2 Titre={`En réalisant ces actions, vos émissions pourraient diminuer de ${consoEvitee} kg de CO₂`} className="h21 justify-content-center" couleur="#000000"/>
                </div>
            </div>

            <div className="row" style={{marginTop : "8vh"}}>
                <div className='col-4'>
                <CardPetiteDroite
                    color={{ backgroundColor: "black", height : "120%", width : "80%"}}
                    taille={{ height : "150px"}}                    
                    contenu={
                    <ChiffreSousChiffre
                        Titre={"Vos émissions"}
                        Chiffre={getTotalConsumption(sumDict)}
                        SousChiffre={"Tonnes de CO₂"}
                        style={{
                        height: "80%",     
                        marginTop: "10px",
                        fontSize:"12px",
                        }}
                    />
                    
                    }
                />
                </div>
                <div className='col-4'>
                <CardPetiteDroite
                    color={{ backgroundColor: "black", height : "120%", width : "80%" }}
                    taille={{ height : "150px"}}                    
                    contenu={
                    <ChiffreSousChiffre
                        Titre={"Vos émissions après réduction"}
                        Chiffre={consoReduite}
                        SousChiffre={"Tonnes de CO₂"}
                        style={{
                        height: "80%",     
                        marginTop: "10px",
                        fontSize:"12px",
                        }}
                    />
                    
                    }
                />
                </div>
            </div>

            <div className='' style={{marginTop : "3vh"}}>
                <Band1/>
            </div>

            <div className='' style={{marginTop : "5vh"}}>
                <Band2/>
            </div>

            
            <div className='row col-12' style={{marginTop : "5vh"}}>
                <CardBilan/>
            </div>

            <div className="row">
            <div className="col-12">
                {/* En savoir plus */}
                <Ensavoir contenu={
                <div>
                    <div className='h41'>En savoir plus pistes d’actions :</div>
                    <p>Ces estimations ont été réalisées sur la base d’hypothèses détaillées ci-dessous. Il est à noter que les émissions après réduction sont données uniquement à titre indicatif pour mettre en évidence l’impact positif d’actions de réduction sur l’empreinte carbone individuelle. Cependant, il se peut que les pistes d’actions proposées ne correspondent pas à votre situation. Dans ce cas, la valeur indiquée sera sur ou sous-estimé.</p>
                    
                    <p>Hypothèses et données :</p>
                    <ul>
                    <li>Déplacements : les émissions générées par les déplacements ont été estimées sur la base de facteurs d’émissions de la Base empreinte (Avion long-courrier, TGV, voiture moyenne). On considère une réduction de 15% de la consommation de carburant par l’écoconduite.</li>
                    <li>Alimentation : on considère qu’un régime classique est considéré de 5 repas végétariens, 5 repas à dominante viande blanche, 2 repas à dominante viande rouge et 2 repas à dominante poisson. Les facteurs d’émission sont issus de la Base empreinte.</li>
                    <li>Domicile et consommation : on considère un domicile de 42 m² chauffé au gaz. Les données sur les biens de consommation et les déchets sont issus de la base empreinte et d’études de l’Ademe.</li>
                    </ul>
                </div>
                } 
            />
            </div>
            </div>

        </div>
        <Footer />
        </div>
        </div>
    );
};

export default BilanPart2;

