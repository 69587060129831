import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useWordPressAuth } from '../Authentification/WordPressAuth';

const ApiCreeCompte = ({username, mail, pwd, Lancer, setLancer, setApiResponse, setApiError}) => {
    const { token, fetchToken } = useWordPressAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
      if (Lancer) {
        if (token) {
          fetchData();
        } else {
          fetchToken();
          fetchData();
        }
      }
    }, []);


   const fetchData = async () => {
     setLoading(true);
    const apiUrl = 'https://prep.goodplanet.org/wp-json/wp/v2/users';
    
    const data = {
        username: username,
        email: mail,
        password: pwd
    };

    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };


    try {
      const result = await axios.post(apiUrl, data, options);
       // Mettre à jour la réponse de l'API dans le composant parent
       setApiResponse(result);
       setLancer(false);
    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setApiResponse(error);
      setLancer(false);
      setApiError(true);
    } finally {
      setLoading(false);
    }
  };
  return (null);
};

export default ApiCreeCompte;
