import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../Particulier/Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import Compteur from '../../../components/Compteur/Compteur';
import CheckboxChoixUnique from '../../../components/Form/checkBox/checkbox';
import CheckboxChoixUniqueAdapt from '../../../components/Form/checkBox/checkbox-adapt';
import imageEnergie from "../../../components/img/Energie.png"
import { Padding } from '@mui/icons-material';
import Bouton from '../../../components/Form/Bouton';
import FormDomicile from '../../../components/Form/Form Particulier/FormDomicile';
import FormLieu from '../../../components/Form/Form Event/FormLocal';
import FormRepas from '../../../components/Form/Form Event/FormRepas';
import FormLogistique from '../../../components/Form/Form Event/FormLogistique';
import FormNumerique from '../../../components/Form/Form Event/FormNumerique';
import StepperLineEvent from '../../../components/StepperLine/index-event';

const NumeriqueEvent = () => {
    const navigate = useNavigate();
    const [consommationConnue, setConsommationConnue] = useState(false);
    const reponses = ['≤5    A', '6 à 10    B', '11 à 20 C', '21 à 35 D', '36 à 55 E', '56 à 80 F', '> 80 G'];



    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLineEvent/>

        <div className="container">
            <div className="row">
            <div className="row col-10 offset-1">
                <Titre Titre="Numérique"    place="start"/>
            </div>
            </div>

            <div className="row col-10 offset-1">
            <FormNumerique/>
            </div>
            
                
            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>Impact environnemental du Numérique</div>
        <p>Le numérique a un impact important sur les émissions de gaz à effet de serre, d’autant plus si votre événement est organisé en ligne. Le numérique inclut le matériel (si celui-ci est loué, un ratio serait fait en fonction de la durée de l’événement), les données et les informations partagées avant, pendant et après l’événement (posts sur les réseaux sociaux, mails envoyés, visio), ainsi que le stockage éventuel de contenu (vidéos en ligne…).</p>
    </div>
} />

            </div>
            </div>

            <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div>

        </div>
        </div>
    </div>
    </>
    );
};

export default NumeriqueEvent;

