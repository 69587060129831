import React, { useCallback, useContext, useEffect } from 'react';
import { useState } from 'react';

import './BilanInter.css';
import LogoAvion from "../img/LogoAvion.png";
import LogoVoiture from "../img/LogoVoiture.png";
import LogoTrain from "../img/train.png";
import LogoTC from "../img/TC.png";
import LogoAl from "../img/Al.png";
import LogoDom from "../img/dom.png";
import LogoDechet from "../img/dechet.png";
import LogoPlacement from "../img/Visite.png";

import Titre from '../Titre';
import SousTitre from '../SousTitre';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FormAlimentaire2 from '../Form/Form Particulier/FormAlimentaire';

import { ConsoDictContext } from './consoDictContext';

const BilanInter = ({ voyages, numeroEtape, handleDeleteVoyage, voyage, alimentaire,responseAPI,responseAPIPlacement,responseAPITC, responseAPIDechet,responseAPIDomicile,responseAPIAvion,responseAPIVoiture, responseAPITrain}) => {

  const { sumDict, addSumConso } = useContext(ConsoDictContext);

  // const getConsoFromDict = (sumDict, consoType) => {
  //   const entry = sumDict.find(item => item.consoType === consoType);
  //   return entry ? entry.sumConso : 0;
  // };

  const getConsoFromDict = (sumDict, consoType) => {
    const voyages = sumDict.find(item => item.consoType === consoType);

    if (!voyages || !voyages.sumConso) {

      return (0).toFixed(1);

    } else if (typeof voyages.sumConso === 'number' || typeof voyages.sumConso === 'string') {

      return voyages.sumConso;

    } else {
      const totalConso = Object.values(voyages.sumConso).reduce((total, voyage) => {
        return total + (voyage.conso ? parseFloat(voyage.conso) : 0)
      }, 0);

      return totalConso.toFixed(1)
    }
  };

  const getTotalConsumption = (sumDict) => {
    return sumDict.reduce((total, item) => {
      return total + (getConsoFromDict(sumDict, item.consoType) ? parseFloat(getConsoFromDict(sumDict, item.consoType)) : 0);
    }, 0).toFixed(1);
  };

  const dictVoyages = (voyages) => {
    const listVoyage = voyages.reduce((acc, voyage, index) => {
      acc[`Voyage ${index + 1}`] = {
        info: `Voyage ${index + 1} : ${voyage.depart.name} - ${voyage.arrive.name}, ${voyage.ajout.frequence} fois`,
        conso: voyage.consoVoyage
      };
      return acc;
    }, {});
    return listVoyage;
  }

// const sumTC = responseAPITC ? (responseAPITC.ConsoTrotinetteVelo).toFixed(3):0;
useEffect(() => {
  if (responseAPITC) {
    addSumConso("sumTC", (responseAPITC).toFixed(1));
  }
}, [responseAPITC]);

useEffect(() => {  
  if (responseAPIVoiture && voyages.length > 0) {
    addSumConso("sumVoiture", dictVoyages(voyages))

  } else if (responseAPIVoiture) {
    const values = Object.values(responseAPIVoiture);
    if (values[1] != 0) {
      addSumConso("sumVoiture", (parseFloat(responseAPIVoiture.ConsoVoiture) || responseAPIVoiture.ConsoMoto || responseAPIVoiture.ConsoVTC || responseAPIVoiture.ConsoVoyageTaxi || responseAPIVoiture.ConsoVoyage).toFixed(1));
    } else {
      addSumConso("sumVoiture", 0);
    }
  }
}, [responseAPIVoiture, voyages]);

// useEffect(() => {
//   if (responseAPIPlacement) {
//     addSumConso("sumPlacement", (responseAPIPlacement.ConsoPlacementBanque).toFixed(1));
//   }
// }, [responseAPIPlacement]);

useEffect(() => {
  if (responseAPIAvion) {
    // addSumConso("sumAvion", voyages.reduce((acc, voyage) => voyage.consoVoyage != null ? acc + voyage.consoVoyage : 0, 0).toFixed(1));
    addSumConso("sumAvion", dictVoyages(voyages))
  }
}, [responseAPIAvion, voyages]);


useEffect(() => {
  if (responseAPI) {
    const responseAPIAlimparsed = JSON.parse(JSON.stringify({ responseAPI }));
    const sumAlim = (
      responseAPIAlimparsed.responseAPI.ConsoPetitDej +
      responseAPIAlimparsed.responseAPI.ConsoRepasVegetarien +
      responseAPIAlimparsed.responseAPI.ConsoRepasVegetalien +
      responseAPIAlimparsed.responseAPI.ConsoRepasMarin +
      responseAPIAlimparsed.responseAPI.ConsoRepasViandeRouge +
      responseAPIAlimparsed.responseAPI.ConsoRepasViandeBlanche +
      responseAPIAlimparsed.responseAPI.ConsoBoisson
    ).toFixed(1);
    addSumConso("sumAlim", sumAlim);
  }
}, [responseAPI]);

useEffect(() => {
  if (responseAPIDomicile) {
    addSumConso("sumDomicile", (responseAPIDomicile.ConsoDomicile).toFixed(1));
  }
}, [responseAPIDomicile]);

useEffect(() => {
  if (responseAPIDechet) {
    addSumConso("sumDechet", (responseAPIDechet.ConsoTotalDechetConsommation).toFixed(1));
  }
}, [responseAPIDechet]);

useEffect(() => {
  if (responseAPITrain && voyages.length > 0) {
    addSumConso("sumTrain", dictVoyages(voyages))
  } else if (responseAPITrain) {
    addSumConso("sumTrain", (responseAPITrain.ConsoTrain).toFixed(1));
  }
}, [responseAPITrain, voyages]);

const sumInter = getTotalConsumption(sumDict)
// console.log(sumDict)
// responseAPIVoiture != null ? console.log(responseAPIVoiture.ConsoVoiture):console.log("error");

// const sumVoiture = responseAPIVoiture ? (parseFloat(responseAPIVoiture.ConsoVoiture) || responseAPIVoiture.ConsoMoto || responseAPIVoiture.ConsoVTC || responseAPIVoiture.ConsoVoyageTaxi).toFixed(3):2;

//  const sumPlacement = responseAPIPlacement ? (responseAPIPlacement.ConsoPlacementBanque).toFixed(3):0;

 // Somme les différentes consos des voyages
//  const sumAvion = voyages ? voyages.reduce((acc, voyage) => voyage.consoVoyage != null ? acc + voyage.consoVoyage:0, 0).toFixed(3):0;
  // console.log(voyages)
  // console.log(responseAPIAlimparsed);

  // const sumAlim = responseAPI ? (
  //   responseAPIAlimparsed.responseAPI.ConsoPetitDej +
  //   responseAPIAlimparsed.responseAPI.ConsoRepasVegetarien +
  //   responseAPIAlimparsed.responseAPI.ConsoRepasVegetalien +
  //   responseAPIAlimparsed.responseAPI.ConsoRepasMarin +
  //   responseAPIAlimparsed.responseAPI.ConsoRepasViandeRouge +
  //   responseAPIAlimparsed.responseAPI.ConsoRepasViandeBlanche +
  //   responseAPIAlimparsed.responseAPI.ConsoBoisson
  // ).toFixed(3) : 0;


  // const sumDomicile = 0;

  // const sumDechet = responseAPIDechet ? (responseAPIDechet.ConsoTotalDechetConsommation).toFixed(3) : 0;

  // const sumTrain = responseAPITrain ? (responseAPITrain.ConsoTrain || responseAPITrain.ConsoVoyageTrain).toFixed(3): 0;
  // const sumTrain = responseAPITrain ? voyages.reduce((acc, voyage) => voyage.consoVoyage != null ? acc + voyage.consoVoyage:0, 0).toFixed(3): 0;

  const collection = [
    {
      text: `Bilan avion : ${getConsoFromDict(sumDict, 'sumAvion')} tonnes de CO₂ par an`,
      image: LogoAvion
    },
    {
      text: `Bilan voiture : ${getConsoFromDict(sumDict, 'sumVoiture')} tonnes de CO₂ par an`,
      image: LogoVoiture
    },
    {
      text: `Bilan train : ${getConsoFromDict(sumDict, 'sumTrain')} tonnes de CO₂ par an`,
      image: LogoTrain
    },
    {
      text: `Bilan transport en commun : ${getConsoFromDict(sumDict, 'sumTC')} tonnes de CO₂ par an`,
      image: LogoTC
    },
    {
      text: `Bilan alimentation : ${getConsoFromDict(sumDict, 'sumAlim')} tonnes de CO₂ par an`,
      image: LogoAl
    },
    {
      text: `Bilan domicile : ${getConsoFromDict(sumDict, 'sumDomicile')} tonnes de CO₂ par an`,
      image: LogoDom
    },
    {
      text: `Bilan consommation & déchets : ${getConsoFromDict(sumDict, 'sumDechet')} tonnes de CO₂ par an`,
      image: LogoDechet
    },
    {
      text: `Bilan placements financiers : ${responseAPIPlacement ? responseAPIPlacement.ConsoPlacementBanque.toFixed(1) : 0} tonnes de CO₂ par an`,
      image: LogoPlacement
    },
    // Ajoutez les autres étapes de la collection ici
  ];

  const collectionAffichee = collection.slice(0, numeroEtape);
  // const sumInter = (parseFloat(sumVoiture) + parseFloat(sumTC) + parseFloat(sumPlacement) + parseFloat(sumAlim) + parseFloat(sumDechet) + parseFloat(sumTrain) + parseFloat(sumAvion) + parseFloat(sumDomicile)).toFixed(3)  ;

  return (

    <div className='BilanInt'>
      <Titre Titre="Bilan intermédiaire" place="start" />
      {numeroEtape != 8 && (
        <div className="containerC">
          <div className="contentB">
            <div className='h61'>Bilan cumulé intermédiaire : {sumInter} tonnes de CO₂ par an</div>
          </div>
        </div>
      )}


      {numeroEtape != 8 && collectionAffichee.map((item, index) => (
        <div className="containerB" key={index}>
          <div className="contentB">
            <div className="imageCont">
            <img className="imageLogo" src={item.image} alt="Image centrée" />
            </div>
            <span className="textB" style={{fontWeight : "800"}}>{item.text}</span>
          </div>
        </div>
      ))}

    {voyage === "non" ? ("") : (
      voyages.map((voyage, index) => (
        <div className="containerB" key={index}>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Voyage {index + 1} : {voyage.depart.name} - {voyage.arrive.name},  {voyage.ajout.frequence} fois</span>
            {/* <div className="textB col-4"> X - test tonne(s) de CO2</div> */}
            <div className="textB col-4"> {(voyage.consoVoyage == null ? "0":(voyage.consoVoyage).toFixed(1))} tonne(s) de CO₂</div>
            <div className="icon-container float-end">
              <DeleteOutlinedIcon className="Icon" onClick={() => handleDeleteVoyage(index)} />
            </div>
          </div>
        </div>
      ))
    )}

    {numeroEtape == 8 && responseAPIPlacement && (
      <div>
        <div className="containerB">
          <div className="contentB">
            <div className="imageCont">
            <img className="imageLogo" src={collection[numeroEtape-1].image} alt="Image centrée" />
            </div>
            <span className="textB" style={{fontWeight : "800"}}>{collection[numeroEtape-1].text}</span>
          </div>
        </div>
      </div>
    )}



{alimentaire === "oui" && (
  <div>
    <div className="containerB">
      {responseAPI ? (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation petit déjeuner :</span>
            <div className="textB col-4">{responseAPI.ConsoPetitDej} tonne(s) de CO₂</div>
          </div>
        </>
      ) : (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation petit déjeuner :</span>
            <div className="textB col-4">0 tonne(s) de CO₂</div>
          </div>
        </>
      ) }
    </div>

    <div className="containerB">
      {responseAPI ? (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation repas végétarien :</span>
            <div className="textB col-4">{responseAPI.ConsoRepasVegetarien} tonne(s) de CO₂</div>
          </div>
        </>
      ) : (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation repas végétarien :</span>
            <div className="textB col-4">0 tonne(s) de CO₂</div>
          </div>
        </>
      )}
    </div>

    <div className="containerB">
      {responseAPI ? (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation repas végétalien :</span>
            <div className="textB col-4">{responseAPI.ConsoRepasVegetalien} tonne(s) de CO₂</div>
          </div>
        </>
      ) : (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation repas végétalien :</span>
            <div className="textB col-4">0 tonne(s) de CO₂</div>
          </div>
        </>
      )}
    </div>

    <div className="containerB">
      {responseAPI ? (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation repas marins :</span>
            <div className="textB col-4">{responseAPI.ConsoRepasMarin} tonne(s) de CO₂</div>
          </div>
        </>
      ) : (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation repas marins :</span>
            <div className="textB col-4">0 tonne(s) de CO₂</div>
          </div>
        </>
      )}
    </div>

    <div className="containerB">
      {responseAPI ? (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation viande rouge :</span>
            <div className="textB col-4">{responseAPI.ConsoRepasViandeRouge} tonne(s) de CO₂</div>
          </div>
        </>
      ) : (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation viande rouge :</span>
            <div className="textB col-4">0 tonne(s) de CO₂</div>
          </div>
        </>
      )}
    </div>

    <div className="containerB">
      {responseAPI ? (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation viande blanche :</span>
            <div className="textB col-4">{responseAPI.ConsoRepasViandeBlanche} tonne(s) de CO₂</div>
          </div>
        </>
      ) : (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation viande blanche :</span>
            <div className="textB col-4">0 tonne(s) de CO₂</div>
          </div>
        </>
      )}
    </div>

    <div className="containerB">
      {responseAPI ? (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation boissons :</span>
            <div className="textB col-4">{responseAPI.ConsoBoisson} tonne(s) de CO₂</div>
          </div>
        </>
      ) : (
        <>
          <div className="contentB row">
            <span className="textB col-7 offset-1">Consommation boissons :</span>
            <div className="textB col-4">0 tonne(s) de CO₂</div>
          </div>
        </>
      )}
    </div>
  </div>
)}
    </div>
  );
}

export default BilanInter;

