import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../components/Titre/index";
import Header from "../../components/Header/index";
import "../../Font/font-family.css";
import "../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Particulier/Avion.css"; 
import Ensavoir from "../../components/Ensavoir";
import Footer from "../../components/Footer/Index";

import FormVoyageCollaborateur from '../../components/Form/Form Entreprise/FormVoyageCol';
import StepperLineEntreprise from '../../components/StepperLine/index-entreprise';
import Schemat from "../../components/img/ScopeSchema.png"
import Bouton from '../../components/Form/Bouton';
import Image15 from "../../components/img/15deg.png";
import Ligne2 from '../../components/Bilan/Ligne2';

const EntrepriseAcceuil = () => {
    const navigate = useNavigate();


    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLineEntreprise/>

        <div className="container">
        <div className="row col-10 offset-1">
            <div className="row">
            <div className="col-12">
                <Titre Titre="Distance parcourue en avion par les collaborateurs dans le cadre de leur travail"    place="start"/>
            </div>
            </div>

            <div className='row'>
            <div className='col-lg-7 col-12'>
            <div>
                <div className='h41'>À propos du Calculateur d'Impact Carbone</div>
                 <p className='p1'>Cet outil, gratuit, a été conçu pour permettre aux entreprises de toutes tailles de mesurer leurs émissions de gaz à effet de serre. L’objectif du calculateur est de vous familiariser avec la logique de calcul et de vous donner une première idée de votre impact climatique. Il vous sera alors possible, et recommandé, de réaliser un bilan exhaustif de vos émissions annuelles pour connaître avec précision l’impact carbone de votre activité.</p>
                 <p className='p1'>En plus des scopes 1 et 2, obligatoires lors de la réalisation d’un bilan carbone réglementaire, nous avons intégré une partie du scope 3 étant considéré que ces émissions représentent la grande majorité du carbone associé à l’activité d’une entreprise (déplacement domicile-travail, déplacements professionnels, fret, immobilisations et consommables, numérique).</p>
                 <p className='p1'>Pour rappel :</p>
                 <ul>
                    <li className='p1'><strong>Le scope 1</strong> concerne les émissions directes de gaz à effets de serre directement émis par la combustion des énergies fossiles (chauffage, essence…)</li>
                    <li className='p1'><strong>Le scope 2</strong> concerne les émissions indirectes de gaz à effets de serre liées à l’énergie (production d’électricité …)</li>
                    <li className='p1'><strong>Le scope 3</strong> regroupe toutes les autres émissions indirectes liées à l’activité de son entreprise sur sa chaîne de valeur (fret, matières premières, déplacement professionnels …)</li>
                 </ul>
             </div>

            </div>
            
            <div className='col-lg-5 col-10'>
            
            <div className="container col-12 d-flex justify-content-center align-items-center">
        <div className="card rounded p-4 col-12" style={{backgroundColor : "rgba(245, 245, 245, 1)"}}>
        <div className="row justify-content-center align-items-center text-center">
        <div className='row justify-content-center col-8'><Bouton titre="Se créer un compte"/> </div>
        <div className='p4 row justify-content-center col-8'>ou</div>
        <div className='row justify-content-center col-8'><Bouton titre="Se connecter"/> </div>
        </div>
        </div>
    </div>

    <div className="container col-12 d-flex justify-content-center align-items-center">
        <div className="card rounded p-4 col-12" style={{backgroundColor : "rgba(245, 245, 245, 1)"}}>
        <div className="row justify-content-center align-items-center text-center">
        <div className='p4 row justify-content-center col-8'>Je connais déjà le montant correspondant à mes émissions de gaz à effet de serre. </div>
        <div className='row justify-content-center col-8'>
            <button
                    type="button"
                    className="custom-button"
                >
                    JE CONTRIBUE
                </button> </div>
        </div>
        </div>
    </div>

    <div className='col-lg-10 offset-lg-1 col-6'><Ligne2 image={Image15} text="Tout comprendre sur les scope 1, 2 et 3 du bilan carbone" /></div>

             </div>
            </div>

            <div className='col-12' style={{margin : "2vh"}}>
            <img className='col-8 offset-2' src={Schemat} alt="Répartition de la consommation d'énergie primaire en france" />
            <div>

        <div className='h41' style={{marginTop : "2vh"}}>Réduire Vos Émissions</div>
        <p className='p1'>Passée cette phase de calcul, l’objectif consiste à réduire vos émissions. À cette fin, nous vous proposerons des actions de réduction que vous pourrez mettre en application au sein de votre entreprise, mais elles resteront standardisées. Notre équipe de conseil peut vous accompagner dans la construction d’un plan d’action spécifique à votre structure, ou vous conseiller des partenaires pertinents.</p>
        <p className='p1'>Enfin, pour aller au bout de la démarche, nous vous proposerons de contribuer à la neutralité carbone sur toute ou partie du périmètre calculé, en soutenant des projets de terrain apportant des solutions concrètes aux populations les plus impactées par le dérèglement climatique.</p>
        </div>

        <div class="data-collection">
        <div className='h41'>Rassembler les Données Nécessaires</div>
    <p className='p1'>Pour remplir ce questionnaire, vous aurez besoin de rassembler les données nécessaires concernant :</p>
    <ul>
        <li className='p1'>Les consommations d’énergie (factures d’électricité et des autres combustibles) : en kWh (ou Litres pour le fioul) ou en euros si on dispose de la donnée, ou la surface des bâtiments sinon</li>
        <li className='p1'>Le fret (facture des transporteurs) : en tonnes x km. Nécessite le tonnage, le type de transports et le nombre de km pour chaque trajet</li>
        <li className='p1'>Les immobilisations (factures, matériels…) en unités</li>
        <li className='p1'>Les consommables (factures …) en euros ou en unités</li>
        <li className='p1'>Les déplacements professionnels (factures, notes de frais …) en litres de carburant ou en kilomètres. Possibilité de mettre des itinéraires</li>
        <li className='p1'>Les déplacements domicile-travail des salariés (enquête auprès des salariés) en litres de carburant ou en kilomètres. Possibilité de mettre des itinéraires</li>
        <li className='p1'>Le numérique (enquête auprès des collaborateurs) : en unité par jour ou par semaine en moyenne.</li>
        </ul>
             <p className='p1'>Une fois ces informations rassemblées, il vous faudra environ 20 min pour remplir le formulaire. Vous pouvez à tout moment enregistrer et reprendre la saisie ultérieurement.</p>
             <p className='p1'>Le choix des facteurs d’émissions et des hypothèses, ainsi qu’une aide à la saisie sont disponibles dans la rubrique « En savoir plus sur ce calcul » en bas de page de chaque catégorie. Les facteurs d’émissions, les hypothèses ainsi que la méthodologie évoluant régulièrement, une mise à jour annuelle est effectuée.</p>
             <p className='p1'>Si vous souhaitez suivre l’évolution de vos émissions sur plusieurs années, vous devez recalculer les années précédentes avec la mise à jour afin de disposer des mêmes facteurs d’émission et des mêmes hypothèses.</p>
         </div>
            </div>



                
            



        </div>
        </div>
        </div>
    </div>
    </>
    );
};

export default EntrepriseAcceuil;