import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [token, setToken] = useState(() => {
    // Récupération du token depuis le localStorage au montage initial
    return localStorage.getItem('token');
  });
  const [expiry, setExpiry] = useState(() => {
    // Récupération de la date d'expiration depuis le localStorage au montage initial
    const storedExpiry = localStorage.getItem('token_expiry');
    return storedExpiry ? Number(storedExpiry) : null;
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const API_URL = process.env.NODE_ENV === 'production' 
  // ? '/api/gettoken'
  // : 'http://localhost:3001/api/gettoken'; 
  const API_URL = '/api/gettoken'


  // Fonction pour obtenir un nouveau token
  const fetchToken = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await axios.post(API_URL);
      setToken(response.data.access_token);
      setExpiry(response.data.expires_in * 1000 + Date.now()); // Correction ici
    } catch (err) {
      setError("An error occurred while fetching the token");
    } finally {
      setLoading(false);
    }
  };

  // Stocker le token et la date d'expiration dans le localStorage chaque fois qu'ils changent
  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
    if (expiry) {
      localStorage.setItem('token_expiry', expiry.toString());
    }
  }, [token, expiry]);

  // Renouveler le token si nécessaire
  useEffect(() => {
    if (!expiry) {
      return;
    }
  
    const buffer = 5 * 60 * 1000; // 5 minutes en millisecondes => refaire avant qu'il n'expire
    if (Date.now() < expiry - buffer) {
      return;
    }
  
    fetchToken();
  }, [expiry]);
  

  // Obtenez le token initial lorsque l'application charge
  useEffect(() => {
    if (!token || Date.now() > expiry) {
      fetchToken();
    }
  }, []);

  const value = {
    token,
    fetchToken, // Exposez fetchToken pour que les composants puissent demander un nouveau token explicitement si nécessaire
    loading,
    error
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider; // Assurez-vous que AuthProvider est exporté
