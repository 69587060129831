import React from 'react';
import './StepperLine-test.css';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HouseIcon from '@mui/icons-material/House';
import RecyclingIcon from '@mui/icons-material/Recycling';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import { useNavigate } from 'react-router-dom'; // import the useNavigate hook
import FestivalIcon from '@mui/icons-material/Festival';
import TrainIcon from '@mui/icons-material/Train';
import KingBedIcon from '@mui/icons-material/KingBed';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import ComputerIcon from '@mui/icons-material/Computer';

const StepperLineEvent = () => {
    const navigate = useNavigate(); // instantiate the navigate function

    return (
    <div className="containerT">

        <div className="circle" onClick={() => navigate('/Evenement/General')}>
        <FestivalIcon sx={{ color: 'green' }}/>
        {/* <div className="triangle"></div> */}
        {/* <div className="line-down"></div> */}
        <div className="textT H69" style={{color: "green"}}>Generalités</div>
        </div>

        <div className="lineT line-between"></div>
        <div className="circle" onClick={() => navigate('/Evenement/Deplacement')}>
        <TrainIcon sx={{ color: '#68C4A5' }}/>
        {/* <div className="triangleT"></div>
        <div className="line-down"></div> */}
        <div className="textT h21">Deplacement</div>
        </div>

        <div className="lineT line-between"></div>
        <div className="circle" onClick={() => navigate('/Evenement/Hebergement')}>
        <KingBedIcon sx={{ color: '#FBAB3B' }} />
        {/* <div className="triangle"></div> */}
        <div className="line-down"></div>
        <div className="textT" style={{color: "#FBAB3B"}}>Hebergement</div>
        </div>

        <div className="lineT line-between"></div>
        <div className="circle" onClick={() => navigate('/Evenement/Restauration')}>
        <RestaurantMenuIcon/>
        {/* <div className="triangle"></div> */}
        <div className="line-down"></div>
        <div className="textT">Restauration</div>
        </div>

        <div className="lineT line-between"></div>
        <div className="circle" onClick={() => navigate('/Evenement/Logistique')}>
        <AirlineStopsIcon/>
        {/* <div className="triangle"></div> */}
        <div className="line-down"></div>
        <div className="textT">Logistique</div>
        </div>

        <div className="lineT line-between"></div>
        <div className="circle" onClick={() => navigate('/Evenement/Numerique')}>
        <ComputerIcon/>
        {/* <div className="triangle"></div> */}
        <div className="line-down"></div>
        <div className="textT">Numerique</div>
        </div>

    </div>
    );
}

export default StepperLineEvent;
