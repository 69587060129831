import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../Particulier/Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import CarreLogoCompteur from '../../../components/CarreLogo/CarreLogoCompteur';
import LogoCereale from "../../../components/img/cereale.png";
import LogoCont from "../../../components/img/continental.png";
import LogoSale from "../../../components/img/sale.png";
import LogoVege from "../../../components/img/vege.png";
import LogoVegetalien from "../../../components/img/vegetalien.png";
import LogoPoisson from "../../../components/img/poisson.png";
import LogoViande from "../../../components/img/viande.png";
import LogoVolail from "../../../components/img/volaille.png";
import LogoThe from "../../../components/img/the.png";
import LogoCafe from "../../../components/img/cafe.png";
import LogoBiere from "../../../components/img/biere.png";
import LogoJus from "../../../components/img/jus.png";
import LogoSodas from "../../../components/img/soda.png";


import SousTitre from '../../../components/SousTitre';
import TitreH2 from '../../../components/TitreH2';
import Compteur from '../../../components/Compteur/Compteur';
import FormFret from '../../../components/Form/Form Entreprise/FormVoyageFret';
import StepperLineEntreprise from '../../../components/StepperLine/index-entreprise';

const Fret1 = () => {
    const navigate = useNavigate();


    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLineEntreprise/>

        <div className="container">
            <div className="row">
            <div className="row col-10 offset-1">
                <Titre Titre="Fret routier"    place="start"/>
            </div>
            </div>
            <div className="row col-10 offset-1">
            <FormFret/>
            </div>

                
            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>Émissions liées au fret</div>
        <p>Le fret correspond au transport terrestre, maritime, ferroviaire, fluvial et aérien des biens en amont, en interne ou en aval de votre activité. Les facteurs d’émissions dépendent du mode de transport utilisé. Nous considérons pour les transports routiers que les véhicules circulent majoritairement au diesel. Sont pris en compte la combustion et la phase amont pour les carburants ainsi que la fabrication pour le transport routier.</p>

        <p>Si vous possédez des véhicules en propre, vous pouvez indiquer directement la consommation de carburant en litre liée au transport amont et aval de vos marchandises. Sinon, vous devez calculer le nombre de tonnes x kilomètres de votre marchandise. A savoir, le nombre de tonnes ayant parcouru un certain nombre de kilomètres. Vous devez additionner ce nombre de tonnes x kilomètres associés.</p>
        
        <p><strong>Exemple :</strong> vous exportez 3 tonnes de marchandises chaque mois sur 200km et vous importez 2 tonnes de matières premières par an sur 500km par camionnette alors le calcul est le suivant : (3 x 200 x 12) + (2 x 500) t.km. Attention, ce n’est pas (200 + 500) km x (3+2) tonnes.</p>
    </div>
} />

            </div>
            </div>

            <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div>

        </div>
        </div>
    </div>
    </>
    );
};

export default Fret1;

