import React from 'react';
import './Graphique.css';
import CardPetiteDroiteImage from '../CardPetiteDroite/Image.js';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const Ligne2 = ({text, lien, image}) => {
  // Données pour les éléments du graphique
  
  return (
    <div className='ligne2 row'>
      <div className='col-5'><CardPetiteDroiteImage color={{ backgroundColor: "#FBAE50"}} backgroundImage={image}/></div>
      <div className='col-1 logo'><div className='traiLigne'></div><AddCircleOutlineIcon/></div>
      <div className='col-6 text'>
      <div className='titre'>{text}</div>
      <a href={lien} className='savoir titre' target="_blank" >En savoir plus</a>
      </div>
      
    </div>
  );
};

export default Ligne2;
