import React, { useContext, useState, useEffect } from 'react';
import './intro.css'
import { useNavigate } from 'react-router-dom';
import ReactShowMoreText from 'react-show-more-text';
import Header from "../../../components/Header/index";
import Card from "../../../components/Card/index";
import Footer from '../../../components/Footer/Index';
import ContenueLogo from "../../../components/ContenuCard/ContenueLogo";
import ContenueBouton from "../../../components/ContenuCard/ContenueBouton";
import TitreH2 from '../../../components/TitreH2';
import TitreBG from '../../../components/TitreBG';
import ImageAvion from "../../../components/img/LogoAvion.png";
import StepperLine from '../../../components/StepperLine/index-test';
import oui from "../../../components/img/imageLogoOui.png";
import non from "../../../components/img/imageLogoNon.png";
import AppelAPIAvion from '../../../components/Api/AppelAPIAvion';

import { IdBCContext } from '../../../components/IdBCContext';
import { useFormProgress } from '../../../components/FormProgressContext';

const Introduction = () => {
    const [Lancer, setLancer] = useState(true);
    const navigate = useNavigate();
    const [apiResponse, setApiResponse] = useState("");
    const [consoVoyage, setConsoVoyage] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { formStatus, setFormStatus } = useFormProgress();

    const { idBC, setIdBC } = useContext(IdBCContext);

    const maxLength = useState(100);

    const styles = {
    tabbedList: {
        listStyle: 'none',
        paddingLeft: 0,
        counterReset: 'list-counter',
    },
    tabbedListItem: {
        counterIncrement: 'list-counter',
        marginLeft: '1em',
        position: 'relative',
    },
    tabbedListItemBefore: {
        content: 'counter(list-counter) ".\\00A0\\00A0"',
        fontWeight: 'bold',
        position: 'absolute',
        left: 0,
    },
    dashedList: {
        listStyle: 'none',
        paddingLeft: 0,
    },
    dashedListItem: {
        position: 'relative',
        paddingLeft: '1em',
        marginBottom: '0.5em',
    },
    dashedListItemBefore: {
        content: '"-"',
        position: 'absolute',
        left: 0,
    },
    };

    const [voyage, setVoyage] = useState(
    {
        typeVoyage: '279640000',
        depart: {
        name: 'Paris',
        latitude: 48.8588,
        longitude: 2.3200
        },
        arrive: {
        name: 'Paris',
        latitude: 48.8588,
        longitude: 2.3200
        },
        ajout: { frequence: '1', unit: '279640000' },
        emptyFieldsMessage: null,
        consoVoyage: null
    },
    );

    // Premier contenu de la carte
    const [cardContenu, setCardContenu] = useState(
    <ReactShowMoreText
        /* Default options */
        lines={4}
        more="Voir plus"
        less="Voir moins"
        className=""
        anchorClass="show-more-text"
        expanded={false}
        width={2000}
        truncatedEndingComponent={"... "}>

        <div>
        Cet outil, gratuit, a été conçu pour vous sensibiliser et estimer les émissions de gaz à effet de serre générées par vos activités quotidiennes.<br/><br/> Agir pour le climat, c’est d’abord identifier puis réduire les gaz à effet de serre que nous émettons au quotidien et sur lesquels nous pouvons agir à la source.<br/>
        Pour résumer :<br/>
        <div style={styles.tabbedList}>
            <ol>
                <li style={styles.tabbedListItem}>Je calcule mon empreinte carbone pour identifier les postes les plus émetteurs et connaître l’évolution des émissions. </li>
                <li style={styles.tabbedListItem}>Je réduis mes émissions carbone grâce aux actions proposées et je prends part aux politiques locales de mon territoire pour faire évoluer les pratiques collectives. </li>
                <li style={styles.tabbedListItem}>Je contribue à la neutralité carbone pour mes émissions non évitables, en finançant nos projets de terraines</li>
            </ol>
        </div>
        Pour remplir ce questionnaire, vous devrez renseigner les informations suivantes :<br/>
        <div style={styles.dashedList}>
            <ul>
                <li style={styles.dashedListItem}>Vos consommations d’énergie (factures d’électricité et des autres combustibles)</li>
                <li style={styles.dashedListItem}>Vos habitudes alimentaires</li>
                <li style={styles.dashedListItem}>Vos déplacements en avion, voiture et train sur l’année (en kilomètre)</li>
                <li style={styles.dashedListItem}>Vos habitudes de consommation</li>
            </ul>
        </div>
        Une fois ces informations rassemblées, il vous faudra environ 10 min pour remplir le formulaire.<br/>
        <br/>
        Le choix des facteurs d’émissions et des hypothèses, ainsi qu’une aide à la saisie sont disponibles dans la rubrique « En savoir plus sur ce calcul » en bas de page de chaque catégorie. Les facteurs d’émissions, les hypothèses ainsi que la méthodologie évoluant régulièrement, une mise à jour annuelle est effectuée.<br/>
        <br/>     
        Si vous souhaitez suivre l’évolution de vos émissions sur plusieurs années, vous pouvez créer un compte sur notre calculateur.
        </div>
        
    </ReactShowMoreText>
    );

    const truncatedText = useState(cardContenu.length > maxLength ? cardContenu.substring(0, maxLength) + "..." : cardContenu);

    useEffect(() => {
    // Mettre à jour le statut du formulaire
    setFormStatus({
        ...formStatus,
        intro: 'en cours',
    });
    }, []);

    const handleCardClick = async () => {
    setIsLoading(true);
    setFormStatus(prevStatus => ({
        ...prevStatus,
        intro: 'fini',
    }));

    if (!idBC) {
        try {
        setLancer(true);
        // Wait for the API call to complete
        await new Promise(resolve => {
            const checkApiResponse = setInterval(() => {
            if (idBC) {
                clearInterval(checkApiResponse);
                resolve();
            }
            }, 100);
        });
        } catch (error) {
        console.error("Error waiting for API response:", error);
        // Handle error (e.g., show error message to user)
        } finally {
        setLancer(false);
        setIsLoading(false);
        }
    }

    // Navigate only after API call is complete or if idBC already exists
    if (idBC) {
        navigate("/particulier");
    }
    };

    // Rendu de la page
    return (
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLine/>

        <div className='TitreBG'>
            <TitreBG Titre="Introduction au calculateur particulier" className="titre"/>
        </div>

        <div className="row">
            <div className="col-10 offset-1">
            <TitreH2 Titre="Introduction au calculateur particulier" className="titre" couleur="#000000"/>
            </div>
        </div>

        {/* Affichage de la carte avec le contenu dynamique */}
        <div className="row justify-content-center">
            <div className="col-10">
            <Card contenu={truncatedText} onClickButton={handleCardClick} color="#68C4A5" intro={true}/>
            </div>
        </div>

        {!idBC && (
            <AppelAPIAvion index={0} voyage={voyage} lancer={Lancer} setLancer={setLancer} setApiResponse={setApiResponse} setConsoVoyage={setConsoVoyage} setIdBC={setIdBC}/>
        )}
        
        <div className="row justify-content-center">
            <div className="col-8">
            <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
        </div>
        
        </div>
    </div>
    );
};

export default Introduction;

