import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import CheckboxChoixUnique from '../checkBox/checkbox';
import InputFieldNb from '../InputNombre/index';
import CheckboxAjout from '../checkBoxAjout/CheckboxAjout';
import AjoutPerso from '../Ajout/index-personne';
import { Button } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useFormProgress } from '../../FormProgressContext';
import AppelAPIMoto from '../../Api/AppelAPIMoto';
import CacheContext from '../../../context';
import {useAppelDict} from '../../BilanInter/appelDictContext'

const CARBURANT_VALUES = {
  'Essence': 279640000,
  'Diesel': 279640001,
  'GPL': 279640002,
  'GNV': 279640003,
  'E85': 279640004,
  'Hybride': 279640005,
  'Electrique': 279640006
};

function FormMoto({ onSubmit }) {
  const { cache, setCache } = useContext(CacheContext);
  const navigate = useNavigate();
  const { formStatus, setFormStatus } = useFormProgress();
  const [valeurInput, setValeurInput] = useState(() => cache.valeurInput || '1');
  const [formSubmitted, setFormSubmitted] = useState(() => cache.formSubmitted || false);
  const [consommationConnue, setConsommationConnue] = useState(() => cache.consommationConnue || "oui");
  const [typeCarburant, setTypeCarburant] = useState(() => cache.typeCarburant || 279640000); //Initialisé à la valaur de essence
  const [typeVehicule, setTypeVehicule] = useState(() => cache.typeVehicule || "Scooteur/moteur < 125cc");
  const [typeKilo, setTypeKilo] = useState(() => cache.typeKilo || 'oui');
  const [consommation, setConsommation] = useState(() => cache.consommation || '');
  const [unit, setUnit] = useState(() => cache.unit || 279640000);
  const [kilometrageConnu, setKilometrageConnu] = useState(() => cache.kilometrageConnu || false);
  const [selectedUnite, setSelectedUnite] = useState(() => cache.selectedUnite || 279640000); 
  const [lancer, setLancer] = useState(() => cache.lancer || false);
  const [apiResponse, setApiResponse] = useState(() => cache.apiResponse || null); // État pour stocker la réponse de l'API
  const [displayBilan, setDisplayBilan] = useState(() => cache.displayBilan || false);
  const [idMoto, setIdMoto] = useState(() => cache.idMoto || "");

  const { appelDict, setAppelDict } = useAppelDict();

  // Méthode pour stocker les appels fait à voiture
  const updateAppelMoto = (index, appelMoto, apiUrl) => {
		setAppelMoto((prevAppelMoto) => {
            const newAppelMoto = [...prevAppelMoto];
			      newAppelMoto[index] = { ...newAppelMoto[index], appelMoto: appelMoto, apiUrl: apiUrl };
            addToAppelDict(newAppelMoto)
			      return newAppelMoto;
		});
  };

  // Etat pour stocker les voyages
  const [appelVoyages, setAppelMoto] = useState(() => [
    {
  },
  ]);

  // Méthode pour stocker tous les appels API 
  const addToAppelDict = (appelsMoto) => {
    setAppelDict(prev => {
        const newAppelDict = { ...prev, 'moto': appelsMoto };
        console.log("Appel Dict", newAppelDict);
        return newAppelDict;
    });
  };

  // Fonction pour mettre à jour l'état de la réponse de l'API
  const updateApiResponse = (response) => {
    setApiResponse(response);
  };

  const updateConsoVoyage = (index, consoVoyage) => {
    setVoyages((prevVoyages) => {
      const newVoyages = [...prevVoyages];
      newVoyages[index] = { ...newVoyages[index], consoVoyage: consoVoyage };
      return newVoyages;
    });
  };

  const handleButtonClickAPI = () => {
    setLancer(true);
    setDisplayBilan(true);
    setFormSubmitted(false);
    // Vous pouvez effectuer ici des traitemensts supplémentaires avec les arguments si nécessaire
    //setApiResponse(null); // Réinitialiser les données avant l'appel API
  };

  useEffect(() => {
    setFormStatus({
        ...formStatus,
        voiture: 'en cours',
    });
   }, []);

  const [voyages, setVoyages] = useState(() => cache.voyages || [
    {
      typeVoyage: '',
      depart: {
        name: '',
        latitude: null,
        longitude: null
      },
      arrive: {
        name: '',
        latitude: null,
        longitude: null
      },
      ajout: { frequence: '1', unit: 'an' },
      // ajoutPerso: '',
      consoVoyage: null
    },
  ]);

  useEffect(
	() => {
		setCache(prevCache => ({
			...prevCache,
			valeurInput,
			formSubmitted,
			consommationConnue,
			typeCarburant,
			typeVehicule,
			typeKilo,
			consommation,
			unit,
			kilometrageConnu,
			selectedUnite,
			lancer,
			apiResponse,
			displayBilan,
			idMoto,
		}));
	}, [
		valeurInput,
		formSubmitted,
		consommationConnue,
		typeCarburant,
		typeVehicule,
		typeKilo,
		consommation,
		unit,
		kilometrageConnu,
		selectedUnite,
		lancer,
		apiResponse,
		displayBilan,
		idMoto,
	]
  );

  // Fonction pour supprimer un voyage de la liste
  const handleDeleteVoyage = (index) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      updatedVoyages.splice(index, 1);
      return updatedVoyages;
    });
  };

  // Fonction pour gérer le clic sur le bouton de navigation
  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/Train'); // Ajout de la navigation après la soumission du formulaire
  };

  const handleTypeCarburantChange = (value) => {
    setTypeCarburant(CARBURANT_VALUES[value]);
  };

  // Fonction pour ajouter un voyage à la liste
  const addVoyage = useCallback(() => {
    setVoyages((prevVoyages) => [
      ...prevVoyages,
      {
        typeVoyage: '',
        depart: {
          name: '',
          latitude: null,
          longitude: null
        },
        arrive: {
          name: '',
          latitude: null,
          longitude: null
        },
        ajout: { frequence: '1', unit: 'an' },
        // ajoutPerso: '',
        consoVoyage: null
      },
    ]);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid) {
      setFormSubmitted(true);
      setDisplayBilan(true);
      setFormStatus({
        ...formStatus,
        voiture: 'fini',
      });
    } else {
      // alert('Veuillez remplir tous les champs requis avant de soumettre.');
    }
  };

  const isFormValid = voyages.every((voyage) => {
    if (typeKilo === "non") {
        // Le cas ou il y a un voyage à remplir
        return voyage.typeVoyage && voyage.depart.name && voyage.arrive.name && voyage.ajout.frequence && voyage.ajoutPerso;
    } else {
        return (typeCarburant && consommation && unit) || (typeCarburant && selectedUnite && valeurInput) ;
    }
});

useEffect(() => {
 if (displayBilan) {
   setFormStatus({
     ...formStatus,
     voiture: 'fini',
   });
 } else if (formSubmitted) {
   setFormStatus({
     ...formStatus,
     voiture: 'en cours',
   });
 }
}, [voyages, formSubmitted]);
  // Fonction pour gérer le changement de la valeur "consommationConnue"
  const handleConsommationConnueChange = (value) => {
    setConsommationConnue(value);
    setIdMoto("demande");
  };

  const handleConsommationChange = (value) => {
    setConsommation(value);
    setIdMoto("demande")
  }
  
  const handleTypeVehiculeChange = (value) => {
    setTypeVehicule(value);
    setIdMoto("demande");
  };

  const handlekilometrageConnu = (value) => {
    setTypeKilo(value);
    localStorage.setItem('kilometrageConnu', value); // Save in localStorage
  };

  const handleUniteChange = (newUnite) => {
    console.log('je suis dans handleUniteChange');
    let unitValue;
    switch (newUnite) {
      case 'an':
        unitValue = 279640000;
        break;
      case 'mois':
        unitValue = 279640001;
        break;
      case 'semaine':
        unitValue = 279640002;
        break;
      default:
        unitValue = 279640000; // Défaut à an si newUnite n'est pas reconnu
    }
    setSelectedUnite(unitValue); // Met à jour l'unité sélectionnée dans l'état
    console.log(newUnite);
  };
  const handleUnitChange = (value) => {
    setUnit(value === 'L/100 km' ? 279640000 : 279640001);
  };

    // Déterminer quand faire l'appel API Voiture Global pour récupérer l'IdVoiture
  useEffect(() => {
    if ((consommationConnue && consommation != '' || consommationConnue === 'non') && idMoto === "") {
      setIdMoto("demande")
    }
  })

  // Fonction pour gérer le changement des valeurs d'un voyage de la liste
  const handleChangeVoyage = useCallback((index, field, value) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        if (field === 'depart' || field === 'arrive') {
          voyage[field] = { ...voyage[field], ...value };
        } else {
          voyage[field] = value;
        }
      }
      return updatedVoyages;
    });
  }, []);

  // Fonction pour gérer le changement des valeurs d'ajout d'un voyage de la liste
  const handleChangeAjout = useCallback((index, values) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        // Récupérer les valeurs de fréquence et unité
        const { frequence, unit } = values;
        
        // Transformer unit selon les conditions
        let unitValue;
        switch (unit) {
          case 'an':
            unitValue = 279640000;
            break;
          case 'mois':
            unitValue = 279640001;
            break;
          case 'semaine':
            unitValue = 279640002;
            break;
          default:
            unitValue = 279640000; // Défaut à an si unit n'est pas reconnu
        }
        
        // Mettre à jour les valeurs
        voyage.ajout = { frequence, unit: unitValue };
      }
      return updatedVoyages;
      
    });
  }, [setVoyages]);



  // Fonction pour gérer le changement des valeurs d'ajout personnel d'un voyage de la liste
  const handleChangeAjoutPerso = useCallback((index, values) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        voyage.ajoutPerso = values;
      }
      return updatedVoyages;
    });
  }, []);

  console.log(idMoto)

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-lg-5">
          {/* Checkbox pour choisir si la consommation est connue */}
          <CheckboxChoixUnique
            titre="Connaissez-vous votre consommation ?"
            rep1="Oui"
            rep2="Non, mais aidez-moi à la calculer"
            onChange={(value) => handleConsommationConnueChange(value)}
            value={consommationConnue}
          />
        </div>
      </div>
{/* Affichage conditionnel en fonction de la valeur de "consommationConnue" */}
{consommationConnue === "" ? null : consommationConnue ? (
        <div className="row">
          {/* Liste déroulante pour le type d'énergie */}
          <div className="col-12 col-lg-4">
            <ListDeroule
              titre="Type d'énergie"
              text="Essence"
              storageKey="type-carburant"
              value={Object.keys(CARBURANT_VALUES)[typeCarburant]}
              onChange={handleTypeCarburantChange}
              mots={['Diesel', 'GPL', 'GNV', 'E85', 'Hybride', 'Electrique']}
            />
          </div>
          {/* Champ d'entrée pour la consommation */}
          <div className="col-12 col-lg-4">
            <InputFieldNb
              titre="Consommation"
              value={consommation}
              onChange={(value) => handleConsommationChange(value)}
              valueSelect={unit === 279640000 ? 'L/100 km' : 'kW/h'}
              onChangeSelect={handleUnitChange}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="row">
            {/* Liste déroulante pour le type d'énergie */}
            <div className="col-12 col-lg-4">
              <ListDeroule
                titre="Type d'énergie"
                text="Essence"
                storageKey="type-carburant"
                value={Object.keys(CARBURANT_VALUES)[typeCarburant]}
                onChange={handleTypeCarburantChange}
                mots={['Diesel', 'GPL', 'GNV', 'E85', 'Hybride', 'Electrique']}
              />
            </div>
            {/* Liste déroulante pour le type de véhicule */}
            <div className="col-12 col-lg-4">
              <ListDeroule
                titre="Type de véhicule"
                text="Scooteur/moteur < 125cc"
                storageKey="type-vehicule"
                value={typeVehicule}
                onChange={handleTypeVehiculeChange}
                mots={['moto routière > 125cc']}
              />
            </div>
          </div>

          
        </div>
      )}

      {idMoto === "demande" && (
        <div>
            <AppelAPIMoto typeVehicule={typeVehicule} typeCarburant={typeCarburant} 
                valeurInput={valeurInput} frequence={selectedUnite} consommation={consommation} typeKilo={"oui"} 
                unit={unit} setLancer={setLancer} Lancer={true} save={false} setApiResponse={updateApiResponse} setIdMoto={setIdMoto} setAppelMoto={updateAppelMoto} /> 
        </div>
      )}

      {idMoto === "" ? null : (
        <div className="row">
          <div className="col-12">
            {/* Composant CheckboxAjout */}
            <CheckboxAjout
              titre="Connaissez-vous votre kilométrage annuel ?"
              values={typeKilo}
              setValues={(values) => handlekilometrageConnu(values)}
              valeurInput={valeurInput}
              transport="non"
              unit="km"
              setValeurInput={(valeurInput) => setValeurInput(valeurInput)}
              setHandleUnite={handleUniteChange}
            />
          </div>
        </div>
      )}
          

          {/* Condition de rendu basée sur la valeur de "typeKilo" */}
          {typeKilo === 'oui' ? null : (
            <div className="row">
              <div className="col-12">
                {/* Affichage dynamique des voyages */}
                {voyages.map((voyage, index) => (
                  <div className="duplique" key={index}>
                    <div className="row col-12">
                      <div className="SousTitre">
                        {/* Icône de suppression du voyage */}
                        <DeleteOutlinedIcon
                          sx={{ color: '#024C4A' }}
                          className="Icon"
                          onClick={() => handleDeleteVoyage(index)}
                        />
                        <div className="h61">Voyage {index + 1}</div>
                      </div>
                    </div>

                    <div className="row">
                      {/* Liste déroulante pour le type de voyage */}
                      <div className="col-12 col-lg-4">
                        <ListDeroule
                          titre="Type de voyage"
                          text="Aller - retour"
                          storageKey={`uniqueKey-${index}`}
                          value={voyage.typeVoyage === 279640001 ? 'Aller simple':'Aller - Retour'}
                          onChange={(value) => 
                            handleChangeVoyage(index, 'typeVoyage', value==='Aller simple'? 279640001:279640000)}
                          mots={['Aller simple']}
                          />
                      </div>
                      {/* Champ d'entrée pour le départ */}
                      <div className="col-lg-4 col-md-6 col-12">
                      <InputField
                        titre="Départ"
                        value={voyage.depart.name} // Use the name from the depart object
                        onChange={(value) => handleChangeVoyage(index, 'depart', value)}
                        isLocation={true}
                      />
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                      <InputField
                        titre="Arrivée"
                        value={voyage.arrive.name} // Use the name from the arrive object
                        onChange={(value) => handleChangeVoyage(index, 'arrive', value)}
                        isLocation={true}
                      />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-lg-6">
                        {/* Composant Ajout pour la fréquence */}
                        <Ajout
                          values={voyage.ajout}
                          setValues={(values) =>
                            handleChangeAjout(index, values)
                          }
                        />
                      </div>

                      {/* <div className="col-12 col-lg-6">
                        Composant AjoutPerso pour la fréquence personnalisée
                        <AjoutPerso
                          values={voyage.ajoutPerso}
                          setValues={(values) =>
                            handleChangeAjoutPerso(index, values)
                          }
                        />
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-12">
                {/* Bouton pour ajouter un voyage */}
                <button
                  type="button"
                  className="custom-button"
                  onClick={addVoyage}
                >
                  Ajouter un voyage
                </button>
              </div>
            </div>
          )}

      

      <div className="row">
        <div className="col-12">
          {/* Bouton de soumission du formulaire */}
          <Bouton titre="CALCULER" onClick={handleButtonClickAPI} />
          
          {voyages.map((voyage, index) => (
              <div className="duplique" key={index}>
              <AppelAPIMoto index={index} voyage={voyage} idMoto={idMoto} typeVehicule={typeVehicule} typeCarburant={typeCarburant} valeurInput= {valeurInput} consommation={consommation} unit={unit} frequence={selectedUnite} typeKilo={typeKilo} setLancer={setLancer} Lancer={lancer} save={false} setApiResponse={updateApiResponse} setConsoVoyage={updateConsoVoyage} setIdMoto={setIdMoto} setAppelMoto={updateAppelMoto}/>
            {/* {apiResponse && (
            <div>
              <h2>Réponse de l'API :</h2>
              <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
            </div>
          )}  */}
              {/* Affichage des valeurs spécifiques pour ce voyage */}
                  {/* <div className="row">
                    <div className="col-12">
                      <div className='h61'>Valeurs spécifiques pour Voyage {index + 1} :</div>
                      <div>IdPage : PMoto</div>
                      <div>ConsoConnu : {consommationConnue}</div>
                      <div>kmConnu : {typeKilo}</div>
                      <div>km : {valeurInput}</div>
                      <div>Type Carburant : {typeCarburant}</div>
                      <div>Type Vehicule : {typeVehicule}</div>
                      <div>Consommation : {consommation}</div>
                      <div>Unité Consommation : {unit}</div>
                      <div>kmConnu frequence: {selectedUnite}</div>
                      <div>typeVoyage : {voyage.typeVoyage}</div>
                      <div>depart : {voyage.depart.name}</div>
                      <div>latitudeDepart : {voyage.depart.latitude}</div>
                      <div>longitudeDepart : {voyage.depart.longitude}</div>
                      <div>arrive : {voyage.arrive.name}</div>
                      <div>latitudeArrive : {voyage.arrive.latitude}</div>
                      <div>longitudeArrive : {voyage.arrive.longitude}</div>
                      <div>frequence : {voyage.ajout.frequence}</div>
                      <div>nbPersonnes : {voyage.ajoutPerso.valeur}</div>
                      <div>unit : {voyage.ajout.unit}</div>
                      <div>message : {voyage.emptyFieldsMessage}</div>
                    </div>
                  </div> */}
                </div>
              ))}
        </div>
      </div>

      {/* Condition de rendu basée sur la valeur de "formSubmitted" */}
      {displayBilan && (



        <div>
          <div className="row">
            <div className="col-12">
              {/* Composant BilanInter pour afficher les résultats */}
              
              <BilanInter
                voyages={typeKilo === 'oui' ? [] : voyages}
                numeroEtape={2}
                handleDeleteVoyage={handleDeleteVoyage}
                responseAPIVoiture={apiResponse}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              {/* Bouton pour passer à la catégorie suivante */}
              <Bouton
                titre="Catégorie suivante"
                onClick={handleButtonClick}
              />
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

export default FormMoto;
