import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';
import { IdBCContext } from '../IdBCContext';

const AppelAPITaxi = ({index, voyage, setLancer, Lancer, save, setApiResponse, setConsoVoyage, setAppelVoyage, filledData, usedUrl, lancerSave, setLancerSave}) => {
  const { token, fetchToken } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { idBC } = useContext(IdBCContext);

  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if (token && Lancer && idBC) { 
       fetchData();
       setLancer(false);
     }
  }, [token, Lancer]); // Se ré-exécute lorsque `token` change

  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if(token && lancerSave){
       fetchDataToSave(filledData, usedUrl);
       setLancerSave(false)
     }
  }, [token, lancerSave]); // Se ré-exécute lorsque `token` change


  const fetchDataToSave = async (filledData, usedUrl) => {
    setLoading(true);
    filledData.save = true
    
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    try {
      const result = await axios.post(usedUrl, filledData, options);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');

    } finally {
      setLoading(false);
      setLancerSave(false)    
    }
  }

   const fetchData = async () => {
     setLoading(true);
    const apiUrl= 'https://dev-goodplanet.crm12.dynamics.com/api/data/v9.1/aps_Ptaxi_Detail';

    const data = {
        IdBC: idBC,
        IdPage: "Ptaxi",
        typeVoyage: voyage.typeVoyage,
        depart: voyage.depart.name,
        latitudeDepart: voyage.depart.latitude,
        longitudeDepart: voyage.depart.longitude,
        arrivee: voyage.arrive.name,
        latitudeArrivee: voyage.arrive.latitude,
        longitudeArrivee: voyage.arrive.longitude,
        frequence: parseFloat(voyage.ajout.frequence),
        unit: voyage.ajout.unit,
        ConsoConnue: true,
        save: save
      };

    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    //Faire le if sur la condition km connu =true
    try {
      const result = await axios.post(apiUrl, data, options);
      // Stocker à jour l'appel à l'API pour plus tard
      setAppelVoyage(index, data, apiUrl)

       // Mettre à jour la réponse de l'API dans le composant parent
       setApiResponse(result.data);
       setConsoVoyage(index, result.data.ConsoVoyageTaxi);
       setLancer(false);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
    } finally {
      setLoading(false);
    }
  };
  return (null);
};

export default AppelAPITaxi;
