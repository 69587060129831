import React from 'react';
import './Graphique.css';
import Bouton from '../Form/Bouton';
import CarreBilan from './CarreBilan';
import { Link } from 'react-router-dom';

const ContenueCardBilan = ({logo, titre, titregras}) => {
  // Données pour les éléments du graphique
  const handleContribution = () => {
  window.open("https://donate.goodplanet.org/contribution-carbone/~mon-don?ns_ira_cr_arg=IyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyOP0H8Pmt8wK33NPA%2B7QJ7%2BqqMPrqVXTF1p6ubLib1NdQ%3D%3D&_cv=1", '_blank');
  }

  const handleEnSvoirPlus = () => {
  window.open("https://www.goodplanet.org/fr/contribution-a-la-neutralite-carbone-mondiale-tout-pour-comprendre-notre-nouvelle-demarche/", '_blank');
  }
  
  return (
    <div className='ContenueCardBilan row'>
      <div className='col-8'>
        <p className='p1'  style={{marginBottom : "3vh", marginTop : "3vh"}}>
        Anciennement dénommée « compensation carbone volontaire », la contribution à la neutralité carbone est un mécanisme qui permet à une organisation ou à un particulier de financer un projet de réduction ou de séquestration de CO₂.<br/>
        <br/>
        En contribuant à la neutralité carbone mondiale avec la Fondation GoodPlanet, vous soutenez des projets portés par des entités à but social s’inscrivant dans une démarche de solidarité internationale et d’intérêt général sur les thématiques d’accès aux énergies durables et de valorisation des ordures ménagères. <br/>
        <br/>
        La Fondation GoodPlanet accompagne les projets de biogaz en Inde, de cuiseurs solaires en Bolivie et de valorisation des ordures ménagères à Madagascar.
        </p>
      <button type="button" className="custom-button" onClick={handleEnSvoirPlus}>
        EN savoir plus sur les projets et la contribution
      </button>
      </div>
      <div className='col-4'>
        <CarreBilan/>
        <Bouton titre = "JE CONTRIBUE" onClick={handleContribution} />
      </div>
      
    </div>
  );
};

export default ContenueCardBilan;
