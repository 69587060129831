import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/index";
import Card from "../../../components/Card/index";
import Footer from '../../../components/Footer/Index';
import ContenueLogo from "../../../components/ContenuCard/ContenueLogo";
import ContenueBouton from "../../../components/ContenuCard/ContenueBouton";
import TitreH2 from '../../../components/TitreH2';
import TitreBG from '../../../components/TitreBG';
import ImagePlacement from "../../../components/img/Visite.png";
import StepperLine from '../../../components/StepperLine/index-test'
import oui from "../../../components/img/imageLogoOui.png";
import non from "../../../components/img/imageLogoNon.png";

const PagePlacement = () => {
    const navigate = useNavigate();
    const [cardContenu, setCardContenu] = useState(<ContenueLogo Logo={ImagePlacement} Titre="Placements financiers"/>);

    const handleCardClick = () => {
    navigate('/Placement-1')
    // setCardContenu(<ContenueBouton petiteCards={petiteCards} setPetiteCards={setPetiteCards} TitreP="Placements financiers" SousTitreP="Avez-vous des placements financiers ou des économies en banque ?" lien = "/bilan" formType="placement"/>);
    };

    const [petiteCards, setPetiteCards] = useState([
    { label: "OUI", imageName: oui, lien:'/Placement-1' },
    { label: "NON", imageName: non, lien:'/bilan'}
    ]);

    const ajouterPetiteCard = (label, imageName) => {
    const nouvellePetiteCard = { label, imageName };
    setPetiteCards([...petiteCards, nouvellePetiteCard]);
    }

    return (
    <>     
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLine/>

        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>
        <div className="row">
            <div className="col-10 offset-1">
                <TitreH2 Titre="Calculez vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
            </div>
            <div className="row justify-content-center">
            <div className="col-10">
                <Card contenu={cardContenu} onClick={handleCardClick} color="#024C4A"/>
            </div>
            </div>
            {/* <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div> */}
        </div>
        </div>
    </>
    );
};

export default PagePlacement;

