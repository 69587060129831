import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const WordPressAuthContext = createContext();

export function useWordPressAuth() {
  return useContext(WordPressAuthContext);
}

export function WordPressAuthProvider({ children }) {
  const [token, setToken] = useState(() => {
    // Récupération du token depuis le localStorage au montage initial
    return localStorage.getItem('wptoken');
  });
  const [expiry, setExpiry] = useState(() => {
    // Récupération de la date d'expiration depuis le localStorage au montage initial
    const storedExpiry = localStorage.getItem('wp_token_expiry');
    return storedExpiry ? Number(storedExpiry) : null;
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const API_URL = process.env.NODE_ENV === 'production' 
  // ? '/api/wpgettoken'
  // : 'http://localhost:3001/api/wpgettoken';
  
  const API_URL = '/api/wpgettoken'
  
  // Fonction pour obtenir un nouveau token
  const fetchToken = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await axios.post(API_URL);
      setToken(response.data.token);
    } catch (err) {
      setError("An error occurred while fetching the token");
    } finally {
      setLoading(false);
    }
  };

  // Stocker le token et la date d'expiration dans le localStorage chaque fois qu'ils changent
  useEffect(() => {
    if (token) {
      localStorage.setItem('wptoken', token);
    }
    if (expiry) {
      localStorage.setItem('token_expiry', expiry.toString());
    }
  }, [token, expiry]);

  useEffect(() => {
    if (!expiry) {
      return;
    }
  
    const buffer = 5 * 60 * 1000; // 5 minutes en millisecondes => refaire avant qu'il n'expire
    if (Date.now() < expiry - buffer) {
      return;
    }
  
    fetchToken();
  }, [expiry]);
 
  // Obtenez le token initial lorsque l'application charge
  useEffect(() => {
    if (!token || Date.now() > expiry) {
      fetchToken();
    }
  }, []);

  const value = {
    token,
    fetchToken, // Exposez fetchToken pour que les composants puissent demander un nouveau token explicitement si nécessaire
    loading,
    error
  };

  return <WordPressAuthContext.Provider value={value}>{children}</WordPressAuthContext.Provider>;
}

export default WordPressAuthProvider; // Assurez-vous que AuthProvider est exporté
