import React from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import FormAvion from '../../../components/Form/Form Particulier/FormAvion';
import StepperLine from '../../../components/StepperLine/index-test';
import MonFormulaire from '../../../components/Form/Form Particulier/AFormTest';
import StepperLineVoyage from '../../../components/StepperLine/index-voyage';

const AvionVoyage2 = () => {

    return (
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLineVoyage/>

        <div className="container">

            <div className="row">
            <div className="col-10 offset-1">
                {/* Titre */}
                <Titre Titre="Indiquez vos voyages en avion"    place="start"/>
            </div>
            </div>
            <div className="row col-10 offset-1">
            {/* Formulaire pour les voyages en avion */}
            <FormAvion lien={"/Voiture-1"}/>
            {/* <MonFormulaire/> */}
            </div>

            <div className="row">
            <div className="col-10 offset-1">
                {/* En savoir plus */}
                <Ensavoir contenu={
    <div>
        <p>Les émissions prises en compte pour les déplacements en avion sont celles liées à la consommation de carburant (amont et combustion), la fabrication du véhicule (pour la version 21.0 et les suivantes de la Base Carbone) et les trainées de condensation (plus d’informations dans Scope 3 &gt; Transport de personnes &gt; Aérien). Les facteurs d’émissions choisis correspondent à des valeurs moyennes court (&lt;1000 km), moyen (1000-3500 km) et long courrier (&gt; 3500 km) en prenant en compte les trainées de condensation.</p>
        <p>Les trainées de condensation se forment à la sortie d’un réacteur par condensation de la vapeur d’eau si l’environnement est suffisamment froid et humide. En persistant dans le ciel, ces trainées blanches s’assimilent à des nuages qui empêchent le rayonnement terrestre de s’échapper, accentuant ainsi le phénomène de réchauffement. En intégrant les trainées dans le calcul des émissions, ce ne sont pas seulement les émissions liées à la combustion du kérosène qui sont prises en compte mais bien l’ensemble des impacts carbone d’un voyage en avion.</p>
        <p>Dans le cadre de l’information GES des prestations de transport, les émissions liées aux trainées de condensation ne sont pas obligatoires (article 75 de la loi Grenelle II - bilan GES réglementaire). C’est pourquoi la valeur du facteur d’émission peut être divisée par deux dans certains calculs ne prenant pas en compte les trainées de condensation. Ce calculateur ayant un but pédagogique, nous avons privilégié l’exhaustivité de la méthodologie de l'ADEME à la réglementation, afin de sensibiliser à l’impact carbone des déplacements aériens.</p>
    </div>
} />
            </div>
            </div>

            <div className="row justify-content-center">
            <div className="col-8">
                {/* Pied de page */}
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div>
        </div>
        </div>
    </div>
    );
};

export default AvionVoyage2;


