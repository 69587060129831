import React from 'react';
import './Graphique.css';

const CarreBilan = ({logo, titre, titregras}) => {
  // Données pour les éléments du graphique
  
  return (
    <div className='CarreBilanParticulier'>
      <div className='textCarreBilan'>En reversant 24€ à la Fondation GoodPlanet, vous contribuez pour l’équivalent d’une tonne de CO₂ évitée par nos projets </div>
    </div>
  );
};

export default CarreBilan;