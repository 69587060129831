import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/index";
import Card from "../../../components/Card/index";
import Footer from '../../../components/Footer/Index';
import ContenueLogo from "../../../components/ContenuCard/ContenueLogo";
import ContenueBouton from "../../../components/ContenuCard/ContenueBouton";
import TitreH2 from '../../../components/TitreH2';
import TitreBG from '../../../components/TitreBG';
import StepperLine from '../../../components/StepperLine/index-test';
import LogoPre from "../../../components/img/presentiel.png";
import LogoNume from "../../../components/img/numeriqueevent.png";
import LogoHybride from "../../../components/img/hybride.png";
import LogoAgenda from "../../../components/img/Agenda.png";
import StepperLineEvent from '../../../components/StepperLine/index-event';


const General = () => {
    const navigate = useNavigate();
    const [cardContenu, setCardContenu] = useState(<ContenueLogo Logo={LogoAgenda} Titre="Généralités"/>);

    const handleCardClick = () => {
    setCardContenu(<ContenueBouton petiteCards={petiteCards} setPetiteCards={setPetiteCards} TitreP="Généralités" SousTitreP="Quel genre d’événement organisez-vous ?" lien="/evenement/hebergement"/>);
    };

    const [petiteCards, setPetiteCards] = useState([
    { label: "Présentiel", imageName: LogoPre, lien:'/Evenement/Lieu' },
    { label: "Numérique", imageName: LogoNume, lien:'/Evenement/Numerique'    },
    { label: "Hybride", imageName: LogoHybride, lien:'/Evenement/Lieu'    },
    ]);

    const ajouterPetiteCard = (label, imageName) => {
    const nouvellePetiteCard = { label, imageName };
    setPetiteCards([...petiteCards, nouvellePetiteCard]);
    }

    return (
    <>     
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLineEvent/>

        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>
        <div className="row">
            <div className="col-10 offset-1">
                <TitreH2 Titre="Calculez vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
            </div>
            <div className="row justify-content-center">
            <div className="col-10">
                <Card contenu={cardContenu} onClick={handleCardClick} color="#024C4A"/>
            </div>
            </div>
            <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div>
        </div>
        </div>
    </>
    );
};

export default General;

