import React from "react";
import "./Titre.css";

function Titre(props) {
    const { Titre, couleur, place} = props;

    const styleTitre = {
    color: couleur || "#024C4A", // Utilise la couleur spécifiée ou "#024C4A" par défaut
    justifyContent: place === 'center' ? 'center' : 'start',
    marginTop : "2vh",
    marginBottom : "2vh",
    };


    return (
        <h1 className={`h31 ${place === 'center' ? 'text-center' : ''}`} style={styleTitre}>{ Titre }</h1>
    );
}

export default Titre;

