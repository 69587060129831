import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import FormDechet from '../../../components/Form/Form Particulier/FormDechet';


const FormDechet2 = () => {
    const navigate = useNavigate();

    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <Stepperline/>

        <div className="container">

            <div className="row">
            <FormDechet /> 
            </div>

                
            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>Comment renseigner mes données ?</div>
        <p></p>
        <div className='h42' style={{fontWeight: 'bold'}}>Déchets</div>
        <p>Nous considérons la quantité moyenne des déchets ménagers produite par an par un Français, à savoir 496 kg/hab. <a href="https://www.zerowastefrance.org/clefs-succes-collectivites-francaises-produisent-moins-de-dechets/" target="_blank" rel="noopener noreferrer">Source</a></p>
        <p>Les facteurs d’émission proposés sont des facteurs d’émission « fin de vie moyenne filière » à l’échelle de chaque catégorie de déchets ménagers.</p>
        <p>Dans cette catégorie, nous vous demandons d’évaluer en pourcentage la quantité de déchets mise au recyclage pour chaque type de déchets. Jusqu’alors, les déchets plastiques à mettre au recyclage ne concernaient que les bouteilles et flacons. Aujourd’hui, plus de la moitié des Français sont concernés par une simplification du tri des déchets permettant à tous les emballages plastiques d’aller dans le bac de tri. Cette extension des consignes de tri concernera toute la France dans les prochaines années.</p>
        <p>Le recyclage ne permet pas directement de réduire son empreinte carbone mais de transformer et de réutiliser des matières premières, comme le plastique, et d’éviter d’en extraire de nouvelles pour la production. Nous parlerons ainsi d’émissions évitées par le recyclage.</p>

        <div className='h42' style={{fontWeight: 'bold'}}>Consommation</div>
        <p>Dans cette catégorie, nous estimons vos émissions de gaz à effet de serre liées à votre consommation des biens à l’aide de ratios monétaires.</p>
        <p>Évaluez le montant dépensé dans l’achat annuel de mobilier, d’appareils électroniques, et de vêtements. Spécifiez également le nombre d’unités achetées d’occasion (déjà utilisées et achetées en l’état) ou reconditionnées (remises en condition avant la revente).</p>
        <p>Afin de faciliter la collecte et le remplissage des données, nous prenons ici en compte des ratios monétaires : les émissions de gaz à effet de serre sont estimées à partir du montant dépensé. Pour les objets et les vêtements de seconde-main, nous estimons que c’est à l’acheteur initial que reviennent les émissions de gaz à effet de serre liées à cet article. Dans certains cas, notamment pour le matériel numérique, l’objet a pu être reconditionné. C’est-à-dire qu’il a été remis en condition avant la revente, prolongeant ainsi sa durée de vie tout en garantissant un bon fonctionnement de l’appareil.</p>
        <p>Selon l’étude de l’ADEME « évaluation de l’impact environnemental d’un ensemble de produits reconditionnés : synthèse intermédiaire », acheter un smartphone reconditionné revient à éviter les émissions de 25kgCO₂e/an. <a href="https://librairie.ademe.fr/cadic/6720/impact-environnemental-reconditionnement-2022-synthese.pdf" target="_blank" rel="noopener noreferrer">Source</a></p>
    </div>
} />

            </div>
            </div>

            {/* <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div> */}

        </div>
        </div>
    </div>
    </>
    );
};

export default FormDechet2;

