import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import FormTrain from '../../../components/Form/Form Particulier/FormTrain';
import FormPlacement from '../../../components/Form/Form Particulier/FormPlacement';

const PagePlacement1 = () => {
    const navigate = useNavigate();


    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <Stepperline/>

        <div className="container">
            <div className="row">
            <div className="row col-10 offset-1">
                <Titre Titre="Placements financiers"    place="start"/>
            </div>
            </div>
            <div className="row col-10 offset-1">
            <FormPlacement/>
            </div>

                
            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
                <div>
                    <div className='h41'>Estimez ici l’empreinte carbone de votre épargne bancaire (source de données OXFAM)</div>
                    Vos placements financiers vont être indirectement responsables d’émissions de gaz à effet serre. En effet, l’argent investi est utilisé par votre banque pour financer des prêts et des projets pouvant avoir une part de responsabilité importante dans le dérèglement climatique, notamment les énergies fossiles. Mais aujourd’hui, la transparence de l’impact climatique de ces placements est faible. Le rapport réalisé par Oxfam en 2020, <a href='https://www.oxfamfrance.org/app/uploads/2020/10/rapportBanque_OXFAM_v5.pdf' target='_blank'>“Banque : des engagements climat à prendre au 4ème degré”</a>, apporte une première estimation de l’impact de vos comptes courants et épargne selon les établissements. <br/>
                    <br/>
                    Notez cependant que cette estimation ne peut s’additionner à l’empreinte carbone que vous venez de calculer. En effet, les émissions générées par nos activités quotidiennes reposent en grande partie sur des industries et énergies financées par le secteur bancaire. Il s’agit ainsi d’une autre façon de calculer et de prendre conscience de l’impact de nos placements financiers.<br/>
                    <br/>
                    Mode de calcul :<br/>
                    Argent détenu dans la banque (compte courant + compte épargne) * intensité carbone de la banque 
                </div>
            } />

            </div>
            </div>

            {/* <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur le calcul du bilan financier de l'OXFAM®" />
            </div>
            </div> */}

        </div>
        </div>
    </div>
    </>
    );
};

export default PagePlacement1;

