import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/index";
import Card from "../../../components/Card/index";
import Footer from '../../../components/Footer/Index';
import ContenueLogo from "../../../components/ContenuCard/ContenueLogo";
import ContenueBouton from "../../../components/ContenuCard/ContenueBouton";
import TitreH2 from '../../../components/TitreH2';
import TitreBG from '../../../components/TitreBG';
import ImageAvion from "../../../components/img/LogoAvion.png";
import StepperLine from '../../../components/StepperLine/index-test';
import oui from "../../../components/img/imageLogoOui.png";
import non from "../../../components/img/imageLogoNon.png";

const Particulier = () => {
    const navigate = useNavigate();
    // Premier contenu de la carte
    const [cardContenu, setCardContenu] = useState(<ContenueLogo Logo={ImageAvion} Titre="Avion"/>);

    // Fonction appelée lorsqu'une carte est cliquée pour changer le contenu de la carte
        // ContenueBouton : Deuxième contenu de la carte
    const handleCardClick = () => {
    setCardContenu(<ContenueBouton petiteCards={petiteCards} setPetiteCards={setPetiteCards} TitreP="Voyage en avion" SousTitreP="Avez-vous voyagé en avion cette année ?" lien="/Voiture-1" formType="avion"/>);
    };

    // Deuxième contenu : réponse qui peuvent mener à des pages différentes
    const [petiteCards, setPetiteCards] = useState([
    { label: "OUI", imageName: oui, lien:'/FormAvion' },
    { label: "NON", imageName: non, lien:'/Voiture-1'}
    ]);



    // Rendu de la page
    return (
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLine/>

        <div className='TitreBG'>
            <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>

        <div className="row">
            <div className="col-10 offset-1">
            <TitreH2 Titre="Calculez vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
        </div>

        {/* Affichage de la carte avec le contenu dynamique */}
        <div className="row justify-content-center">
            <div className="col-10">
            <Card contenu={cardContenu} onClick={handleCardClick} color="#68C4A5"/>
            </div>
        </div>

        {/* <div className="row justify-content-center">
            <div className="col-8">
            <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
        </div> */}
        
        </div>
    </div>
    );
};

export default Particulier;

