import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import './form.css';
import Ajout from '../Ajout';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Compteur from '../../Compteur/Compteur';
import CheckboxChoixUnique from '../checkBox/checkbox';
import LogoCereale from "../../img/cereale.png";
import LogoCont from "../../img/continental.png";
import LogoSale from "../../img/sale.png";
import LogoRepas from "../../img/vege.png";
import LogoVegetalien from "../../img/vegetalien.png";
import LogoPoisson from "../../img/poisson.png";
import LogoViande from "../../img/viande.png";
import LogoVolail from "../../img/volaille.png";
import LogoThe from "../../img/the.png";
import LogoCafe from "../../img/cafe.png";
import LogoBiere from "../../img/biere.png";
import LogoJus from "../../img/jus.png";
import LogoSodas from "../../img/soda.png";
import CarreLogoCompteur from '../../CarreLogo/CarreLogoCompteur';
import LogoPetitDej from "../../img/PetitDej.png";
import LogoGouter from "../../img/Gouter.png";
import LogoSnack from "../../img/Snack.png";
import LogoBuffet from "../../img/Buffet.png";
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import Titre from '../../Titre';
import SousTitre from '../../SousTitre';



function FormRepas({ lien }) {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Soumission du formulaire
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
  };

  // Gestion du clic sur le bouton
  const handleButtonClick = (event) => {
    event.preventDefault();
    //navigate(lien);
  };

    const [compteurValues, setCompteurValues] = useState({

    petitdej: '0',
    gouter: '0',
    snack: '0',
    buffet: '0',
    repas: '0',

    RepasVege : '0',
    RepasViandeBlanche : '0',
    RepasViandeRouge : '0',
    RepasPoisson : '0',

    EmbalageJetable : '0',
    EmbalageRecyclable : '0',
    QtDechetAlimentaire : '0',
    QtDechetComposter : '0',

    Don: '0',

    cafe: '0',
    the: '0',
    jusFruits: '0',
    soda: '0',
    alcool: '0',
  });


  const handleValeurChange = (nom, valeur) => {
    setCompteurValues({ ...compteurValues, [nom]: valeur });
  };


  return (
    <form>

      <div className='h61'>Repas</div>
      <div className='p1'>Préciser le détails du nombre total de repas servis sur l’ensemble de l’événement :</div>
      <div className="row col-12">
              <div className="col-12 col-md-6 col-lg">
                <CarreLogoInput2
                  logo={LogoPetitDej}
                  titre="Viennoiseries, Pain, Beurre, Confiture, Fruits, etc..."
                  titregras={'Petit Déjeuner'}
                  onValeurChange={(valeur) =>
                    handleValeurChange('petitdej', valeur)
                  }
                  unit = ""
                  nombre={1}
                />
              </div>
              <div className="col-12 col-md-6 col-lg">
              <CarreLogoInput2
                  logo={LogoGouter}
                  titre="Gateaux, fruits..."
                  titregras={'Goûter'}
                  onValeurChange={(valeur) =>
                    handleValeurChange('gouter', valeur)
                  }
                  unit = ""
                  nombre={1}
                />
              </div>
              <div className="col-12 col-md-6 col-lg">
              <CarreLogoInput2
                  logo={LogoSnack}
                  titre="Pic nique, snack..."
                  titregras={'Panier Repas'}
                  onValeurChange={(valeur) =>
                    handleValeurChange('snack', valeur)
                  }
                  unit = "Personne(s)"
                  nombre={1}
                />
              </div>
              <div className="col-12 col-md-6 col-lg">
              <CarreLogoInput2
                  logo={LogoBuffet}
                  titre="Assortiment d’amuse bouche et pret à manger"
                  titregras={'Buffet'}
                  onValeurChange={(valeur) =>
                    handleValeurChange('buffet', valeur)
                  }
                  unit = ""
                  nombre={1}
                />
              </div>
              <div className="col-12 col-md-6 col-lg">
              <CarreLogoInput2
                  logo={LogoRepas}
                  titre="Repas classsique à plusieurs plats"
                  titregras={'Repas'}
                  onValeurChange={(valeur) =>
                    handleValeurChange('repas', valeur)
                  }
                  unit = ""
                  nombre={1}
                />
              </div>
            </div>


        <div className='row col-12'>
          <div className='h61 vert'>Détailler les repas : </div>
        </div>

        <div className='row col-12'>
        <Compteur
          titre="Pourcentage de repas végétarien ou végétalien"
          onValeurChange={(value) => handleValeurChange('RepasVege',value)}
          unit='%'
          />
          </div>

          <div className='row col-12'>
            <div className='row col-12'>
            <Compteur
            titre="Pourcentage de repas non végétariens avec viande blanche"
            onValeurChange={(value) => handleValeurChange('RepasViandeBlanche',value)}
            unit='%'
            />
            </div>
            <div className='row col-md-4 col-12'>
            <Compteur
            titre="Avec viande rouge"
            onValeurChange={(value) => handleValeurChange('RepasViandeRouge',value)}
            unit='%'
            italique={true}
            />
            </div>          
            <div className='row col-md-4 col-12'>
            <Compteur
            titre="Avec poisson"
            onValeurChange={(value) => handleValeurChange('RepasPoisson',value)}
            unit='%'
            italique={true}
            />
            </div>
          </div>

          <div style={{marginTop : "5vh"}}>
          <Titre Titre="Boissons consommées durant l’événement" />
          </div>
            <div className="p1">Préciser la consommation en litre ou en € pour chaque boisson :</div>
            <div className="row col-12">
              <div className="col-12 col-md-6 col-lg">
                <CarreLogoInput2
                  unit={"Litre(s)"}
                  logo={LogoCafe}
                  titre="par tasse à café"
                  titregras={'Café'}
                  onValeurChange={(valeur) =>
                    handleValeurChange('cafe', valeur)
                  }
                />
              </div>
              <div className="col-12 col-md-6 col-lg">
                <CarreLogoInput2
                  unit={"Litre(s)"}
                  logo={LogoThe}
                  titre="par tasse de thé (mug)"
                  titregras={'Thé'}
                  onValeurChange={(valeur) =>
                    handleValeurChange('the', valeur)
                  }
                />
              </div>
              <div className="col-12 col-md-6 col-lg">
                <CarreLogoInput2
                  unit={"Litre(s)"}
                  logo={LogoJus}
                  titre="par verre (25cl)"
                  titregras={'Jus de fruits'}
                  onValeurChange={(valeur) =>
                    handleValeurChange('jusFruits', valeur)
                  }
                />
              </div>
              <div className="col-12 col-md-6 col-lg">
                <CarreLogoInput2
                  unit={"Litre(s)"}
                  logo={LogoSodas}
                  titre="par verre (25cl)"
                  titregras={'Soda'}
                  onValeurChange={(valeur) =>
                    handleValeurChange('soda', valeur)
                  }
                />
              </div>
              <div className="col-12 col-md-6 col-lg">
                <CarreLogoInput2
                  unit={"Litre(s)"}
                  logo={LogoBiere}
                  titre="par verre (25cl)"
                  titregras={'Alcool'}
                  onValeurChange={(valeur) =>
                    handleValeurChange('alcool', valeur)
                  }
                />
              </div>
            </div>

            <div style={{marginTop : "6vh", marginBottom : "2vh"}}>
            <Titre Titre="Déchets & Emballages alimentaires" />
            </div>

            <div className='row col-12'>
            <div className='row col-12'>
            <Compteur
            titre="Emballage et vaisselle jetable "
            onValeurChange={(value) => handleValeurChange('EmbalageJetable',value)}
            unit='kg'
            />
            </div>
            <div className='row col-12'>
            <Compteur
            titre="dont emballage et vaisselle biodégradable ou recyclable"
            onValeurChange={(value) => handleValeurChange('EmbalageRecyclable',value)}
            unit='kg'
            italique={true}
            />
            </div>          
          </div>

          <div className='row col-12'>
            <div className='row col-12'>
            <Compteur
            titre="Quantité de déchets alimentaires "
            onValeurChange={(value) => handleValeurChange('QtDechetAlimentaire',value)}
            unit='kg'
            />
            </div>
            <div className='row col-12'>
            <Compteur
            titre="dont quantité de déchets compostés "
            onValeurChange={(value) => handleValeurChange('QtDechetComposter',value)}
            unit='kg'
            italique={true}
            />
            </div>          
          </div>


          <div className="row">
          <div className="col-12">
          <CheckboxChoixUnique
              onChange={(value) => handleValeurChange('Don',value)}
              titre="Valoriser vous les restes à travers des dons à une association pour limité le gaspillage ?"
              rep1="Oui"
              rep2="Non"
          />
          </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Bouton titre="CALCULER" />
        </div>
      </div>

      {formSubmitted && (
        <div>
          <div className="row">
            <div className="col-12">
              {/* Composant BilanInter */}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

export default FormRepas;
