import React from "react";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import rectangle32 from "../img/rectangle-32.png";
import image4 from "../img/image-4@2x.png";
import "../../Font/font-family.css";
import "../../Font/font-google-api.css";

function Header() {
    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/')
    }
    
    return (
        <header className="header">
            <div className="container container-fluid">
                <div className="row">
                    {/* Logo */}
                    <div className="col-4 offset-4 col-lg-2 col-sm-3 offset-sm-1">
                        <div className="frame-2">
                            <img className="image-4" src={image4} alt="logo" onClick={handleHome}/>
                        </div>
                    </div>

                    {/* Sous-titre */}
                    <div className="col-9    offset-1 col-sm-10 offset-sm-1">
                        <div className="agir-nos-cts">
                            <h4 className="h61">AGIR À NOS CÔTÉS</h4>
                        </div>
                    </div>

                    {/* Trai blanc */}
                    <div className="col-3 offset-1    col-sm-2 offset-sm-1">
                        <div className="rectangle-33"></div>
                    </div>

                    {/* Titre sur image */}
                    <div className="col-9    offset-1 col-sm-10 offset-sm-1">
                        <div className="title">
                            <h1 className="h11">CALCULATEUR CARBONE PARTICULIER</h1>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;



