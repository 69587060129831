import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import CheckboxChoixUnique from '../checkBox/checkbox';
import Compteur from '../../Compteur/Compteur';
import CheckboxChoixUniqueAdapt from '../checkBox/checkbox-adapt';
import imageEnergie from "../../img/Energie.png"
import ConsomationAnnel from './DomicileAnnuel';
import ConsomationAnnuelCollectif from './DomicileAnnuelCollectif';
import BilanInter from '../../BilanInter';
import { useFormProgress } from '../../FormProgressContext';
import AppelAPIDomicile from '../../Api/AppelAPIDomicile';
import {useAppelDict} from '../../BilanInter/appelDictContext'

function FormDomicile({ onSubmit }) {
  const navigate = useNavigate();
  const { formStatus, setFormStatus } = useFormProgress();
  const [displayBilan, setDisplayBilan] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  // const [consommationConnue, setConsommationConnue] = useState("");
  const [ChauffageColl, setChauffageColl] = useState("");
  const [modeChauffage, setModeChauffage] = useState("");
  const [Renove, setRenove] = useState("");
  // const [afficherCheckboxAdapt, setAfficherCheckboxAdapt] = useState(false);

  const [lancer, setLancer] = useState(false);
  const [apiResponse, setApiResponse] = useState(null); // État pour stocker la réponse de l'API

  const reponses = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
  const reponseDates = ['Avant 1975', 'Après 1975'];
  const reponseChauffCol = ['Gaz', 'GPL', 'Chauffage urbain', 'Bois et plaquettes', 'Fioul'];

  const [domicileData, setDomicileData] = useState({
    TypeDeLogement: 279640001,
    NbPersonnes: 1,
    Surface: 20,
    ConsommationConnue: null,
    Etiquette: null,
    DateConstruction: null,
    Renovation: null,
    Pays: 279640000,
    ElectriciqueEuro: null,
    ElectriciqueUnit: null,
    ChauffageUrbainEuro: null,
    ChauffageUrbainUnit: null,
    GplEuro: null,
    GplUnit: null,
    GazEuro: null,
    GazUnit: null,
    BoisEuro: null,
    BoisUnit: null,
    FioulEuro: null,
    FioulUnit: null,
    PanneauSol: null,
    PanneauPhoto: null,
    Eolienne: null,
    ChauffageElectrique: false,
    ChauffageFioulCollectif: false,
    ChauffageFioulIndividuel: false,
    ChauffageGazCollectif: false,
    ChauffageGazIndividuel: false,
    ChauffageGPL: false,
    ChauffageReseau: false,
  })

  const { appelDict, setAppelDict } = useAppelDict();

  // Méthode pour stocker les appels fait à voiture
  const updateAppelDomicile = (index, appelDomicile, apiUrl) => {
		setAppelDomicile((prevAppelDomicile) => {
            const newAppelDomicile = [...prevAppelDomicile];
			      newAppelDomicile[index] = { ...newAppelDomicile[index], appelDomicile: appelDomicile, apiurl: apiUrl };
            addToAppelDict(newAppelDomicile)
			      return newAppelDomicile;
		});
  };

  // Etat pour stocker les voyages
  const [appelVoyages, setAppelDomicile] = useState(() => [
    {
  },
  ]);

  // Méthode pour stocker tous les appels API 
  const addToAppelDict = (appelsDomicile) => {
    setAppelDict(prev => {
        const newAppelDict = { ...prev, 'domicile': appelsDomicile };
        console.log("Appel Dict", newAppelDict);
        return newAppelDict;
    });
  };

  useEffect(() => {
    setFormStatus({
        ...formStatus,
        domicile: 'en cours',
    });
   }, []);

   const handleButtonClickAPI = () => {
    setLancer(true);
    setDisplayBilan(true);
    setFormSubmitted(false);
    // Vous pouvez effectuer ici des traitemensts supplémentaires avec les arguments si nécessaire
    //setApiResponse(null); // Réinitialiser les données avant l'appel API
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    setDisplayBilan(true);
    console.log(formSubmitted);
  }

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/Dechet'); // Ajout de la navigation après la soumission du formulaire
  };

  // const handleCheckboxChange = (value) => {
  //   setConsommationConnue(value);
  //   setAfficherCheckboxAdapt(value === "oui");
  // };

  const handleChangePays = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = { ...prevDomicile };
      updatedDomicile.Pays = value;
      return updatedDomicile
    })
  }

  const handleCheckboxEtiquette = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = {...prevDomicile};

      let etiquetteNum;
      switch (value) {
        case 'A':
          etiquetteNum = 279640000;
          break;

        case 'B':
          etiquetteNum = 279640001;
          break;
            
        case 'C':
          etiquetteNum = 279640002;
          break;
          
        case 'D':
          etiquetteNum = 279640003;
          break;
          
        case 'E':
          etiquetteNum = 279640004;
          break;
          
        case 'F':
          etiquetteNum = 279640005;
          break;
          
        case 'G':
          etiquetteNum = 279640006;
          break;
        default:
          etiquetteNum = value;
      }
      updatedDomicile.Etiquette = etiquetteNum

      return updatedDomicile;
    })
  };

  const handleCompteurNbPersonnes = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = {...prevDomicile};
      updatedDomicile.NbPersonnes = value;
      return updatedDomicile;
    })
  }

  const handleCompteurSurface = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = {...prevDomicile};
      updatedDomicile.Surface = value;
      return updatedDomicile;
    })
  }

  const handleChangeConsommationConnue = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = {...prevDomicile};
      updatedDomicile.ConsommationConnue = value;
      return updatedDomicile;
    })
  }

  const handleCheckboxDate = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = {...prevDomicile};
      let dateConstruction;

      switch (value) {
        case 'Avant 1975':
          dateConstruction = 279640000;
          break;
        
        case 'Après 1975':
          dateConstruction = 279640002;
          break;
        default:
          dateConstruction = value;
      }

      updatedDomicile.DateConstruction = dateConstruction;
      return updatedDomicile;
    })
  }

  const handleRenovation = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = {...prevDomicile};
      updatedDomicile.Renovation = value;
      return updatedDomicile;
    })
  }

  const handleModeChauffage = (value) => {
    setModeChauffage(value);
    console.log(value)
  }

  useEffect(() => {
    const mapping = ['ChauffageGazCollectif', "ChauffageGPL", "ChauffageReseau", "", "ChauffageFioulCollectif"]

    setDomicileData((prevDomicile) => {
      const updatedDomicile = {...prevDomicile};
      const indexTrue = reponseChauffCol.indexOf(modeChauffage);

      // Mettre toutes les clés du mapping à false
      mapping.forEach((key) => {
        if (key) {
          updatedDomicile[key] = false;
        }
      });

      if (indexTrue != -1 && mapping[indexTrue]) {
        const key = mapping[indexTrue];
        updatedDomicile[key] = true;
      }

      return updatedDomicile
    });
  }, [modeChauffage]);

  useEffect(() => {
    if (displayBilan) {
      setFormStatus({
        ...formStatus,
        domicile: 'fini',
      });
    } else if (formSubmitted) {
      setFormStatus({
        ...formStatus,
        domicile: 'en cours',
      });
    }
  }, [formSubmitted]);

  console.log(domicileData)

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-lg">
          <Compteur titre="Nombre total de personnes vivant dans le logement" val={domicileData.NbPersonnes} onChange={handleCompteurNbPersonnes} />
        </div>
        <div className="col-2"></div>
      </div>

      <div className="row">
        <div className="col-12 col-lg">
            <Compteur titre="Surface du logement" unit="m²" val={domicileData.Surface} onChange={handleCompteurSurface}/>
        </div>
        <div className="col-12 col-lg"></div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <div className="row">
          <div className="col-12 col-md-4">
            <ListDeroule
              titre="Pays"
              text="France"
              value={domicileData.Pays}
              onChange={(value) => {
                let convertedValue;
                switch (value) {
                  case "France":
                    convertedValue = 279640000;
                    break;
                  case "Espagne":
                    convertedValue = 279640001;
                    break;
                  case "Italie":
                    convertedValue = 279640002;
                    break;
                  case "Portugal":
                    convertedValue = 279640003;
                    break;
                  default:
                    convertedValue = value; 
                  }
                handleChangePays(convertedValue);}}
                mots={["Espagne", "Italie", "Portugal"]} 
              />
          </div>
        </div>
      </div>
      
      
      <div className="row">
        <div className="col-12">
          <CheckboxChoixUnique
            onChange={(value) => handleChangeConsommationConnue(value)}
            titre="Connaissez-vous la catégorie de votre logement dans l’étiquette climat (kgCO₂/m².an) ?"
            rep1="Oui"
            rep2="Non, mais aidez-moi à la calculer"
          />
        </div>
      </div>

      {domicileData.ConsommationConnue === null ? ("") : domicileData.ConsommationConnue ? (
        <div className="row Connais">
          <div className="col-12 col-lg-4">
            <CheckboxChoixUniqueAdapt
              titre="Quelle est votre étiquette climat ?"
              reponses={reponses}
              onChange={handleCheckboxEtiquette}
            />
          </div>
          <div className="col-12 col-lg-6">
            <img className="imageEtiquette" src={imageEnergie} alt="Étiquette climat" />
          </div>
          <div className="col-12 col-lg-2 etiquette">
            <div className='p3' style={{ backgroundColor: "#EBE085", padding: "10%" }}>
              L'étiquette climat pour connaître la quantité de gaz à effet de serre émise (à droite sur l'image) n'est pas à confondre avec l'étiquette énergie à gauche.
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-12">
              <CheckboxChoixUniqueAdapt
                titre="Date de construction"
                reponses={reponseDates}
                onChange={handleCheckboxDate}
              />
            </div>
          </div>
          {domicileData.DateConstruction && (
            <div className="row">
              <div className="col-12">
                <CheckboxChoixUnique
                  onChange={(value) => handleRenovation(value)}
                  titre="Avez-vous réalisé une rénovation énergétique (isolation, changement de mode de chauffage,…) dans les 10 dernières années ?"
                  rep1="Oui"
                  rep2="Non"
                />
              </div>
            </div>
          )}

          {domicileData.Renovation === null ? ("") : (
            <div className="row">
              <div className="col-12">
                <CheckboxChoixUnique
                  onChange={(value) => setChauffageColl(value)}
                  titre="Réseau de chauffage"
                  rep1="Collectif"
                  rep2="Individuel"
                />
              </div>
            </div>
          )}

          {ChauffageColl === "" ? ("") : ChauffageColl ? (
            <div className="row">
              <div className="col-12">
                <CheckboxChoixUniqueAdapt
                    titre="Mode de chauffage collectif"
                    reponses={reponseChauffCol}
                    onChange={handleModeChauffage}
                  />
                <ConsomationAnnuelCollectif setDomicileData={setDomicileData} />
              </div>
            </div>
          ) : (
            <ConsomationAnnel setDomicileData={setDomicileData} />
          )}
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <Bouton titre="CALCULER" onClick={handleButtonClickAPI} />
          <AppelAPIDomicile domicileData={domicileData} setLancer={setLancer} Lancer={lancer} save={false} setApiResponse={setApiResponse} setAppelDomicile={updateAppelDomicile} />
        </div>
      </div>

      {formSubmitted && (
        <div>
          <div className="row">
            <div className="col-12">
              
              <BilanInter voyage="non" numeroEtape={6} responseAPIDomicile={apiResponse}/>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

export default FormDomicile;
