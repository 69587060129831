import React, { useState } from 'react';
import './Band.css';
import SousTitre from '../../components/SousTitre';
import { shareOnMobile } from 'react-mobile-share';
import IosShareIcon from '@mui/icons-material/IosShare';
import { IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Band1 = ({generateGraphImage, totalConsumption}) => {
  const [imageUrl, setImageUrl] = useState(null);
  // Données pour les éléments du graphique

  const handleClick = async () => {
    setImageUrl(await generateGraphImage());

    shareOnMobile({
      text: `Voici combien j'ai consommé cette année : ${totalConsumption} tonnes de CO₂`,
      url: "https://good-planet-test-app.vercel.app/",
      title: "Ma consommation CO₂ - GoodPlanet",
      image:[imageUrl],
    });
    console.log(imageUrl)
  }
  
  return (
  <div className='row col-12 Band1 justify-item-center'>
    <div className='bandG col-3 row justify-item-center'>
      {/* <div className='col-3'><InstagramIcon sx={{ fontSize: 40, color: 'black' }}/></div>
      <div className='col-3'><FacebookIcon sx={{ fontSize: 40, color: 'black' }}/></div>
      <div className='col-3'><WhatsAppIcon sx={{ fontSize: 40, color: 'black' }}/></div>
      <div className='col-3'><MapsUgcIcon sx={{ fontSize: 40, color: 'black' }}/></div> */}
      {/* <div className='col-3'>
        <InstapaperShareButton url="https://youtube.com" quote="Mon bilan test" hashtag="#Bilan">

          <InstagramIcon sx={{ fontSize: 40, color: 'black' }}/>

        </InstapaperShareButton>
      </div>
      <div className='col-3'>
        <FacebookShareButton url='https://youtube.com' quote="Mon bilan test" hashtag="#Bilan">

          <FacebookIcon sx={{ fontSize: 40, color: 'black' }}/>

        </FacebookShareButton>
      </div>
      <div className='col-3'>
        <LinkedinShareButton url='https://youtube.com' quote="Mon bilan test" hashtag="#Bilan">

          <LinkedInIcon sx={{ fontSize: 40, color: 'black' }}/>

        </LinkedinShareButton>
      </div> */}
      <div className="row justify-content-center">
        <div className="col-3">
          <IconButton 
            onClick={handleClick} >
            <IosShareIcon fontSize='large'/>
          </IconButton>
        </div>
      </div>
    </div>
    
    <div className='col-9'>
      <SousTitre SousTitre={"Partager votre empreinte et vos objectifs carbone"}/>
      <p className='p1'>Invitez vos proches à calculer leur empreinte carbone</p>
      <div className='row' style={{marginTop: '-2vh'}}>
        <FacebookIcon sx={{ fontSize: 55, color: '#024C4A' }}/>
        <LinkedInIcon sx={{ fontSize: 55, color: '#024C4A' }}/>
        <InstagramIcon sx={{ fontSize: 55, color: '#024C4A' }}/>
      </div>
    </div>
    {/* <div className='col-2 justify-content-center'>
      <FacebookIcon sx={{ fontSize: 30, color: '#024C4A' }}/>
      <LinkedInIcon sx={{ fontSize: 30, color: '#024C4A' }}/>
      <InstagramIcon sx={{ fontSize: 30, color: '#024C4A' }}/>
    </div> */}
  </div>
  );
};

export default Band1;
