import React, { useRef, useEffect, useState } from 'react';
import "./Input.css";

function InputField(props) {
  const { titre, value, onChange, text, erreur, isLocation } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [address, setAddress] = useState('');
  const [location, setLocation] = useState(null);
  const [currentValue, setCurrentValue] = useState(value);

  const autocompleteInputRef = useRef(null);
  let autocomplete;
  
  const handlePlaceChanged = () => {
    const place = autocomplete.getPlace();
    const cityData = {
    name: place.formatted_address,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    };
    setAddress(cityData.name);
    setLocation(place.geometry.location);
    setCurrentValue(cityData.name); // Update the current value to display the selected place
    onChange(cityData);
  };
  
  
  
  useEffect(() => {
    autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteInputRef.current,
      { types: ['(cities)'] }
    );
    autocomplete.addListener('place_changed', handlePlaceChanged);
    
    return () => {
      window.google.maps.event.clearInstanceListeners(autocomplete);
    };
  }, []);
  
  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const handleInputChange = (e) => {
    setCurrentValue(e.target.value);
    localStorage.setItem(titre, e.target.value);
  };
  

  const handleInputFocus = () => {
  setIsFocused(true);
  };

  const handleInputBlur = () => {
  setIsFocused(false);
  };

  useEffect (() => {
  if (!isLocation) {
    onChange(currentValue)
  }
  }, [currentValue])

  return (
  <div className="row">
    <div className={`col-25 ${isFocused || value ? 'label-up' : ''} ${erreur ? 'label-error' : ''}`}>
    <label className='h61'>{titre}</label>
    </div>
    <div className="col-75">
    <div className={`normal ${erreur ? 'erreur' : ''}`}>
    {isLocation && (
    <input
    type="text"
    ref={autocompleteInputRef}
    className={`form-control ${erreur ? 'input-error' : ''}`}
    placeholder={value ? value : text}
    value={currentValue}
    onChange={handleInputChange}
    onFocus={handleInputFocus}
    onBlur={handleInputBlur}
    />
    )}
    {!isLocation && (
    <input
    type="text"
    className={`form-control ${erreur ? 'input-error' : ''}`}
    placeholder={value ? value : text}
    value={currentValue}
    onChange={handleInputChange}
    onFocus={handleInputFocus}
    onBlur={handleInputBlur}
    />
    )}
    </div>
    </div>
  </div>
  );
}

export default InputField;
