import React from 'react';
import ImageAvion from "../img/LogoAvion.png";
import './Card.css';
import { colors } from '@mui/material';
import Bouton from '../Form/Bouton';

const Card = ({ contenu, onClick, color, intro, onClickButton }) => {
  return (
      <div className="default-card align-items-center justify-content-center" onClick={onClick} style={{ boxShadow: `-6px 6px 0px ${color}` }}>
          {contenu}
        <div>
          {intro && (
            <div className="row justify-content-end mt-4">
              <div className="col-md-6 col-sm-6 col-12 text-right">
              {/* Bouton Catégorie suivante */}
              <Bouton titre="Commencer le formulaire" onClick={onClickButton} />
              </div>
            </div>
          )}
        </div>
      </div>

  );
}

export default Card;