import React, { createContext, useState } from 'react';

const CacheContext = createContext();

export const CacheProvider = ({ children }) => {
	const [cache, setCache] = useState({});

	return (
		<CacheContext.Provider value={{ cache, setCache }}>
			{children}
		</CacheContext.Provider>
	);
};

export default CacheContext;
