import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AppelAPITaxi from '../../Api/AppelAPITaxi';
import {useAppelDict} from '../../BilanInter/appelDictContext'


function FormTaxi({ onSubmit }) {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false); // nouvel état pour suivre la soumission du formulaire
  const [displayBilan, setDisplayBilan] = useState(false);

  const { appelDict, setAppelDict } = useAppelDict();

  const updateAppelVoyageTaxi = (index, appelTaxi, apiUrl) => {
		setAppelTaxi((prevAppelTaxi) => {
            const newAppelTaxi = [...prevAppelTaxi];
			      newAppelTaxi[index] = { ...newAppelTaxi[index], appelTaxi: appelTaxi, apiUrl: apiUrl };
            addToAppelDict(newAppelTaxi)
			return newAppelTaxi;
		});
    };

    // Etat pour stocker les Taxi
    const [appelTaxi, setAppelTaxi] = useState(() => [
    	{
		},
	]);


  const addToAppelDict = (appelsTaxi) => {
    setAppelDict(prev => {
        const newAppelDict = { ...prev, 'taxi': appelsTaxi };
        console.log("Appel Dict", newAppelDict);
        return newAppelDict;
    });
};


  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    setDisplayBilan(true);
     // indique que le formulaire a été soumis SetDisplay Bilan (true) quand reponse api je repasse à false. le display de bilan inter se fera sur displaybilan
    console.log(formSubmitted);
  }


  const [lancer, setLancer] = useState(false);
  const [apiResponse, setApiResponse] = useState(null); // État pour stocker la réponse de l'API
   

  // Fonction pour mettre à jour l'état de la réponse de l'API
  const updateApiResponse = (response) => {
    setApiResponse(response);
  };


  const handleButtonClickAPI = () => {
    setLancer(true);
    setDisplayBilan(true);
    setFormSubmitted(false);
    console.log(lancer);
    // Vous pouvez effectuer ici des traitemensts supplémentaires avec les arguments si nécessaire
    //setApiResponse(null); // Réinitialiser les données avant l'appel API
  };

  

  const [voyagesTaxi, setVoyagesTaxi] = useState([
    {
      typeVoyage: '',
      depart: {
        name: '',
        latitude: null,
        longitude: null
      },
      arrive: {
        name: '',
        latitude: null,
        longitude: null
      },
      ajout: { frequence: '', unit: '' },
      consoVoyage: null
    },
  ]);

  // useEffect(() => {
  //   localStorage.setItem('voyages', JSON.stringify(voyages));
  // }, [voyages]);

  const addVoyage = useCallback(() => {
    setVoyagesTaxi((prevVoyages) => [
      ...prevVoyages,
      {
        typeVoyage: '',
        depart: {
          name: '',
          latitude: null,
          longitude: null
        },
        arrive: {
          name: '',
          latitude: null,
          longitude: null
        },
        ajout: { frequence: '', unit: '' },
        consoVoyage: null
      },
    ]);
  }, []);

  const setConsoVoyage = (index, value) => {
    setVoyagesTaxi((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      updatedVoyages[index].consoVoyage = value;
      return updatedVoyages;
    })
  }

  const handleChangeVoyage = useCallback((index, field, value) => {
    setVoyagesTaxi((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        if (field === 'depart' || field === 'arrive') {
          voyage[field] = { ...voyage[field], ...value };
        } else {
          voyage[field] = value;
        }
      }
      return updatedVoyages;
    });
  }, []);

  const handleChangeAjout = useCallback((index, values) => {
    setVoyagesTaxi((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        // Récupérer les valeurs de fréquence et unité
        const { frequence, unit } = values;
        
        // Transformer unit selon les conditions
        let unitValue;
        switch (unit) {
          case 'an':
            unitValue = 279640000;
            break;
          case 'mois':
            unitValue = 279640001;
            break;
          case 'semaine':
            unitValue = 279640002;
            break;
          default:
            unitValue = 279640000; // Défaut à an si unit n'est pas reconnu
        }
        
        // Mettre à jour les valeurs
        voyage.ajout = { frequence: frequence, unit: unitValue };
      }
      return updatedVoyages;
    });
  }, [setVoyagesTaxi]);



  const handleDeleteVoyage = (index) => {
    setVoyagesTaxi((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      updatedVoyages.splice(index, 1);
      return updatedVoyages;
    });
  };
  
  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate("/Train"); 
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row col-12">
          <p className='p4'>
            Si votre voyage comporte une ou plusieurs escales, décomposez-le en plusieurs voyages.
          </p>
      </div>
  
      {voyagesTaxi.map((voyage, index) => (
        <div className="duplique" key={index}>
          <div className="row col-12">
            <div className="SousTitre">
              <DeleteOutlinedIcon sx={{ color : "#024C4A"}} className="Icon" onClick={() => handleDeleteVoyage(index)} />
              <div className="h61">Voyage {index + 1}</div>
            </div>
          </div>
  
          <div className="row">
            <div className="col-lg-4 col-12">
              <ListDeroule
                titre="Type de voyage"
                value={voyage.typeVoyage === 279640001 ? 'Aller simple':'Aller - Retour'}
                onChange={(value) => handleChangeVoyage(index, 'typeVoyage', value==='Aller simple'? 279640001:279640000)}
                text="Aller - retour"
                mots={['Aller simple']}
              />
            </div>
            <div className="col-lg-4 col-12">
            <InputField
              titre="Départ"
              value={voyage.depart.name} // Use the name from the depart object
              onChange={(value) => handleChangeVoyage(index, 'depart', value)}
              isLocation={true}
            />
            </div>
            <div className="col-lg-4 col-12">
            <InputField
              titre="Arrivée"
              value={voyage.arrive.name} // Use the name from the arrive object
              onChange={(value) => handleChangeVoyage(index, 'arrive', value)}
              isLocation={true}
            />
            </div>
          </div>
  
          <div className="row">
            <div className="col-12">
              <Ajout values={voyage.ajout} setValues={(values) => handleChangeAjout(index, values)} />
            </div>
          </div>
        </div>
      ))}
  
      <div className="row">
        <div className="col-12">
          <button type="button" className="custom-button" onClick={addVoyage}>
            Ajouter un voyage
          </button>
        </div>
      </div>
  
      <div className="row">
        <div className="col-12">

          <Bouton titre="CALCULER" onClick={handleButtonClickAPI}/>
          
          {voyagesTaxi.map((voyage, index) => (
            <AppelAPITaxi index={index} voyage={voyage} setLancer={setLancer} Lancer={lancer} save={false} setApiResponse={updateApiResponse} setConsoVoyage={setConsoVoyage} setAppelVoyage={updateAppelVoyageTaxi} />
          ))}
        </div>
      </div>
  
      {displayBilan && ( 
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInter voyages={voyagesTaxi} numeroEtape={2} responseAPIVoiture={apiResponse}/>
            </div>
          </div>
  
          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick}/>
              
            </div>
          </div>
        </div>
      )}
    </form>
  );
}  

export default FormTaxi;
