import React, { useState } from 'react';
import Voyages from './cityForm';
import BilanInterRequete from '../../BilanInter/indexRequete';

function MonFormulaire() {
  const formulaireId = 'formulaire1'; // Remplacez par un identifiant unique pour chaque formulaire
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [resultatsRequetes, setResultatsRequetes] = useState([]); // État pour les résultats de requête
  const [voyages, setVoyages] = useState([]); // État pour les voyages

  const handleSubmit = () => {
  setFormSubmitted(true);
  };

  // Cette fonction sera appelée depuis le composant Voyages pour mettre à jour les résultats de requête et les voyages
  const handleRequeteTerminee = (resultat, voyageIndex, updatedVoyages) => {
  setResultatsRequetes((prevResultats) => {
    const updatedResultats = [...prevResultats];
    updatedResultats[voyageIndex] = resultat;
    return updatedResultats;
  });

  setVoyages(updatedVoyages);
  };

  return (
  <form>
    <h2>Mon Formulaire</h2>
    <Voyages
    formulaireId={formulaireId}
    formSubmitted={formSubmitted}
    onRequeteTerminee={handleRequeteTerminee} // Passer la fonction pour mettre à jour les résultats de requête et voyages
    />

    <button type="button" onClick={handleSubmit}>
    Soumettre le formulaire
    </button>

    {/* Afficher BilanInterRequete si le formulaire a été soumis */}
    {formSubmitted && (
    <BilanInterRequete
      voyages={voyages} // Passer les voyages mis à jour au composant BilanInterRequete
      numeroEtape={1}
    />
    )}
  </form>
  );
}

export default MonFormulaire;
