import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Titre from '../../Titre';
import CheckboxChoixUnique from '../checkBox/checkbox';
import Compteur from '../../Compteur/Compteur';
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import LogoPapier from "../../img/papier1.png";
import LogoBureautique from "../../img/Bureautique.png";
import LogoFourniture from "../../img/Fourniture.png";
import LogoHberge from "../../img/heberge.png";
import LogoVisioconf from "../../img/visioconf.png";
import LogoReseau from "../../img/reseau.png";
import LogoVisite from "../../img/Visite.png";
import AjoutNume from '../Ajout/indexNumerique';
import BilanInterEnt from '../../BilanInter/indexEntreprise';




function FormNumerique({ onSubmit }) {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [consommationConnue, setConsommationConnue] = useState(false);
  const [mail, setMail] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log(formSubmitted);
  }

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/Entreprise/Bien'); // Ajout de la navigation après la soumission du formulaire
  };

  const handleConsommationConnueChange = (value) => {
    setConsommationConnue(value);
  };

  const [batiments, setBatiments] = useState([
    {
      Surface: '',
      Type: '',
      Structure: ''
    },
  ]);

  useEffect(() => {
    localStorage.setItem('batiments', JSON.stringify(batiments));
  }, [batiments]);

  const addBatiment = useCallback(() => {
    setBatiments((prevBatiments) => [
      ...prevBatiments,
      {
        Surface: '',
        Type: '',
        Structure: ''
      },
    ]);
  }, []);

  const handleChangeBatiment = useCallback((index, field, value) => {
    setBatiments((prevBatiments) => {
      const updatedBatiments = [...prevBatiments];
      const batiment = updatedBatiments[index];
      if (batiment) {
        batiment[field] = value;
      }
      return updatedBatiments;
    });
  }, []);

  const handleDeleteBatiment = (index) => {
    setBatiments((prevBatiments) => {
      const updatedBatiments = [...prevBatiments];
      updatedBatiments.splice(index, 1);
      return updatedBatiments;
    });
  };

  const handleChangeMail = useCallback((values) => {
    setMail(values)
  });

  return (
    <form onSubmit={handleSubmit}>
      <AjoutNume values={mail} setValues={(values) => handleChangeMail(values)} />
  
      <div className="row col-12">
        <div className="col-12 col-md-6 col-lg-3">
          <CarreLogoInput2 logo={LogoReseau} titregras="Posts sur les réseaux" titre="Nombre de posts sur les réseaux sociaux par mois" nombre={1} unit="Post(s) par mois" />
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <CarreLogoInput2 logo={LogoVisite} titregras="Visites du site" titre="Nombre de visites quotidiennes sur le site internet" nombre={1} unit="Visite(s) quotidienne(s)" />
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <CarreLogoInput2 logo={LogoVisioconf} titregras="Visioconférence" titre="Temps passé en visio (avec la vidéo) par salarié par semaine" nombre={1} unit="Heure(s)" />
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <CarreLogoInput2 logo={LogoHberge} titregras="Vidéos hébergées" titre="Nombre de minutes de vidéo hébergée sur internet" nombre={1} unit="Minute(s)" />
        </div>
      </div>
  
      <div className="row">
        <div className="col-12" style={{ marginTop: "3vh" }}>
          <Bouton titre="Calculer" onClick={handleButtonClick} />
        </div>
      </div>
  
      {formSubmitted && (
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInterEnt voyages={[]} numeroEtape={6} />
            </div>
          </div>
  
          <div className="row">
            <div className="col-12">
              <Bouton
                titre="Catégorie suivante"
                onClick={handleButtonClick}
              />
            </div>
          </div>
        </div>
      )}
    </form>
  );
  
}

export default FormNumerique;

