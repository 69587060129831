import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../Titre/index";
import Header from "../../Header/index";
import Stepperline from "../../StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./form.css"; 
import Ensavoir from "../../Ensavoir";
import BilanInter from "../../BilanInter";
import Footer from "../../Footer/Index";
import CarreLogoCompteur from '../../CarreLogo/CarreLogoCompteur';
import LogoCereale from "../../img/cereale.png";
import LogoCont from "../../img/continental.png";
import LogoSale from "../../img/sale.png";
import LogoVege from "../../img/vege.png";
import LogoVegetalien from "../../img/vegetalien.png";
import LogoPoisson from "../../img/poisson.png";
import LogoViande from "../../img/viande.png";
import LogoVolail from "../../img/volaille.png";
import LogoThe from "../../img/the.png";
import LogoCafe from "../../img/cafe.png";
import LogoBiere from "../../img/biere.png";
import LogoJus from "../../img/jus.png";
import LogoSodas from "../../img/soda.png";
import { useFormProgress } from '../../FormProgressContext'; //Pour suivre l'état d'avancement du remplissage du formulaire

import SousTitre from '../../SousTitre';
import TitreH2 from '../../TitreH2';
import Compteur from '../../Compteur/Compteur';
import Alert from '@mui/material/Alert';
import Bouton from '../Bouton';
import AppelAPIAlimentaire from '../../Api/AppelAPIAlimentaire';
import {useAppelDict} from '../../BilanInter/appelDictContext'


const FormAlimentaire2 = ({nombrePetitDej}) => {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false); // nouvel état pour suivre la soumission du formulaire
  const [displayBilan, setDisplayBilan] = useState(false);
  const { formStatus, setFormStatus } = useFormProgress();

  const { appelDict, setAppelDict } = useAppelDict();

  // Méthode pour stocker les appels fait à voiture
  const updateAppelAlimentaire = (index, appelAlimentaire, apiUrl) => {
		setAppelAlimentaire((prevAppelAlimentaire) => {
            const newAppelAlimentaire = [...prevAppelAlimentaire];
			      newAppelAlimentaire[index] = { ...newAppelAlimentaire[index], appelAlimentaire: appelAlimentaire, apiUrl: apiUrl };
            addToAppelDict(newAppelAlimentaire)
			      return newAppelAlimentaire;
		});
  };

  // Etat pour stocker les voyages
  const [appelVoyages, setAppelAlimentaire] = useState(() => [
    {
  },
  ]);

  // Méthode pour stocker tous les appels API 
  const addToAppelDict = (appelsAlimentaire) => {
    setAppelDict(prev => {
        const newAppelDict = { ...prev, 'alimentaire': appelsAlimentaire };
        console.log("Appel Dict", newAppelDict);
        return newAppelDict;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    setDisplayBilan(true);
     // indique que le formulaire a été soumis SetDisplay Bilan (true) quand reponse api je repasse à false. le display de bilan inter se fera sur displaybilan
    console.log(formSubmitted);
  }
  
  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate("/Domicile"); // Ajout de la navigation après la soumission du formulaire
  };

  const [lancer, setLancer] = useState(false);
  const [apiResponse, setApiResponse] = useState(null); // État pour stocker la réponse de l'API
   

  // Fonction pour mettre à jour l'état de la réponse de l'API
  const updateApiResponse = (response) => {
    setApiResponse(response);
  };


  const handleButtonClickAPI = () => {
    setLancer(true);
    setDisplayBilan(true);
    setFormSubmitted(false);
    setFormStatus({ ...formStatus, alimentaire: 'fini' });
    // Vous pouvez effectuer ici des traitemensts supplémentaires avec les arguments si nécessaire
    //setApiResponse(null); // Réinitialiser les données avant l'appel API
  };



  useEffect(() => {
    setFormStatus({
        ...formStatus,
        alimentaire: 'en cours',
    });
   }, []);


 useEffect(() => {
   if (displayBilan) {
     setFormStatus({
       ...formStatus,
       alimentaire: 'fini',
     });
   } else if (formSubmitted) {
     setFormStatus({
       ...formStatus,
       alimentaire: 'en cours',
     });
   }
 }, [formSubmitted]);

  const [compteurValues, setCompteurValues] = useState({
    cerealier: '0',
    continental: '0',
    sale: '0',
    vegetarien: '0',
    vegetalien: '0',
    produitsMarins: '0',
    viandeBovine: '0',
    viandeVolaille: '0',
    viandeRougePlus: '0',
    viandeRougeEntre: '0',
    viandeRougeMoins: '0',
    viandeBlanchePlus: '0',
    viandeBlancheEntre: '0',
    viandeBlancheMoins: '0',
    cafe: '0',
    the: '0',
    jusFruits: '0',
    soda: '0',
    alcool: '0',
  });
  // console.log(apiResponse);
  const handleValeurChange = (nom, valeur) => {
    setCompteurValues({ ...compteurValues, [nom]: valeur });
  };

  const countTotalViandeRouge = () => {
    const {
      viandeRougePlus,
      viandeRougeMoins,
      viandeRougeEntre,
    } = compteurValues;

    const totalViandeRouge =
      parseInt(viandeRougePlus) +
      parseInt(viandeRougeMoins) +
      parseInt(viandeRougeEntre);

    return totalViandeRouge;
  };

  const countTotalViandeBlanche = () => {
    const {
      viandeBlanchePlus,
      viandeBlancheMoins,
      viandeBlancheEntre,
    } = compteurValues;

    const totalViandeBlanche =
      parseInt(viandeBlanchePlus) +
      parseInt(viandeBlancheMoins) +
      parseInt(viandeBlancheEntre);

    return totalViandeBlanche;
  };

  const countTotalRepas = () => {
    const {
      vegetarien,
      vegetalien,
      produitsMarins,
      viandeBovine,
      viandeVolaille,
    } = compteurValues;

    const totalRepas =
      parseInt(vegetarien) +
      parseInt(vegetalien) +
      parseInt(produitsMarins) +
      parseInt(viandeBovine) +
      parseInt(viandeVolaille);

    return totalRepas;
  };

  const countTotalPetitDej = () => {
    const {
      cerealier,
      continental,
      sale,
    } = compteurValues;

    const totalPetitDej =
      parseInt(cerealier) +
      parseInt(continental) +
      parseInt(sale);

    return totalPetitDej;
  };

  

  const totalRepas = countTotalRepas();
  const totalPetitDej = countTotalPetitDej();
  const totalViandeRouge = countTotalViandeRouge();
  const totalViandeBlanche = countTotalViandeBlanche();



  return (
    
    <form onSubmit={handleSubmit} className="container">
      <div>
            <Titre Titre="Petit déjeuner :" />
            <div className="h61">
              Précisez le détail de vos petits déjeuners ({totalPetitDej} / {nombrePetitDej} petits déjeuners) :
            </div>
            <div className='popup'>
            {totalPetitDej > nombrePetitDej && (
              <Alert severity="error">
                Trop de petits déjeuners sont renseignés.
              </Alert>
            )}
            {totalPetitDej < nombrePetitDej && (
              <Alert severity="error">
                Tous les petits déjeuners ne sont pas renseignés.
              </Alert>
            )}
            </div>
            <div className="row col-12">
              <div className="col-lg col-md-4 col-12">
                <CarreLogoCompteur
                  logo={LogoCereale}
                  titre="Lait & Céréales"
                  titregras={'Céréalier'}
                  onChange={(valeur) =>
                    handleValeurChange('cerealier', valeur)
                  }
                />
              </div>
              <div className="col-lg col-md-4 col-12">
                <CarreLogoCompteur
                  logo={LogoCont}
                  titre="Viennoiseries, Pain, Beurre, Confiture, Fruits, etc..."
                  titregras={'Continental'}
                  onChange={(valeur) =>
                    handleValeurChange('continental', valeur)
                  }
                />
              </div>
              <div className="col-lg col-md-4 col-12">
                <CarreLogoCompteur
                  logo={LogoSale}
                  titre="Oeuf, Jambon , Pain , etc..."
                  titregras={'Salé'}
                  onChange={(valeur) =>
                    handleValeurChange('sale', valeur)
                  }
                />
              </div>
              <div className="col-lg"></div>
              <div className="col-lg"></div>
            </div>

            <Titre Titre="Repas par semaine :" />
            <div className="row">
            <div className="h61 col-lg-8 col-12">
              Précisez le détail de vos repas (14 repas : 7 déjeuners & 7 diners) :
            </div>
            <div className="h71 col-md-4 col-12">
            {totalRepas > 14 && (
              <div className="row justify-content-lg-end justify-content-center droit align-items-center" style={{color : 'red'}}>
              {totalRepas}/14 repas indiqués
              </div>
            )}
            {totalRepas < 14 && (
              <div className="row justify-content-lg-end justify-content-center droit align-items-center">
              {totalRepas}/14 repas indiqués
              </div>
            )}

            </div>
            </div>

            <div className='popup'>
            {totalRepas > 14 && (
              <Alert severity="error">
                Le nombre de repas n’est pas égal à 14. Recalculer si ce n'est pas volontaire.
              </Alert>
            )}
            {totalRepas < 14 && (
              <Alert severity="error">
                Le nombre de repas n’est pas égal à 14. Recalculer si ce n'est pas volontaire.
              </Alert>
            )}
            </div>

            <div className="row col-12">
              <div className="col">
                <CarreLogoCompteur
                  logo={LogoVege}
                  titre="Repas sans viande."
                  titregras={'Végétarien'}
                  onChange={(valeur) =>
                    handleValeurChange('vegetarien', valeur)
                  }
                />
              </div>
              <div className="col">
                <CarreLogoCompteur
                  logo={LogoVegetalien}
                  titre="Repas sans produits d’origine animale."
                  titregras={'Végétalien'}
                  onChange={(valeur) =>
                    handleValeurChange('vegetalien', valeur)
                  }
                />
              </div>
              <div className="col">
                <CarreLogoCompteur
                  logo={LogoPoisson}
                  titre="Repas avec produits maritimes."
                  titregras={'Avec produits marins'}
                  onChange={(valeur) =>
                    handleValeurChange('produitsMarins', valeur)
                  }
                />
              </div>
              <div className="col">
                <CarreLogoCompteur
                  logo={LogoViande}
                  titre="Repas avec viande bovine."
                  titregras={'Avec viande rouge'}
                  onChange={(valeur) =>
                    handleValeurChange('viandeBovine', valeur)
                  }
                />
              </div>
              <div className="col">
                <CarreLogoCompteur
                  logo={LogoVolail}
                  titre="Repas avec viande de volaille."
                  titregras={'Avec viande blanche'}
                  onChange={(valeur) =>
                    handleValeurChange('viandeVolaille', valeur)
                  }
                />
              </div>
            </div>

            <div className="row">
          <div className="col-12">
            <Titre Titre="Repas par semaine :" />
            <div className="h61">Détaillez vos repas avec viande rouge ({compteurValues.viandeBovine} repas):</div>
            <div className="row">
              <div className="col-12">
                <Compteur
                  titre="Repas majoritaire en viande (+ de 2/3 de l’assiette)"
                  onChange={(valeur) => handleValeurChange('viandeRougePlus', valeur)}
                  block={totalViandeRouge >= parseInt(compteurValues.viandeBovine)}
                />
              </div>
              <div className="col-12">
                <Compteur
                  titre="Repas classique en viande (entre 1/3 et 2/3 de l’assiette)"
                  onChange={(valeur) => handleValeurChange('viandeRougeEntre', valeur)}
                  block={totalViandeRouge >= parseInt(compteurValues.viandeBovine)}
                />
              </div>
              <div className="col-12">
                <Compteur
                  titre="Repas minoritaire en viande (- de 1/3 de l’assiette)"
                  onChange={(valeur) => handleValeurChange('viandeRougeMoins', valeur)}
                  block={totalViandeRouge >= parseInt(compteurValues.viandeBovine)}
                />
              </div>
            </div>

            <div className="h61" style={{ marginTop: '5vh' }}>
              Détaillez vos repas avec viande blanche ({compteurValues.viandeVolaille} repas):
            </div>
            <div className="row">
              <div className="col-12">
                <Compteur
                  titre="Repas majoritaire en viande (+ de 2/3 de l’assiette)"
                  onChange={(valeur) => handleValeurChange('viandeBlanchePlus', valeur)}
                  block={totalViandeBlanche >= parseInt(compteurValues.viandeVolaille)}
                />
              </div>
              <div className="col-12">
                <Compteur
                  titre="Repas classique en viande (entre 1/3 et 2/3 de l’assiette)"
                  onChange={(valeur) => handleValeurChange('viandeBlancheEntre', valeur)}
                  block={totalViandeBlanche >= parseInt(compteurValues.viandeVolaille)}
                />
              </div>
              <div className="col-12">
                <Compteur
                  titre="Repas minoritaire en viande 
                  (- de 1/3 de l’assiette)"
                  onChange={(valeur) => handleValeurChange('viandeBlancheMoins', valeur)}
                  block={totalViandeBlanche >= parseInt(compteurValues.viandeVolaille)}
                />
              </div>
            </div>
          </div>
        </div>


            <Titre Titre="Boissons consommées par semaine :" />
            <div className="h61">Précisez votre consommation pour chaque boisson </div>
            <div className="row col-12">
              <div className="col">
                <CarreLogoCompteur
                  logo={LogoCafe}
                  titre="par tasse à café"
                  titregras={'Café'}
                  onChange={(valeur) =>
                    handleValeurChange('cafe', valeur)
                  }
                />
              </div>
              <div className="col">
                <CarreLogoCompteur
                  logo={LogoThe}
                  titre="par tasse de thé (mug)"
                  titregras={'Thé'}
                  onChange={(valeur) =>
                    handleValeurChange('the', valeur)
                  }
                />
              </div>
              <div className="col">
                <CarreLogoCompteur
                  logo={LogoJus}
                  titre="par verre (25cl)"
                  titregras={'Jus de fruits'}
                  onChange={(valeur) =>
                    handleValeurChange('jusFruits', valeur)
                  }
                />
              </div>
              <div className="col">
                <CarreLogoCompteur
                  logo={LogoSodas}
                  titre="par verre (25cl)"
                  titregras={'Soda'}
                  onChange={(valeur) =>
                    handleValeurChange('soda', valeur)
                  }
                />
              </div>
              <div className="col">
                <CarreLogoCompteur
                  logo={LogoBiere}
                  titre="par verre (25cl)"
                  titregras={'Alcool'}
                  onChange={(valeur) =>
                    handleValeurChange('alcool', valeur)
                  }
                />
              </div>
            </div>


            <div className="row">
        <div className="col-12 bouton">
          <Bouton titre="CALCULER" onClick={handleButtonClickAPI}/>

          <AppelAPIAlimentaire compteurAlim={compteurValues} setLancer={setLancer} Lancer={lancer} save={false} setApiResponse={updateApiResponse} setAppelAlimentaire={updateAppelAlimentaire}/>
          
        </div>
      </div>

      {displayBilan && apiResponse &&( 
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInter numeroEtape={5} voyage="non" alimentaire="oui" responseAPI={apiResponse} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick}/>
            </div>
          </div>
          
          {/* <div>
              <h2>Valeurs des compteurs :</h2>
              <ul>
                {Object.entries(compteurValues).map(([nom, valeur]) => (
                  <li key={nom}>
                    {nom} : {valeur}
                  </li>
                ))}
              </ul>
            </div> */}
        </div>            
      )}
    </div>


    </form>
  );
};

export default FormAlimentaire2;
