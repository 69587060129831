import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import CheckboxChoixUnique from '../checkBox/checkbox';
import InputFieldNb from '../InputNombre/index';
import CheckboxAjout from '../checkBoxAjout/CheckboxAjout';
import AjoutPerso from '../Ajout/index-personne';
import { Button } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Compteur from '../../Compteur/Compteur';
import BilanInterEnt from '../../BilanInter/indexEntreprise';

function FormVoyageCollaborateur({ onSubmit }) {
  const navigate = useNavigate();
  const [valeurInput, setValeurInput] = useState('7');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [consommationConnue, setConsommationConnue] = useState("");
  const [typeCarburant, setTypeCarburant] = useState('');
  const [typeKilo, setTypeKilo] = useState('oui');
  const [consommation, setConsommation] = useState('');
  const [unit, setUnit] = useState('');
  const [kilometrageConnu, setKilometrageConnu] = useState(false);
  const [voyages, setVoyages] = useState([
  {
    typeVoyage: '',
    prenom: '',
    nom: '',
    ajout: { valeur: '30', unite: 'an' },
    ajoutPerso: '',
  },
  ]);
  const [categories, setCategories] = useState([
  {
    titre: 'Vols court-courriers (< 1000 km)',
    compteur: { titre: 'Distance totale parcourue :', unite: 'km', valeur: '0' }
  },
  {
    titre: 'Vols moyen-courriers (entre 1000 et 3500 km)',
    compteur: { titre: 'Distance totale parcourue :', unite: 'km', valeur: '0' }
  },
  {
    titre: 'Vols long-courriers (> 3500 km)',
    compteur: { titre: 'Distance totale parcourue :', unite: 'km', valeur: '0' }
  }
  ]);

  const handleDeleteVoyage = (index) => {
  setVoyages((prevVoyages) => {
    const updatedVoyages = [...prevVoyages];
    updatedVoyages.splice(index, 1);
    return updatedVoyages;
  });
  };

  const handleButtonClick = (event) => {
  event.preventDefault();
  navigate('/Entreprise/Fret'); // Ajout de la navigation après la soumission du formulaire
  };

  const addVoyage = useCallback(() => {
  setVoyages((prevVoyages) => [
    ...prevVoyages,
    {
    typeVoyage: '',
    prenom: '',
    nom: '',
    ajout: { valeur: '10000', unite: 'an' },
    ajoutPerso: '',
    },
  ]);
  }, []);

  const handleSubmit = (event) => {
  event.preventDefault();
  setFormSubmitted(true);
  console.log(formSubmitted);
  };

  const handleConsommationConnueChange = (value) => {
  setConsommationConnue(value);
  };

  const handleChangeVoyage = useCallback((index, field, value) => {
  setVoyages((prevVoyages) => {
    const updatedVoyages = [...prevVoyages];
    const voyage = updatedVoyages[index];
    if (voyage) {
    voyage[field] = value;
    }
    return updatedVoyages;
  });
  }, []);

  const handleChangeAjout = useCallback((index, values) => {
  setVoyages((prevVoyages) => {
    const updatedVoyages = [...prevVoyages];
    const voyage = updatedVoyages[index];
    if (voyage) {
    voyage.ajout = values;
    }
    return updatedVoyages;
  });
  }, []);

  const handleChangeAjoutPerso = useCallback((index, values) => {
  setVoyages((prevVoyages) => {
    const updatedVoyages = [...prevVoyages];
    const voyage = updatedVoyages[index];
    if (voyage) {
    voyage.ajoutPerso = values;
    }
    return updatedVoyages;
  });
  }, []);

  return (
  <form onSubmit={handleSubmit}>
    <div className="row col-12">
    <CheckboxChoixUnique
      titre="Connaissez-vous le nombre de kilomètres totaux parcourus par l’ensemble des salariés ?"
      rep1="Oui"
      rep2="Non"
      onChange={(value) => setConsommationConnue(value)}
    />
    </div>
  
    {consommationConnue === "" ? (
    ""
    ) : consommationConnue ? (
    <div>
      {categories.map((category, index) => (
      <div key={index} className="row col-12">
        <div className="h61 vert" style={{ color: "#024C4A" }}>
          {category.titre}
        </div>
        <div className="compteur col-12">
        <Compteur
          titre={category.compteur.titre}
          unit={category.compteur.unite}
          valeur={category.compteur.valeur}
          onValeurChange={(value) => {
          const updatedCategories = [...categories];
          updatedCategories[index].compteur.valeur = value;
          setCategories(updatedCategories);
          }}
        />
        </div>
      </div>
      ))}
    </div>
    ) : (
    <div className="row">
      <div className="col-12">
      {voyages.map((voyage, index) => (
        <div className="duplique" key={index}>
        <div className="row col-12">
          <div className="SousTitre">
          <DeleteOutlinedIcon
            sx={{ color: "#024C4A" }}
            className="Icon"
            onClick={() => handleDeleteVoyage(index)}
          />
          <div className="h61">Vol {index + 1}</div>
          </div>
        </div>
  
        <div className="row">
          <div className="col-md-6 col-12 col-lg-4">
          <ListDeroule
            titre="Type de voyage"
            text="Aller - retour"
            storageKey={`uniqueKey-${index}`}
            value={voyage.typeVoyage}
            onChange={(value) =>
            handleChangeVoyage(index, "typeVoyage", value)
            }
            mots={["Retour"]}
          />
          </div>
          <div className="col-md-6 col-12 col-lg-4">
          <InputField
            titre="Départ"
            value={voyage.prenom}
            onChange={(value) =>
            handleChangeVoyage(index, "prenom", value)
            }
          />
          </div>
          <div className="col-md-6 col-12 col-lg-4">
          <InputField
            titre="Arrivée"
            value={voyage.nom}
            onChange={(value) =>
            handleChangeVoyage(index, "nom", value)
            }
          />
          </div>
        </div>
  
        <div className="row">
          <div className="col-md-6 col-12">
          <Ajout
            values={voyage.ajout}
            setValues={(values) => handleChangeAjout(index, values)}
          />
          </div>
  
          <div className="col-md-6 col-12">
          <AjoutPerso
            values={voyage.ajoutPerso}
            setValues={(values) =>
            handleChangeAjoutPerso(index, values)
            }
          />
          </div>
        </div>
        </div>
      ))}
      </div>
  
      <div className="row">
      <div className="col-12">
        <button
        type="button"
        className="custom-button"
        onClick={addVoyage}
        >
        Ajouter un vol
        </button>
      </div>
      </div>
    </div>
    )}
  
    <div className="row">
    <div className="col-12">
      <Bouton titre="Soumettre" />
    </div>
    </div>
  
    {formSubmitted && (
    <div>
      <div className="row">
      <div className="col-12">
        <BilanInterEnt
        voyages={typeKilo === "oui" ? [] : voyages}
        numeroEtape={3}
        handleDeleteVoyage={handleDeleteVoyage}
        />
      </div>
      </div>
  
      <div className="row">
      <div className="col-12">
        <Bouton
        titre="Catégorie suivante"
        onClick={handleButtonClick}
        />
      </div>
      </div>
    </div>
    )}
  </form>
  );
  
}

export default FormVoyageCollaborateur;
