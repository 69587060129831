import React, { useState, useEffect } from 'react';
import ListDeroule from '../ListDeroule';
import InputField from '../Input';
import Bouton from '../Bouton';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import '../Form Particulier/form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Titre from '../../Titre';
import "./Connection.css"
import InputMotPasse from '../Input/indexMP';
import CheckboxLabelsWithLinks from '../CheckBoxMulti/CheckboxLabelsWithLinks';
import ApiCreeCompte from '../../Api/ApiCreeCompte';
import AppelAPILinkContactBilanCarbone from '../../Api/AppelAPILinkContactBilanCarbone';
import { useNavigate } from 'react-router-dom';

function FormCreeCompte3() {
  const [email, setEmail] = useState('');
  const [motDePasse, setMotDePasse] = useState('');
  const [confirmerMotDePasse, setConfirmerMotDePasse] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [Alertes, setAlertes] = useState({
    champsManquants: true,
    errorChecked: true,
    compteExistant: false,
    mauvaisChamps: false,
    errorServer: false,
  });
  const [lancer, setLancer] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [checkedStates, setCheckedStates] = useState({
    cgu: false,
    rgpd: false,
    newsletter: false
  });
  const [errorCode, setErrorCode] = useState('');
  const [elementsToRender, setElementsToRender] = useState([]);
  const [lancerLink, setLancerLink] = useState(false);
  const [hasLinked, setHasLinked] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    const isFormValid = !Alertes.champsManquants && !Alertes.errorChecked && !Alertes.compteExistant && !Alertes.mauvaisChamps;


    // Vérification des champs remplis
    if (isFormValid) {
      setLancer(true);
    } else {
      console.log(2)
      setErrorCode('INVALID_FORM');
    }
  };


  const handleCompteCree = () => {
    if (apiResponse) {
      if (apiResponse.status === 201) {
        localStorage.setItem('wpusername', apiResponse.data.name);
        navigate('/bilan');
        setFormSubmitted(false);
      }
    }
  };

  const handleLink = () => {
    if (apiResponse && !hasLinked) { 
      if (apiResponse.status === 201) {
        setLancerLink(true)
        setHasLinked(true) 
      }
    }
  }

  const handleCheckboxChange = (key) => {
    setCheckedStates(prevStates => ({
      ...prevStates,
      [key]: !prevStates[key]
    }));
  };

  useEffect(() => {
    if (formSubmitted && apiResponse) {
      handleCompteCree();
    }
  }, [formSubmitted, apiResponse]);

  useEffect(() => {
    if (email && motDePasse && confirmerMotDePasse) {
      setAlertes(prevAlertes => ({
        ...prevAlertes,
        champsManquants: false
      }));
    } else {
      setAlertes(prevAlertes => ({
        ...prevAlertes,
        champsManquants: true
      }));
    }
  }, [email, motDePasse, confirmerMotDePasse]);

  useEffect(() => {
    if (checkedStates.cgu && checkedStates.rgpd) {
      setAlertes(prevAlertes => ({
        ...prevAlertes,
        errorChecked: false
      }));
    } else {
      setAlertes(prevAlertes => ({
        ...prevAlertes,
        errorChecked: true
      }));
    }
  }, [checkedStates.cgu, checkedStates.rgpd]);

  useEffect(() => {
    if (apiResponse && apiError) {
      if (apiResponse.response.status === 500) {
        setAlertes(prevAlertes => ({
          ...prevAlertes,
          compteExistant: true
        }));
        // setApiResponse(null);
      } else if (apiResponse.response.status === 400) {
        setAlertes(prevAlertes => ({
          ...prevAlertes,
          mauvaisChamps: true
        }));
        // setApiResponse(null);
      }
    }
  }, [apiResponse]);

  useEffect(() => {
    setAlertes(prevAlertes => ({
      ...prevAlertes,
      compteExistant: false,
      mauvaisChamps: false
    }));
    setFormSubmitted(false);
    setApiError(false);
  }, [email, motDePasse, confirmerMotDePasse]);

  return (
    <form className='Connection'>
      <div className="row col-12 justify-content-center">
        <div className="SousTitre">
          <Titre
            Titre="Votre profil"
            place="center"
            color="#024C4A"
          />
        </div>
      </div>

      {/* <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputField
            titre="Username"
            value={username}
            onChange={setUsername}
          />
        </div>
      </div> */}

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 co-md-6 col-12">
          <InputField
            titre="Email"
            value={email}
            onChange={setEmail}
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputMotPasse
            titre="Mot de passe"
            value={motDePasse}
            onChange={setMotDePasse}
            text="Mot de passe"
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputMotPasse
            titre="Confirmer le mot de passe"
            value={confirmerMotDePasse}
            onChange={setConfirmerMotDePasse}
            text="Confirmer le mot de passe"
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-8 col-md-6 col-12">
          <CheckboxLabelsWithLinks
            title="Gestion des données"
            labels={[
              {key: 'cgu', label: "J'accepte la politique de gestion des données de GoodPlanet et les CGU"},
              {key: 'rgpd', label: "Je comprends que mes données anonymement seront utilisées à des fins statistiques"},
              {key: 'newsletter', label: "Je souhaite m'inscire à la newsletter GoodPlanet"}
            ]}
            links={['/cgu.pdf', '/rgpd.pdf', null]}
            checked={checkedStates}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>

      {formSubmitted && Alertes.champsManquants && (
        <div className="row justify-content-center">
          <div className="col-8">
            <div
              className="alert alert-danger"
              role="alert"
            >
              Veuillez remplir tous les champs !
            </div>
          </div>
        </div>
      )}

      {formSubmitted && Alertes.errorChecked && (
        <div className="row justify-content-center">
          <div className="col-8">
            <div
              className="alert alert-danger"
              role="alert"
            >
              Veuillez accepter les conditions !
            </div>
          </div>
        </div>
      )}

      {formSubmitted && Alertes.compteExistant && (
        <div className="row justify-content-center">
          <div className="col-8">
            <div
              className="alert alert-danger"
              role="alert"
            >
              Un compte existe déjà avec cette adresse email !
            </div>
          </div>
        </div>
      )}

      {formSubmitted && Alertes.mauvaisChamps && (
        <div className="row justify-content-center">
          <div className="col-8">
            <div
              className="alert alert-danger"
              role="alert"
            >
              Les champs ne sont pas valides !
            </div>
          </div>
        </div>
      )}


      <div className="row">
        <div className="col-12">
          <Bouton
            type="submit"
            titre="Suivant"
            onClick={handleSubmit}
          />
        </div>
      </div>

      {formSubmitted && lancer && (
        <div>
          <ApiCreeCompte username={email} mail={email} pwd={motDePasse} Lancer={lancer} setLancer={setLancer} setApiResponse={setApiResponse} setApiError={setApiError}/>
        </div>
      )}
      {lancerLink && (
          <AppelAPILinkContactBilanCarbone mail={email} lancerLink={lancerLink} setLancerLink={setLancerLink} />
      )}

      {formSubmitted && apiResponse && handleCompteCree()}
      {formSubmitted && apiResponse && handleLink()}

    </form>
  );
}

export default FormCreeCompte3;
