import React from 'react';
import SuspensionPoints from "../img/suspension-points.png";
import './Graphique.css';

const Ligne1 = ({logo, titre, titregras, floatNumber}) => {
  // Données pour les éléments du graphique
  let fullNumber = 0;
  let fraction = 0;
  let tooMuch = false;

  if (floatNumber <= 3) {
  fullNumber = Math.floor(floatNumber);
  fraction = (floatNumber - fullNumber);
  } else {
  fullNumber = 3;
  tooMuch = true;
  }
  
  return (
  <div className='Ligne1'>
    <div className='images'>
    {[...Array(fullNumber)].map((_, index) => (
      <div className='LogoLigne1' key={index}>
      <img src={logo} alt="Image"/>
      </div>
    ))}
    {fraction > 0 && (
      <div className='LogoLigne1 fraction-container'>
      <img 
        src={logo} 
        alt="Fraction of image" 
        className='fraction-image'
        style={{
        clipPath: `inset(0 ${100 - (fraction * 100)}% 0 0)`,
        width: '70px',
        height: '70px'
        }}
      />
      </div>
    )}
    {tooMuch && (
      <div className='LogoLigne1' >
      <img src={SuspensionPoints} alt="suspension" style={{width: '50px', height: 'auto'}}/>
      </div>
    )}
    </div>
    <div className='titre'>{titre}</div>
    <div className='Gras'>{floatNumber + ' ' + titregras}</div>
  </div>
  );
};

export default Ligne1;
