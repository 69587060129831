import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import Compteur from '../../../components/Compteur/Compteur';
import CheckboxChoixUnique from '../../../components/Form/checkBox/checkbox';
import CheckboxChoixUniqueAdapt from '../../../components/Form/checkBox/checkbox-adapt';
import imageEnergie from "../../../components/img/Energie.png"
import { Padding } from '@mui/icons-material';
import Bouton from '../../../components/Form/Bouton';
import FormDomicile from '../../../components/Form/Form Particulier/FormDomicile';
import FormDomicileMaison from '../../../components/Form/Form Particulier/FormDomicileMaison';

const Maison = () => {
    const navigate = useNavigate();
    const [consommationConnue, setConsommationConnue] = useState(false);
    const reponses = ['≤5    A', '6 à 10    B', '11 à 20 C', '21 à 35 D', '36 à 55 E', '56 à 80 F', '> 80 G'];



    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <Stepperline/>

        <div className="container">
            <div className="row">
            <div className="col-12">
                <Titre Titre="Information sur le logement"    place="start"/>
            </div>
            </div>

            <FormDomicileMaison/>

                
            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>L’habitat et les émissions de gaz à effet de serre</div>
        <p>L’habitat a évolué en fonction des nouvelles normes en vigueur (isolation, mode de chauffage …) dans le but d’être moins énergivore. C’est pourquoi, une distinction a été faite dans la base carbone de l’ADEME entre les logements construits avant ou après 1975. Depuis, la réglementation RT 2012 impose aux constructions des règles permettant de réduire fortement la consommation énergétique du logement. Cependant, la rénovation énergétique d’un logement construit avant 2012 permet de réduire de 17% ses émissions de gaz à effet de serre. <a href="https://www.oid.com/fr/etude-poids-carbone-logements" target="_blank" rel="noopener noreferrer">Source - Observatoire de l’immobilier Durable – Etude sur le poids carbone des logements - mai 2021</a></p>
        
        <p>L’étiquette climat permet d’évaluer les émissions de Gaz à effet de serre générées par votre logement. Pour chaque catégorie, nous avons pris la fourchette haute. Si vous n’avez pas accès à votre DPE (Diagnostic de Performance Energétique), vous devez entrer des informations spécifiques à votre logement. Les émissions de gaz à effet de serre de votre logement dépendent des sources d’énergie utilisées.</p>

        <p>Les données à renseigner sont présentes sur vos factures. Nous vous conseillons d’indiquer en priorité les kWh consommés. En effet, pour réaliser une estimation des émissions de gaz à effet de serre en fonction du prix, une moyenne annuelle du prix de l’énergie par unité a été réalisée selon les données de 2021 (prix moyen). La méthode est donc moins précise qu’une indication de la consommation directe puisqu’elle dépend de la fluctuation du prix de l’énergie, et du prix total payé au fournisseur, sans distinction entre l’abonnement et la consommation réelle.</p>

        <p>Le calcul de l’empreinte carbone étant une démarche personnelle, les émissions liées aux consommations d’énergie dans le logement seront divisées par le nombre d’occupants du logement.</p>
    </div>
} />
            </div>
            </div>

            {/* <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div> */}

        </div>
        </div>
    </div>
    </>
    );
};

export default Maison;

