import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import FormTrain from '../../../components/Form/Form Particulier/FormTrain';
import MonFormulaireB from '../../../components/Form/Form Particulier/BFormTest';

const PageTrain = () => {
    const navigate = useNavigate();


    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <Stepperline/>

        <div className="container">
            <div className="row">
            <div className="row col-10 offset-1">
                <Titre Titre="Nombre de km parcouru en train"    place="start"/>
            </div>
            </div>
            <div className="row col-10 offset-1">
            <FormTrain/>
            </div>

                
            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>Émissions liées aux trajets en train</div>
        <p>Nous distinguons les trajets en France, en France et en Europe, et en Europe. En effet, le train fonctionne à l’électricité et ses émissions de gaz à effet de serre dépendent directement du mix énergétique national. Notre calculateur n’intègre pas pour la France le cas particulier de la Corse (une seule ligne Bastia-Ajaccio alimentée au diesel).</p>
        
        <p>Pour les trajets :</p>
        <ul>
            <li>En France : c’est la moyenne des facteurs d’émissions TGV, Train grandes lignes et Train Express régional.</li>
            <li>En Europe : c’est la moyenne des valeurs Train de voyageurs pour l’Autriche, l’Allemagne, la Belgique, le Danemark, l’Espagne, la Finlande, la Grèce, l’Irlande, l’Italie, le Luxembourg, la Norvège, les Pays Bas, le Portugal, le Royaume-Uni, la Suède et la Suisse.</li>
            <li>En France/Europe : c’est la moyenne entre le facteur d’émission de la France (calculé selon la méthode ci-dessus) et la moyenne des facteurs d’émissions des pays européens accessibles depuis la France.</li>
        </ul>
    </div>
} />

            </div>
            </div>

            {/* <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div> */}

        </div>
        </div>
    </div>
    </>
    );
};

export default PageTrain;

