import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/index";
import Card from "../../../components/Card/index";
import Footer from '../../../components/Footer/Index';
import ContenueLogo from "../../../components/ContenuCard/ContenueLogo";
import ContenueBouton from "../../../components/ContenuCard/ContenueBouton";
import TitreH2 from '../../../components/TitreH2';
import TitreBG from '../../../components/TitreBG';
import ImageVoiture from "../../../components/img/Al.png";
import StepperLine from '../../../components/StepperLine/index-test'
import LogoNon from "../../../components/img/imageLogoNon.png";
import LogoTravail from "../../../components/img/travail.png";
import Logowkend from "../../../components/img/wkend.png";
import Logottjour from "../../../components/img/ttjour.png";
import Compteur from '../../../components/Compteur/Compteur';
import LogoImmo from "../../../components/img/Immo.png"
import ContenueLogoText from '../../../components/ContenuCard/ContenueLogoText';
import StepperLineEntreprise from '../../../components/StepperLine/index-entreprise';
import Logo from "../../../components/img/deplacement.png"
import StepperLineEvent from '../../../components/StepperLine/index-event';


const DeplacementEvent = () => {
    const navigate = useNavigate();
    const [cardContenu, setCardContenu] = useState(<ContenueLogoText Logo={Logo} Titre="Déplacements"/>);

    const handleCardClick = () => {
    navigate("/Evenement/Deplacement-Equipe")
    };


    return (
    <>     
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container'>
        <StepperLineEvent/>

        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>
        <div className="row">
            <div className="col-10 offset-1">
                <TitreH2 Titre="Calculez vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
            </div>
            <div className="row justify-content-center">
            <div className="col-10">
                <Card contenu={cardContenu} onClick={handleCardClick} color="#024C4A"/>
            </div>
            </div>
            <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div>
        </div>
        </div>
    </>
    );
};

export default DeplacementEvent;

