import React, { useRef } from 'react';
import './Band.css';
import Card from '../Card';
import ContenueCardBilan from './ContenuCardBilan';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import SousTitre from '../../components/SousTitre';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ShareLink from 'react-linkedin-share-link';
import { toPng } from 'html-to-image';
import ChartEntreprise from '.';

const Band1 = ({}) => {
  const imageURL = "https://www.referenseo.com/wp-content/uploads/2019/03/image-attractive.jpg";
  const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${imageURL}`;
  const whatsappShareURL = `https://api.whatsapp.com/send?text=${imageURL}`;
  const instagramURL = `https://www.instagram.com/fondationgoodplanet`;  // Remplacer [VotreNomUtilisateur] avec votre nom d'utilisateur Instagram
  const linkedinShareURL = `https://www.linkedin.com/shareArticle?mini=true&url=${imageURL}`;
  const ref = useRef();

  const openInNewTab = (url) => {
    toPng(ref.current)
    .then((dataUrl) => {
      // const link = document.createElement('a');
      // link.href = dataUrl;
      // imageURL = dataUrl;
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    })
    .catch((error) => {
      console.error('oops, something went wrong!', error);
    });
    
  };

   const downloadImage = () => {
    toPng(ref.current)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'my-image.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  };



  return (
    <div className='row col-12 Band1 justify-item-center'>
    <div className='bandG col-5 row justify-item-center'>
      <div className='col-3'>
        <InstagramIcon sx={{ fontSize: 40, color: 'black' }} onClick={() => openInNewTab(instagramURL)} />
      </div>
      <div className='col-3'>
        <FacebookIcon sx={{ fontSize: 40, color: 'black' }} onClick={() => openInNewTab(facebookShareURL)} />
      </div>
      <div className='col-3'>
        <WhatsAppIcon sx={{ fontSize: 40, color: 'black' }} onClick={() => openInNewTab(whatsappShareURL)} />
      </div>
      <div className='col-3'>
        <ShareLink link={linkedinShareURL}>
          {link => (
            <LinkedInIcon sx={{ fontSize: 40, color: 'black' }} onClick={() => openInNewTab(link)} />
          )}
        </ShareLink>      </div>
    </div>
    <div className='col-7'>
      <SousTitre SousTitre={"Partager votre bilan et vos objectifs"}/>
      <p className='p1'>Invitez vos proches à faire le leur</p>
    </div>
</div>
  );
};

export default Band1;

