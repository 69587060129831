import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../Particulier/Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import Compteur from '../../../components/Compteur/Compteur';
import CheckboxChoixUnique from '../../../components/Form/checkBox/checkbox';
import CheckboxChoixUniqueAdapt from '../../../components/Form/checkBox/checkbox-adapt';
import imageEnergie from "../../../components/img/Energie.png"
import { Padding } from '@mui/icons-material';
import Bouton from '../../../components/Form/Bouton';
import FormDomicile from '../../../components/Form/Form Particulier/FormDomicile';
import FormLieu from '../../../components/Form/Form Event/FormLocal';
import FormHebergement from '../../../components/Form/Form Event/FormHebergement';
import StepperLineEvent from '../../../components/StepperLine/index-event';

const Hebergement = () => {
    const navigate = useNavigate();
    const [consommationConnue, setConsommationConnue] = useState(false);
    const reponses = ['≤5    A', '6 à 10    B', '11 à 20 C', '21 à 35 D', '36 à 55 E', '56 à 80 F', '> 80 G'];



    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLineEvent/>

        <div className="container col-10">

        <div className="row col-10 offset-1">
            <FormHebergement/>
            </div>


                
            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>Hébergement</div>
        <p>Cette section couvre l'empreinte carbone associée à l'hébergement lors de votre événement. Nous prenons en compte les nuitées des participants et évaluons les émissions de gaz à effet de serre engendrées par ces séjours.</p>
        <p>Voici comment notre calculateur effectue cette évaluation :</p>
        <ul>
            <li>Collecte des données essentielles : Nous sollicitons des informations telles que le nombre de nuitées réservées et la catégorie d'hébergement choisie, que ce soit un hôtel, un camping ou autre. Si l'établissement d'hébergement est labellisé pour sa durabilité environnementale, cela est également pris en compte.</li>
            <li>Facteur d'émission adapté : Chaque catégorie d'hébergement a un facteur d'émission spécifique associé exprimé en kilogrammes d'équivalent CO2 par nuitée. Ce facteur est calculé en prenant en considération diverses variables telles que la consommation d'énergie, l'utilisation de ressources, et les pratiques durables de l'établissement.</li>
            <li>Calcul de l'empreinte carbone : Le calculateur multiplie le facteur d'émission par le nombre de nuitées réservées pour obtenir une estimation de l'empreinte carbone totale liée à l'hébergement de votre événement. Cette estimation vous permet de comprendre et de comparer l'impact environnemental de différentes options d'hébergement.</li>
        </ul>
        <p>En choisissant des hébergements labellisés et en encourageant des pratiques plus respectueuses de l'environnement, vous pouvez réduire l'empreinte carbone de l'hébergement associé à votre événement.</p>
    </div>
} />

            </div>
            </div>

            <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div>

        </div>
        </div>
    </div>
    </>
    );
};

export default Hebergement ;

