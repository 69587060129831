import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../Particulier/Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import CarreLogoCompteur from '../../../components/CarreLogo/CarreLogoCompteur';
import LogoCereale from "../../../components/img/cereale.png";
import LogoCont from "../../../components/img/continental.png";
import LogoSale from "../../../components/img/sale.png";
import LogoVege from "../../../components/img/vege.png";
import LogoVegetalien from "../../../components/img/vegetalien.png";
import LogoPoisson from "../../../components/img/poisson.png";
import LogoViande from "../../../components/img/viande.png";
import LogoVolail from "../../../components/img/volaille.png";
import LogoThe from "../../../components/img/the.png";
import LogoCafe from "../../../components/img/cafe.png";
import LogoBiere from "../../../components/img/biere.png";
import LogoJus from "../../../components/img/jus.png";
import LogoSodas from "../../../components/img/soda.png";


import SousTitre from '../../../components/SousTitre';
import TitreH2 from '../../../components/TitreH2';
import Compteur from '../../../components/Compteur/Compteur';
import FormDeplacement from '../../../components/Form/Form Entreprise/Deplacement';
import StepperLineEntreprise from '../../../components/StepperLine/index-entreprise';

const Deplacement1 = () => {
    const navigate = useNavigate();


    return (
    <>
     <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLineEntreprise/>

        <div className="container">
            <div className="row">
            <div className="row col-10 offset-1">

                <Titre Titre="Déplacement domicile - travail des collaborateurs"    place="start"/>
            </div>
            </div>
            <div className="row col-10 offset-1">
         <FormDeplacement/>
         </div>

                
            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>Émissions liées aux déplacements</div>
        <p>Les facteurs d’émission de la voiture prennent en compte le carburant (phase amont et combustion) ainsi que la fabrication pour l’ensemble du véhicule. Les émissions dépendent directement du type d’énergie utilisée pour faire avancer le véhicule. De plus, une voiture qui circule consomme autant de carburant et émet autant de CO₂e qu’elle transporte à son bord 1 ou 5 personnes. Nous considérons que les trajets domicile-travail restent des courts trajets et nous prenons en compte par défaut le taux de remplissage moyen pour les courts trajets de 1,4 passagers défini par l’ADEME.</p>

        <p>Les émissions prises en compte pour les transports en commun routiers (bus) prennent en compte le carburant (amont et combustion) ainsi que la fabrication du véhicule.</p>
        <p>Pour les bus urbains et interurbains, on distingue trois classes d’agglomérations :</p>
        <ul>
            <li>Classe 1 : agglomérations de plus de 250 000 habitants</li>
            <li>Classe 2 : agglomérations de 150 000 à 250 000 habitants</li>
            <li>Classe 3 : agglomérations de moins de 150 000 habitants</li>
        </ul>

        <p>Pour les modes ferrés (Métro, RER, Tram), ce sont les émissions liées au mode d'alimentation énergétique du réseau électrique (les émissions amont), ainsi que la fabrication qui sont prises en compte. La quasi-totalité des métros circulent dans les grandes agglomérations : c’est le facteur d’émission correspondant aux agglomérations de classe 1 qui a été choisi. Quant au tram, il y a presque autant de réseaux de tramway dans les agglomérations de classe 1 que dans les agglomérations de classe 2. C’est pourquoi, nous avons choisi de prendre une moyenne des facteurs d’émissions de ces deux types d’agglomérations. Le RER et Transiliens ne circulent qu’en Ile-de-France et disposent de leur propre facteur d’émission. Pour le train, le facteur d’émission choisi est une moyenne entre celui du TGV, du TER et des Trains grandes lignes.</p>

        <p>Pour évaluer la consommation d’énergie liée aux déplacements domicile-travail, vous pouvez réaliser une enquête sur les habitudes auprès de tous vos collaborateurs ou bien d’un échantillon représentatif que vous extrapolerez à l’ensemble des salariés. Vous devez indiquer le kilométrage total à l’ensemble des salariés par an pour chaque type de véhicule et de motorisation.</p>
        <p>Par exemple :</p>
        <ul>
            <li>Le salarié A fait 5 km aller-retour par jour en voiture essence</li>
            <li>La salarié B fait 10 kilomètres aller-retour par jour en voiture essence</li>
            <li>Le salarié C fait 60 km en train aller-retour par jour</li>
        </ul>
        <p>Kilométrage essence : (5+10) x 2 x nombre de jours travaillés</p>
        <p>Kilométrage train : 60 x 2 x nombre de jours travaillés</p>
    </div>
} />

            </div>
            </div>

            <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div>

        </div>
        </div>
    </div>
    </>
    );
};

export default Deplacement1;

