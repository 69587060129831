import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import CheckboxChoixUnique from '../checkBox/checkbox';
import InputFieldNb from '../InputNombre/index';
import CheckboxAjout from '../checkBoxAjout/CheckboxAjout';
import AjoutPerso from '../Ajout/index-personne';
import { Button } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CheckboxChoixUnique3 from '../checkBox/checkbox-3';
import InputFieldNbUnique from '../InputNombre/indexUnit';
import AppelAPIPlacement from '../../Api/AppelAPIPlacement';
import { useFormProgress } from '../../FormProgressContext';

function FormPlacement({ onSubmit }) {
  const navigate = useNavigate();
  const [valeurInput, setValeurInput] = useState('7');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [displayBilan, setDisplayBilan] = useState(false);
  const [consommationConnue, setConsommationConnue] = useState(false);
  const { formStatus, setFormStatus } = useFormProgress();
  const [placements, setPlacements] = useState([
    {
      banque: '',
      montant: '',
    },
  ]);

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate("/bilan"); // Ajout de la navigation après la soumission du formulaire
  };

  const [lancer, setLancer] = useState(false);
  const [apiResponse, setApiResponse] = useState(null); // État pour stocker la réponse de l'API
  
  const updateApiResponse = (response) => {
    setApiResponse(response);
  };


  const handleButtonClickAPI = () => {
    setLancer(true);
    setDisplayBilan(true);
    setFormSubmitted(false);


  };

  const handleDeletePlacement = (index) => {
    setPlacements((prevPlacements) => {
      const updatedPlacements = [...prevPlacements];
      updatedPlacements.splice(index, 1);
      return updatedPlacements;
    });
  };
  

  const addPlacement = useCallback(() => {
    setPlacements((prevPlacements) => [
      ...prevPlacements,
      {
        banque: '',
        montant: '',
      },
    ]);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    setDisplayBilan(true);
    setFormStatus({
      ...formStatus,
      placement: 'fini',
    });
  }

  useEffect(() => {
    setFormStatus({
        ...formStatus,
        placement: 'en cours',
    });
   }, []);


 useEffect(() => {
   if (displayBilan) {
     setFormStatus({
       ...formStatus,
       placement: 'fini',
     });
   } else if (formSubmitted) {
     setFormStatus({
       ...formStatus,
       placement: 'en cours',
     });
   }
 }, [formSubmitted, displayBilan]);

  const handleConsommationConnueChange = (value) => {
    setConsommationConnue(value);
  };

  const handleChangePlacement = useCallback((index, field, value) => {
    setPlacements((prevPlacements) => {
      const updatedPlacements = [...prevPlacements];
      const placement = updatedPlacements[index];
      if (placement) {
        placement[field] = value;
      }
      return updatedPlacements;
    });
  }, []);
  

  const handleChangeAjout = useCallback((index, values) => {
    setPlacements((prevPlacements) => {
      const updatedPlacements = [...prevPlacements];
      const placement = updatedPlacements[index];
      if (placement) {
        placement.ajout = values;
      }
      return updatedPlacements;
    });
  }, []);

  const handleChangeAjoutPerso = useCallback((index, values) => {
    setPlacements((prevPlacements) => {
      const updatedPlacements = [...prevPlacements];
      const placement = updatedPlacements[index];
      if (placement) {
        placement.ajoutPerso = values;
      }
      return updatedPlacements;
    });
  }, []);
  

  return (
    <form onSubmit={handleSubmit}>

      {/* <div className="row">
        <div className="col-12">
          <p>Votre épargne est utilisée par votre banque pour des prêts à des industries ou des projets par le biais de portefeuilles ou de fonds d’investissement. Ceux-ci peuvent impacter l’environnement lorsqu’il s’agit de projets polluant en France comme à l’étranger. La transparence sur l’impact climatique de ces prêts n’est pas totale mais est en progression. Une première estimation moyenne peut être faite en fonction de la banque à laquelle vous confiez votre épargne. 
           <br/><br/>Estimez ici l’empreinte carbone de votre épargne bancaire (source de données OXFAM).
          </p>
        </div>
      </div> */}



        <div>
        <div className="row">
          <div className="col-12">
            {placements.map((placement, index) => (
              <div className="duplique" key={index}>
                <div className="row col-12">
                  <div className="SousTitre">
                  <DeleteOutlinedIcon sx={{ color : "#024C4A"}} className="Icon" onClick={() => handleDeletePlacement(index)} />
                    <div className="h61">Placement {index + 1}</div>
                    </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <ListDeroule
                      titre="Banque"
                      text="Banque Populaire - Caisse d'Epargne"
                      storageKey={`uniqueKey-${index}`}
                      value={placement.banque}
                      onChange={(value) => {
                        let convertedValue;
                        switch (value) {
                          case "Banque Populaire - Caisse d'Epargne":
                            convertedValue = 279640000;
                            break;
                          case "La Banque Postale":
                            convertedValue = 279640001;
                            break;
                          case "Société Générale":
                            convertedValue = 279640002;
                            break;
                          case "Crédit Agricole - LCL":
                            convertedValue = 279640003;
                            break;
                          case "Crédit Mutuel - CIC":
                            convertedValue = 279640004;
                            break;
                          case "Autres banques":
                            convertedValue = 279640005;
                            break;
                          default:
                            convertedValue = 279640000; 
                          }
                        handleChangePlacement(index, 'banque', convertedValue);}}
          
                      mots = {[ "La Banque Postale", "Société Générale", "Crédit Agricole - LCL", "Crédit Mutuel - CIC", "Autres banques"]}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputFieldNbUnique
                      titre="Montant"
                      value={placement.montant}
                      onChange={(value) =>
                        handleChangePlacement(index, 'montant', value)
                      }
                      unit = "€"
                    />
                  </div>

                </div>
                
              </div>
            ))}
          </div>
        </div>
     

      <div className="row">
        <div className="col-12">
          <button
            type="button"
            className="custom-button"
            onClick={addPlacement}
          >
            Ajouter un Placement
          </button>
        </div>
      </div>
      </div>

                

      <div className="row">
        <div className="col-12">
          
          <Bouton titre="CALCULER" onClick={handleButtonClickAPI}/>
          
        </div>
      </div>
      

      {displayBilan && (
        <div>
          <div className="row">
            <div className="col-12">
            {placements.map((placement, index) => (
              <div className="duplique" key={index}>
              <AppelAPIPlacement placement={placement} setLancer= {setLancer} Lancer={lancer} save={false} setApiResponse={updateApiResponse}/>
              
              
              {/* Affichage des valeurs spécifiques pour ce voyage */}
                  {/* <div className="row">
                    <div className="col-12">
                      <div className='h61'>Valeurs spécifiques pour Placement {index + 1} :</div>
                      <div>IdPage : Pplacement</div>
                      <div>Banque: {placement.banque}</div>
                      <div>Montant : {placement.montant}</div>
                    
                    </div>
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        
          <div className="row">
          <div className="col-12">
            <BilanInter voyage="non" numeroEtape={8} responseAPIPlacement={apiResponse} /> 
           </div>
        </div>





          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
            </div>
          </div>
        </div>
      )}




      


    </form>
  );
}

export default FormPlacement;
