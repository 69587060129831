import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import "./form.css"
import LogoMobilier from '../../img/mobilier.png';
import LogoRevêtementSol from '../../img/revetementsol.png';
import LogoDécoration from '../../img/déco.png';
import LogoTechnique from '../../img/technique.png';
import LogoEnergie from '../../img/MachineEnergie.png';

import LogoPetitesFournitures from '../../img/PetiteFourniture.png';
import LogoBureautique from '../../img/Bureautique.png';
import LogoTextile from '../../img/Textile.png';

import LogoPlastique from '../../img/PlastiqueTotem.png';
import LogoPapier from '../../img/papier.png';
import LogoCarton from '../../img/carton.png';
import LogoGoodiesCadeau from '../../img/goodies.png';

import LogoBois from '../../img/bois.png';
import LogoVerre from '../../img/verreMetal.png';


import LogoArtCulture from '../../img/ArtCulture.png';
import LogoSportLoisir from '../../img/Sport.png';
import LogoAdministrationSécurité from '../../img/Administration.png';
import LogoAssuranceBanqueConseil from '../../img/Assurance.png';
import LogoTransportStockage from '../../img/transportAuxilliaire.png';
import LogoInstallationRéparation from '../../img/Instalation.png';
import LogoTélécomServicesDivers from '../../img/telecom.png';
import Titre from '../../Titre';
import InputFieldNbUnique from '../InputNombre/indexUnit';
import Compteur from '../../Compteur/Compteur';
import BilanInter from '../../BilanInter';
import Bouton from '../Bouton';


function FormLogistique({ lien }) {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false); // nouvel état pour suivre la soumission du formulaire
  const [Valorise, setValorise] = useState()
  const [compteurValues, setCompteurValues] = useState({
    mobilier: '',
    revêtementSol: '',
    décoration: '',
    technique: '',
    energie: '',

    petitesFournitures: '',
    bureautique: '',
    textile: '',
    
    plastique: '',
    papier: '',
    carton: '',
    goodiesCadeau: '',
    
    artCulture: '',
    sportLoisir: '',
    administrationSécurité: '',
    assuranceBanqueConseil: '',
    transportStockage: '',
    installationRéparation: '',
    télécomServicesDivers: '',

    plastiqueDechet: '',
    papierDechet: '',
    Bois: '',
    Verre: '',
  });

  const handleValeurChange = (nom, valeur) => {
    setCompteurValues({ ...compteurValues, [nom]: valeur });
  };

  const [transportValues, setTransportValues] = useState({
    fretRoutier: 0,
    fretAerienSup3000: 0,
    fretAerienInf3000: 0,
    fretFerroviaireFrance: 0,
    fretFerroviaireHorsFrance: 0,
    fretMaritimeConteneurs: 0,
    fretMaritimeVraquiers: 0,
    fretFluvialPousseurs: 0,
    fretFluvialAutomoteurs: 0,
  });

  const handleValeurChangeTransport = (nom, valeur) => {
    setTransportValues({ ...transportValues, [nom]: valeur });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true); // indique que le formulaire a été soumis
    console.log(formSubmitted);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate("/Voiture-11");
  };


  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div className='h61'>Matériaux nécessaires</div>

        <div className="row">
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoMobilier}
              titre="Location ou achat de meuble et autres biens"
              titregras={'Mobilier'}
              onValeurChange={(valeur) =>
                handleValeurChange('mobilier', valeur)
              }
              unit="€"
              nombre={1}   />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoRevêtementSol}
              titre="Revêtement de sols"
              titregras={'Revêtement de sols'}
              onValeurChange={(valeur) =>
                handleValeurChange('revêtementSol', valeur)
              }
              unit="€"
              nombre={1}        />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoDécoration}
              titre="Budget alloué à la décoration hors mobilier et sol"
              titregras={'Décoration'}
              onValeurChange={(valeur) =>
                handleValeurChange('décoration', valeur)
              }
              unit="€"
              nombre={1}     />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoTechnique}
              titre="Machine et équipements techniques "
              titregras={'Technique'}
              onValeurChange={(valeur) =>
                handleValeurChange('technique', valeur)
              }
              unit="€"
              nombre={1}    />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoEnergie}
              titre="Groupe électrogène (poids) "
              titregras={'Energie'}
              onValeurChange={(valeur) =>
                handleValeurChange('energie', valeur)
              }
              unit="€"
              nombre={1}  />
          </div>
        </div>


      <div className="row">
        <div className="col-lg col-md-6 col-12">
          <CarreLogoInput2
            logo={LogoPetitesFournitures}
            titre="Petites fournitures"
            titregras={'Petites fournitures'}
            onValeurChange={(valeur) =>
              handleValeurChange('petitesFournitures', valeur)
            }
            unit="€"
            nombre={1}
          />
        </div>
        <div className="col-lg col-md-6 col-12">
          <CarreLogoInput2
            logo={LogoBureautique}
            titre="Consommables bureautiques "
            titregras={'Bureautique'}
            onValeurChange={(valeur) =>
              handleValeurChange('bureautique', valeur)
            }
            unit="€"
            nombre={1}    />
        </div>
        <div className="col-lg col-md-6 col-12">
          <CarreLogoInput2
            logo={LogoTextile}
            titre="Textiles et habillement "
            titregras={'Textile'}
            onValeurChange={(valeur) =>
              handleValeurChange('textile', valeur)
            }
            unit="€"
            nombre={1}/>
        </div>
        <div className='col'></div>
        <div className='col'></div>
        </div>

        <Titre Titre="Support de communication" />

        <div className="row">
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoPlastique}
              titre="Totem, présentoire..."
              titregras={'Plastique'}
              onValeurChange={(valeur) =>
                handleValeurChange('plastique', valeur)
              }
              unit="kg"
              nombre={1}    />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoPapier}
              titre="Support papier type flyer, "
              titregras={'Papier'}
              onValeurChange={(valeur) =>
                handleValeurChange('papier', valeur)
              }
              unit="kg"
              nombre={1} />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoCarton}
              titre="Support en  carton"
              titregras={'Carton'}
              onValeurChange={(valeur) =>
                handleValeurChange('carton', valeur)
              }
              unit="kg"
              nombre={1} />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoGoodiesCadeau}
              titre="Goodies & Cadeau"
              titregras={'Goodies & Cadeau'}
              onValeurChange={(valeur) =>
                handleValeurChange('goodiesCadeau', valeur)
              }
              unit="€"
              nombre={1}        />
          </div>
          <div className='col'></div>
        </div>
        </div>

      <div>
        <Titre Titre="Services" />

        <div className="row">
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoArtCulture}
              titre="Activités créatives, artistiques, culturelles et de spectacle ; Bibliothèques musées"
              titregras={'Art & Culture'}
              onValeurChange={(valeur) =>
                handleValeurChange('artCulture', valeur)
              }
              unit="€"
              nombre={1}     />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoSportLoisir}
              titre="Organisation de jeux de hasard et d'argent ; Activités sportives, récréatives et de loisirs"
              titregras={'Sport & Loisir'}
              onValeurChange={(valeur) =>
                handleValeurChange('sportLoisir', valeur)
              }
              unit="€"
              nombre={1}      />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoAdministrationSécurité}
              titre="Administration publique et défense, sécurité sociale obligatoire"
              titregras={'Administration & Sécurité'}
              onValeurChange={(valeur) =>
                handleValeurChange('administrationSécurité', valeur)
              }
              unit="€"
              nombre={1}
            />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoAssuranceBanqueConseil}
              titre="Assurance, services bancaires, conseil et honoraires "
              titregras={'Assurance, Banque & Conseil'}
              onValeurChange={(valeur) =>
                handleValeurChange('assuranceBanqueConseil', valeur)
              }
              unit="€"
              nombre={1}
            />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoTransportStockage}
              titre="Entreposage et services auxiliaires des transports "
              titregras={'Transport auxiliaire & Stockage'}
              onValeurChange={(valeur) =>
                handleValeurChange('transportStockage', valeur)
              }
              unit="k€"
              nombre={1}            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoInstallationRéparation}
              titre="Réparation et installation de machines et d'équipements "
              titregras={'Installation & Réparation'}
              onValeurChange={(valeur) =>
                handleValeurChange('installationRéparation', valeur)
              }
              unit="€"
              nombre={1}
            />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoTélécomServicesDivers}
              titre="Services (imprimerie, publicité, architecture et ingénierie, etc.) Télécommunications"
              titregras={'Télécom & Services divers'}
              onValeurChange={(valeur) =>
                handleValeurChange('télécomServicesDivers', valeur)
              }
              unit="€"
              nombre={1}
            />
          </div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>

        </div>

        <Titre Titre="Transport (Fret) : restauration, équipements, aménagements, décors, etc." />

      <div className="row">
        <div className="col-12">
          <div className="h61 vert">Fret routier</div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-12">
          <InputFieldNbUnique
            titre="Fret routier"
            onValeurChange={(valeur) =>
              handleValeurChangeTransport('fretRoutier', valeur)
            }
            unit="tonnes.km"
            nombre={transportValues.fretRoutier}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="h61 vert">Fret aérien</div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg col-md-6 col-12">
          <InputFieldNbUnique
            titre="Distance supérieure à 3000km"
            onValeurChange={(valeur) =>
              handleValeurChangeTransport('fretAerienSup3000', valeur)
            }
            unit="tonnes.km"
            nombre={transportValues.fretAerienSup3000}
          />
        </div>
        <div className="col-lg col-md-6 col-12">
          <InputFieldNbUnique
            titre="Distance inférieure à 3000km"
            onValeurChange={(valeur) =>
              handleValeurChangeTransport('fretAerienInf3000', valeur)
            }
            unit="tonnes.km"
            nombre={transportValues.fretAerienInf3000}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="h61 vert">Fret ferroviaire</div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg col-md-6 col-12">
          <InputFieldNbUnique
            titre="En France"
            onValeurChange={(valeur) =>
              handleValeurChangeTransport('fretFerroviaireFrance', valeur)
            }
            unit="tonnes.km"
            nombre={transportValues.fretFerroviaireFrance}
          />
        </div>
        <div className="col-lg col-md-6 col-12">
          <InputFieldNbUnique
            titre="Hors France"
            onValeurChange={(valeur) =>
              handleValeurChangeTransport('fretFerroviaireHorsFrance', valeur)
            }
            unit="tonnes.km"
            nombre={transportValues.fretFerroviaireHorsFrance}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="h61 vert">Fret maritime</div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg col-md-6 col-12">
          <InputFieldNbUnique
            titre="En porte-conteneurs"
            onValeurChange={(valeur) =>
              handleValeurChangeTransport('fretMaritimeConteneurs', valeur)
            }
            unit="tonnes.km"
            nombre={transportValues.fretMaritimeConteneurs}
          />
        </div>
        <div className="col-lg col-md-6 col-12">
          <InputFieldNbUnique
            titre="En porte-vraquiers"
            onValeurChange={(valeur) =>
              handleValeurChangeTransport('fretMaritimeVraquiers', valeur)
            }
            unit="tonnes.km"
            nombre={transportValues.fretMaritimeVraquiers}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="h61 vert">Fret fluvial</div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg col-md-6 col-12">
          <InputFieldNbUnique
            titre="En pousseurs"
            onValeurChange={(valeur) =>
              handleValeurChangeTransport('fretFluvialPousseurs', valeur)
            }
            unit="tonnes.km"
            nombre={transportValues.fretFluvialPousseurs}
          />
        </div>
        <div className="col-lg col-md-6 col-12">
          <InputFieldNbUnique
            titre="En automoteurs"
            onValeurChange={(valeur) =>
              handleValeurChangeTransport('fretFluvialAutomoteurs', valeur)
            }
            unit="tonnes.km"
            nombre={transportValues.fretFluvialAutomoteurs}
          />
        </div>
      </div>


      <Titre Titre="Déchets (Fin de vie) : décors, communication, déchets sur place, etc." />

        <div className="row">
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoPlastique}
              titre="Poubelle recyclable"
              titregras={'Plastique'}
              onValeurChange={(valeur) =>
                handleValeurChange('plastiqueDechet', valeur)
              }
              unit="tonnes"
              nombre={1}    />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoPapier}
              titre="Poubelle recyclable"
              titregras={'Papier'}
              onValeurChange={(valeur) =>
                handleValeurChange('papierDechet', valeur)
              }
              unit="tonnes"
              nombre={1} />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoBois}
              titre="Bois"
              titregras={'Bois'}
              onValeurChange={(valeur) =>
                handleValeurChange('Bois', valeur)
              }
              unit="tonnes"
              nombre={1} />
          </div>
          <div className="col-lg col-md-6 col-12">
            <CarreLogoInput2
              logo={LogoVerre}
              titre="Poubelle recyclable"
              titregras={'Verre & Métal'}
              onValeurChange={(valeur) =>
                handleValeurChange('Verre', valeur)
              }
              unit="tonnes"
              nombre={1}        />
          </div>
          <div className='col'></div>
        </div>
      </div>

      <Compteur
        titre="Pourcentage de déchets donnés à des associations pour être réutilisés ou valorisés"
        unit='%'
        onValeurChange={(value) => setValorise(value)}
      />

 
      <div className="row">
        <div className="col-12">
          <Bouton titre="CALCULER" />
        </div>
      </div>
  
      {formSubmitted && ( 
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInter numeroEtape={2} voyage="non" />
            </div>
          </div>
  
          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick}/>
            </div>
          </div>
        </div>
      )}

    </form>
  );
}

export default FormLogistique;
