import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../Particulier/Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import Compteur from '../../../components/Compteur/Compteur';
import CheckboxChoixUnique from '../../../components/Form/checkBox/checkbox';
import CheckboxChoixUniqueAdapt from '../../../components/Form/checkBox/checkbox-adapt';
import imageEnergie from "../../../components/img/Energie.png"
import { Padding } from '@mui/icons-material';
import Bouton from '../../../components/Form/Bouton';
import FormDomicile from '../../../components/Form/Form Particulier/FormDomicile';
import FormLieu from '../../../components/Form/Form Event/FormLocal';
import StepperLineEvent from '../../../components/StepperLine/index-event';
import Image from "../../../components/img/ESPenergie.png";

const LieuEvent = () => {
    const navigate = useNavigate();
    const [consommationConnue, setConsommationConnue] = useState(false);
    const reponses = ['≤5    A', '6 à 10    B', '11 à 20 C', '21 à 35 D', '36 à 55 E', '56 à 80 F', '> 80 G'];



    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLineEvent/>

        <div className="container">
            <div className="row">
            <div className="col-12">
                <Titre Titre="Information sur le logement"    place="start"/>
            </div>
            </div>

            <div className="row col-10 offset-1">
            <FormLieu/>
            </div>
            {/* <div className="row col-12">
            <div className="col-6"><Compteur titre="Nombre de personnes vivant avec vous dans le logement"/></div>
            </div>
            <div className="row col-12">
            <div className="col-6"><Compteur titre="Surface du logement"/></div>
            </div>
            <div className="row">
            <div className="col-12"><CheckboxChoixUnique onChange={(value) => setConsommationConnue(value)} titre="Connaissez-vous la catégorie de votre logement dans l’étiquette climat (kgeqCO2/m².an) ?" rep1="oui" rep2="non"/></div>
            </div>

            <div className="row col-12">
            <div className="col-4"><CheckboxChoixUniqueAdapt titre="Quelle est votre étiquette climat ?" reponses={reponses} /></div>
            <div className="col-6"><img src={imageEnergie} /></div>
            <div className="col-2" style={{height : "150px", paddingLeft : "50px"}} >
                <div className='p3' style={{backgroundColor : "#EBE085", padding : "10%"}}>
                l’étiquette climat pour connaître la quantité de gaz à effet de serre émise
                (à droite sur l’image) n’est pas à confondre avec l’étiquette energie à gauche. 
                </div>
            </div>
            </div> */}

                
            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div className='row'>
        <div className='col-md-8 col-12'>
        <p>Les Émissions de gaz à effet de serre liées à votre consommation énergétique</p>
        <p>Les émissions de gaz à effet de serre liées à votre consommation énergétique dépendent des sources d’énergie utilisées. Le gaz, le pétrole, le charbon sont des énergies fossiles qui émettent directement des gaz à effets de serre lors de leur combustion alors que l’électricité quant à elle, émet indirectement lors de sa phase amont, sa combustion à la centrale, et son transport (pertes énergétiques). Les facteurs d’émissions choisis sont ceux issus de la base carbone de l’ADEME.</p>
        
        <p>Pour chaque lieu fermé utilisé, chauffé ou climatisé, indiquez la surface ainsi que le mode de chauffage/climatisation et, si vous la connaissez, la consommation. Sinon, une estimation sera faite à partir de la surface renseignée.</p>
        </div>
        <img className='col-md-4 col-12' src={Image} alt="Répartition de la consommation d'énergie primaire en france" />
    </div>
} />
            </div>
            </div>

            <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div>

        </div>
        </div>
    </div>
    </>
    );
};

export default LieuEvent;

