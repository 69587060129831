import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../Particulier/Avion.css"; 
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import CarreLogoCompteur from '../../../components/CarreLogo/CarreLogoCompteur';
import LogoCereale from "../../../components/img/cereale.png";
import LogoCont from "../../../components/img/continental.png";
import LogoSale from "../../../components/img/sale.png";
import LogoVege from "../../../components/img/vege.png";
import LogoVegetalien from "../../../components/img/vegetalien.png";
import LogoPoisson from "../../../components/img/poisson.png";
import LogoViande from "../../../components/img/viande.png";
import LogoVolail from "../../../components/img/volaille.png";
import LogoThe from "../../../components/img/the.png";
import LogoCafe from "../../../components/img/cafe.png";
import LogoBiere from "../../../components/img/biere.png";
import LogoJus from "../../../components/img/jus.png";
import LogoSodas from "../../../components/img/soda.png";


import SousTitre from '../../../components/SousTitre';
import TitreH2 from '../../../components/TitreH2';
import Compteur from '../../../components/Compteur/Compteur';
import FormEnergie from '../../../components/Form/Form Entreprise/Energie';
import StepperLineEntreprise from '../../../components/StepperLine/index-entreprise';
import Image from "../../../components/img/ESPenergie.png";

const Energie1 = () => {
    const navigate = useNavigate();


    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLineEntreprise/>

        <div className="container limiteContenue">
            <div className="row">
            <div className="row col-10 offset-1">
            <Titre Titre="Consommation énergétique des locaux" />
            </div>
            </div>
            <div className="row col-10 offset-1">
            <FormEnergie />
            </div>

            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div className='row'>
        <div className='col-md-8 col-12'>
        <p>Les Émissions de gaz à effet de serre liées à votre consommation énergétique</p>
        <p>Les émissions de gaz à effet de serre liées à votre consommation énergétique dépendent des sources d’énergie utilisées. Le gaz, le pétrole, le charbon sont des énergies fossiles qui émettent directement des gaz à effets de serre lors de leur combustion alors que l’électricité quant à elle, émet indirectement lors de sa phase amont, sa combustion à la centrale, et son transport (pertes énergétiques). Les facteurs d’émissions choisis sont ceux issus de la base carbone de l’ADEME.</p>
        
        <p>Pour chaque lieu fermé utilisé, chauffé ou climatisé, indiquez la surface ainsi que le mode de chauffage/climatisation et, si vous la connaissez, la consommation. Sinon, une estimation sera faite à partir de la surface renseignée.</p>
        </div>
        <img className='col-md-4 col-10' src={Image} alt="Répartition de la consommation d'énergie primaire en france" />
    </div>
} />

            </div>
            </div>

            <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div>

        </div>
        </div>
    </div>
    </>
    );
};

export default Energie1;

