import React from 'react';
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/Index";
import Graphique from "../../components/Bilan/index"
import CardPetiteDroite from '../../components/CardPetiteDroite';
import SousTitre from '../../components/SousTitre';
import ChiffreSousChiffre from '../../components/CardPetiteDroite/Chiffre';
import ListDeroule from '../../components/Form/ListDeroule';
import TitreH2 from '../../components/TitreH2/index';
import TitreBG from '../../components/TitreBG/index';
import image1 from "../../components/img/planet.png";
import image2 from "../../components/img/Trainbilan.png"
import logolegume from "../../components/img/Logolegume.png"
import logoAvion from "../../components/img/LogoAvion.png"
import Ligne1 from '../../components/Bilan/Ligne1';
import Ligne2 from '../../components/Bilan/Ligne2';
import Bouton from '../../components/Form/Bouton';
import CardBilan from '../../components/Bilan/CardBilan';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import Band1 from '../../components/Bilan/Band1';
import Band2 from '../../components/Bilan/Band2';
import CarreLogo from '../../components/CarreLogo/CarreLogo';
import BilanInter from '../../components/BilanInter';
import BilanFinal from '../../components/BilanInter/final';
import StepperLine from '../../components/StepperLine/index-test';

const BilanPart4 = () => {
    const handleListDerouleChange = (selectedValue) => {
    // Logique de gestion du changement de valeur du ListDeroule
    console.log("ListDeroule value:", selectedValue);
    };

    return (
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLine />

        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>
        <div className="row">

            <div className="col-10"    style={{marginTop : "5vh"}}>
                <TitreH2 Titre="Bilan de vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
            
        </div>

        <div className="row">
            
            <div className="col-9" style={{marginBottom : "3vh"}}>
                <h1 className = "h31">Le bilan détaillé de mes émissions </h1>
            </div>
            
            </div>

            <div className="row">
                <BilanFinal numeroEtape={8} voyage="non" />
            </div>


            



            <div className='' style={{marginTop : "5vh"}}>
                <Band1/>
            </div>
            
            <div className='row col-12' style={{marginTop : "5vh"}}>
                <CardBilan/>
            </div>

        </div>
        <Footer />
    </div>
    );
};

export default BilanPart4;

