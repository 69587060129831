import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PetiteCard2.css';

const PetiteCard2 = ({ label, imageName, lien }) => {
    const navigate = useNavigate();

    const handleButtonClick = (event) => {
    event.preventDefault();
    navigate(lien); // Ajout de la navigation après la soumission du formulaire
    };

    return (
    <button className="small-card2" onClick={handleButtonClick}>
        <div className="rectangle">
        <div className="label">{label}</div>
        <div className="image" style={{ backgroundImage: `url(${require(`../img/${imageName}`)})` }} />
        </div>
    </button>
    );
}

export default PetiteCard2;
