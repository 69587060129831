import React, { useState, useEffect } from 'react';
import "./ListDeroule.css";

function ListDeroule(props) {
  const { titre, value, onChange, text, storageKey, mots } = props;
  const [selectedValue, setSelectedValue] = useState(value);
  const [loadedFromStorage, setLoadedFromStorage] = useState(false); // Drapeau pour suivre le chargement initial depuis le stockage
  
  const handleSelectChange = (e) => {
  const selectedValue = e.target.value;
  setSelectedValue(selectedValue);
  onChange(selectedValue);
  localStorage.setItem(storageKey, selectedValue);
  };

  // useEffect(() => {
  //   const storedValue = localStorage.getItem(storageKey);
  //   if (storedValue) {
  //   setSelectedValue(storedValue);
  //   onChange(storedValue);
  //   }
  // }, [storageKey, onChange]);

  useEffect(() => {
  if (!loadedFromStorage) {
    const storedValue = localStorage.getItem(storageKey);
    if (storedValue) {
    setSelectedValue(storedValue);
    onChange(storedValue);
    }
    setLoadedFromStorage(true); // Mettre à jour le drapeau après le chargement initial depuis le stockage
  }
  }, [storageKey, onChange, loadedFromStorage]);



  return (
  <div className="row form-group-list">
    <div className="titre-list col-25">
    <label className='h61'>{titre}</label>
    </div>
    <div className="liste col-75">
    <select className="form-select form-control-list" value={selectedValue} onChange={handleSelectChange}>
      <option value={text}>{text}</option>
      {mots && mots.map((mot, index) => (
      <option key={index} value={mot}>
        {mot}
      </option>
      ))}
    </select>
    </div>
  </div>
  );
}

export default ListDeroule;
