import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import CheckboxChoixUnique from '../checkBox/checkbox';
import InputFieldNb from '../InputNombre/index';
import CheckboxAjout from '../checkBoxAjout/CheckboxAjout';
import AjoutPerso from '../Ajout/index-personne';
import { Button } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CacheContext from '../../../context';

function FormVTC({ onSubmit }) {
	const { cache, setCache } = useContext(CacheContext);
    const navigate = useNavigate();
    const [valeurInput, setValeurInput] = useState(() => cache.valeurInput || '1');
    const [formSubmitted, setFormSubmitted] = useState(() => cache.formSubmitted || false);
    const [consommationConnue, setConsommationConnue] = useState(() => cache.consommationConnue || false);
    const [typeCarburant, setTypeCarburant] = useState(() => cache.typeCarburant || '');
    const [typeKilo, setTypeKilo] = useState(() => cache.typeKilo || 'oui');
    const [consommation, setConsommation] = useState(() => cache.consommation || '');
    const [kilometrageConnu, setKilometrageConnu] = useState(() => cache.kilometrageConnu || false);
    const [vehicules, setVehicules] = useState(() => cache.vehicules || [
    {
        id: 1,
        isOpen: true,
        typeCarburant: '',
        typeVehicule: '',
        ajoutPersoV: 1,
        voyages: [
        {
            id: 1,
            typeVoyage: '',
            depart: {
            name: '',
            latitude: null,
            longitude: null
            },
            arrive: {
            name: '',
            latitude: null,
            longitude: null
            },
            ajout: { valeur: '', unit: 'an' },
        },
        ],
    },
    ]);

    const handleChangeVehicule = useCallback((id, field, value) => {
    setVehicules((prevVehicules) => {
        const updatedVehicules = prevVehicules.map((vehicule) => {
        if (vehicule.id === id) {
            return { ...vehicule, [field]: value };
        }
        return vehicule;
        });
        return updatedVehicules;
    });
    }, []);

    const handleChangeVoyage = useCallback((vehiculeId, voyageId, field, value) => {
    setVehicules((prevVehicules) => {
        const updatedVehicules = prevVehicules.map((vehicule) => {
        if (vehicule.id === vehiculeId) {
            const updatedVoyages = vehicule.voyages.map((voyage) => {
            if (voyage.id === voyageId) {
                // Si le champ est l'un des champs imbriqués, le traiter différemment
                if (field === 'depart' || field === 'arrive' || field === 'ajout') {
                return { ...voyage, [field]: { ...voyage[field], ...value } };
                } else {
                return { ...voyage, [field]: value };
                }
            }
            return voyage;
            });
            return { ...vehicule, voyages: updatedVoyages };
        }
        return vehicule;
        });
        return updatedVehicules;
    });
    }, []);
    

    const handleChangeAjout = useCallback((vehiculeId, voyageId, values) => {
    setVehicules((prevVehicules) => {
        const updatedVehicules = prevVehicules.map((vehicule) => {
        if (vehicule.id === vehiculeId) {
            const updatedVoyages = vehicule.voyages.map((voyage) => {
            if (voyage.id === voyageId) {
                // Assigner la valeur de 'unit' en fonction de la chaîne de caractères
                let unitValue;
                switch (values.unit) {
                case 'an':
                    unitValue = 279640000;
                    break;
                case 'mois':
                    unitValue = 279640001;
                    break;
                case 'semaine':
                    unitValue = 279640002;
                    break;
                default:
                    unitValue = 279640000; // Valeur par défaut, au cas où 'unit' ne correspond à aucun des cas précédents
                }
                // Retourner le voyage mis à jour avec la valeur de 'unit' appropriée
                return { ...voyage, ajout: { frequence: values.frequence, unit: unitValue } };
            }
            return voyage;
            });
            // Retourner le véhicule mis à jour avec les voyages mis à jour
            return { ...vehicule, voyages: updatedVoyages };
        }
        return vehicule;
        });
        return updatedVehicules;
    });
    }, []);

    const handleChangeAjoutPerso = useCallback((vehiculeId, voyageId, values) => {
    setVehicules((prevVehicules) => {
        const updatedVehicules = prevVehicules.map((vehicule) => {
        if (vehicule.id === vehiculeId) {
            const updatedVoyages = vehicule.voyages.map((voyage) => {
            if (voyage.id === voyageId) {
                return { ...voyage, ajoutPerso: values };
            }
            return voyage;
            });
            return { ...vehicule, voyages: updatedVoyages };
        }
        return vehicule;
        });
        return updatedVehicules;
    });
    }, []);

    const handleDeleteVoyage = (vehiculeId, voyageId) => {
    setVehicules((prevVehicules) => {
        const updatedVehicules = prevVehicules.map((vehicule) => {
        if (vehicule.id === vehiculeId) {
            const updatedVoyages = vehicule.voyages.filter(
            (voyage) => voyage.id !== voyageId
            );
            return { ...vehicule, voyages: updatedVoyages };
        }
        return vehicule;
        });
        return updatedVehicules;
    });
    };

    const addVoyage = (vehiculeId) => {
    setVehicules((prevVehicules) => {
        const updatedVehicules = prevVehicules.map((vehicule) => {
        if (vehicule.id === vehiculeId) {
            const newVoyage = {
            id: vehicule.voyages.length + 1,
            typeVoyage: '',
            depart: {
                name: '',
                latitude: null,
                longitude: null
            },
            arrive: {
                name: '',
                latitude: null,
                longitude: null
            },
            ajout: { valeur: '', unit: 'an' },
            };
            return { ...vehicule, voyages: [...vehicule.voyages, newVoyage] };
        }
        return vehicule;
        });
        return updatedVehicules;
    });
    };

    const addVehicule = () => {
    setVehicules((prevVehicules) => {
        const newVehicule = {
        id: vehicules.length + 1,
        typeCarburant:'',
        typeVehicule: '',
        ajoutPersoV : 1,
        isOpen: true,
        voyages: [
            {
            id: 1,
            typeVoyage: '',
            depart: {
                name: '',
                latitude: null,
                longitude: null
            },
            arrive: {
                name: '',
                latitude: null,
                longitude: null
            },
            ajout: { valeur: '', unit: 'an' },
            },
        ],
        };
        return [...prevVehicules, newVehicule];
    });
    };

    const handleDeleteVehicule = (vehiculeId) => {
    setVehicules((prevVehicules) =>
        prevVehicules.filter((vehicule) => vehicule.id !== vehiculeId)
    );
    };

    const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log(formSubmitted);
    };

    const handleChangeAjoutPersoV = useCallback((vehiculeId, values) => {
    setVehicules((prevVehicules) => {
        const updatedVehicules = prevVehicules.map((vehicule) => {
        if (vehicule.id === vehiculeId) {
            return { ...vehicule, ajoutPersoV: values };
        }
        return vehicule;
        });
        return updatedVehicules;
    });
    }, []);

    const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/Voiture-11'); // Ajout de la navigation après la soumission du formulaire
    };


    const [isOpen, setIsOpen] = useState(() => cache.isOpen || false);

    // Update cache when voyages change
    useEffect(() => {
		setCache(prevCache => ({
			...prevCache,
			isOpen,
			valeurInput,
			formSubmitted,
			consommationConnue,
			typeCarburant,
			typeKilo,
			consommation,
			kilometrageConnu,
			vehicules,	
		}));
	}, [
		isOpen,
		valeurInput,
		formSubmitted,
		consommationConnue,
		typeCarburant,
		typeKilo,
		consommation,
		kilometrageConnu,
		vehicules,	
	]);


    const toggleDropdown = (vehiculeId) => {
    setVehicules((prevVehicules) => {
        return prevVehicules.map((vehicule) => {
        if (vehicule.id === vehiculeId) {
            return { ...vehicule, isOpen: !vehicule.isOpen };
        }
        return vehicule;
        });
    });
    };

    

    return (
    <form onSubmit={handleSubmit}>
        {vehicules.map((vehicule) => (
        <div key={vehicule.id}>
            <div className="row col-12" style={{ marginTop: "5vh" }}>
            <div className="SousTitre">
                <DeleteOutlinedIcon
                sx={{ color: "#024C4A" }}
                className="Icon"
                onClick={() => handleDeleteVehicule(vehicule.id)}
                />
                <div className="h61">Vehicule {vehicule.id}</div>
                {vehicule.isOpen ? (
                <ExpandLessIcon
                    sx={{ color: "#024C4A" }}
                    className="Icon"
                    onClick={() => toggleDropdown(vehicule.id)}
                />
                ) : (
                <ExpandMoreIcon
                    sx={{ color: "#024C4A" }}
                    className="Icon"
                    onClick={() => toggleDropdown(vehicule.id)}
                />
                )}
            </div>
            </div>
    
            {vehicule.isOpen && (
            <div>
                <div className="row">
                <div className="col-12 col-lg-4">
                    <ListDeroule
                    titre="Type de carburant"
                    text="Essence"
                    storageKey={`type-carburant-${vehicule.id}`}
                    value={vehicule.typeCarburant}
                    onChange={(value) => {
                        let convertedValue;
                        switch (value) {
                        case "Essence":
                            convertedValue = 279640000;
                            break;
                        case "Electrique":
                            convertedValue = 279640001;
                            break;
                        case "Hybride":
                            convertedValue = 279640002;
                            break;
                        case "GPL":
                            convertedValue = 279640003;
                            break;
                        default:
                            convertedValue = 279640000; // Si la valeur est inattendue, la valeur par défaut est celle d'Essence
                        }
                        handleChangeVehicule(vehicule.id, "typeCarburant", convertedValue);
                    }}
                    mots={["Electrique", "Hybride", "GPL"]}
                    />
                </div>
                <div className="col-12 col-lg-4">
                    <ListDeroule
                    titre="Type de véhicule"
                    text="Berline"
                    storageKey={`type-vehicule-${vehicule.id}`}
                    value={vehicule.typeVehicule}
                    onChange={(value) => {
                        let convertedVehicule;
                        switch (value) {
                        case "Berline":
                            convertedVehicule = 279640000;
                            break;
                        case "Entrée de gamme":
                            convertedVehicule = 279640001;
                            break;
                        case "Coeur de gamme":
                            convertedVehicule = 279640002;
                            break;
                        case "Haut de gamme":
                            convertedVehicule = 279640003;
                            break;
                        default:
                            convertedVehicule = 279640000; // Si la valeur est inattendue, la valeur par défaut est celle de Berline
                        }
                        handleChangeVehicule(vehicule.id, "typeVehicule", convertedVehicule)
                    }}
                    mots={["Entrée de gamme","Coeur de gamme","Haut de gamme"]}
                    />
                </div>
                <div className="col-12 col-lg-5">
                    <AjoutPerso
                    values={vehicule.ajoutPersoV}
                    setValues={(values) =>
                        handleChangeAjoutPersoV(vehicule.id, values)
                    }
                    />
                </div>
                </div>
            </div>
            )}
    
            {vehicule.isOpen && (
            <div>
                <div className="row">
                <div className="col-12">
                    {vehicule.voyages.map((voyage) => (
                    <div className="duplique" key={voyage.id}>
                        <div className="row col-12">
                        <div className="SousTitre">
                            <DeleteOutlinedIcon
                            sx={{ color: "#024C4A", fontSize: 20 }}
                            className="Icon"
                            onClick={() =>
                                handleDeleteVoyage(vehicule.id, voyage.id)
                            }
                            />
                            <div className="h51">Voyage {voyage.id}</div>
                        </div>
                        </div>
    
                        <div className="row">
                        <div className="col-12 col-lg-4">
                            <ListDeroule
                            titre="Type de voyage"
                            text="Aller - retour"
                            storageKey={`uniqueKey-${voyage.id}`}
                            value={voyage.typeVoyage}
                            onChange={(value) => {
                                let convertedValue;
                                switch (value) {
                                case "Aller - retour":
                                    convertedValue = 279640000;
                                    break;
                                case "Aller Simple":
                                    convertedValue = 279640001;
                                    break;
                                default:
                                    convertedValue = 279640000; // Si la valeur est inattendue, la valeur de Aller-Retour est prise par défaut
                                }
                                handleChangeVoyage(
                                vehicule.id,
                                voyage.id,
                                "typeVoyage",
                                convertedValue
                                )
                            }}
                            mots={["Aller Simple"]}
                            />
                        </div>
                        <div className="col-12 col-lg-4">
                            <InputField
                            titre="Départ"
                            value={voyage.depart.name} // Use the name from the depart object
                            onChange={(value) =>
                                handleChangeVoyage(
                                vehicule.id,
                                voyage.id,
                                "depart",
                                value
                                )
                            }
                            isLocation={true}
                            />
                        </div>
                        <div className="col-12 col-lg-4">
                            <InputField
                            titre="Arrivée"
                            value={voyage.arrive.name} // Use the name from the arrive object
                            onChange={(value) =>
                                handleChangeVoyage(
                                vehicule.id,
                                voyage.id,
                                "arrive",
                                value
                                )
                            }
                            isLocation={true}
                            />
                        </div>
                        </div>
    
                        <div className="row">
                        <div className="col-12 col-lg-6">
                            <Ajout
                            values={voyage.ajout}
                            setValues={(values) =>
                                handleChangeAjout(
                                vehicule.id,
                                voyage.id,
                                values
                                )
                            }
                            />
                        </div>
                        </div>
                    </div>
                    ))}
                </div>
                </div>
            </div>
            )}
    
            {vehicule.isOpen && (
            <div className="row">
                <div className="col-12">
                <button
                    type="button"
                    className="custom-button"
                    style={{
                    fontSize: "12px",
                    fontWeight: "16px",
                    marginTop: "1vh",
                    }}
                    onClick={() => addVoyage(vehicule.id)}
                >
                    Ajouter un voyage
                </button>
                </div>
            </div>
            )}
        </div>
        ))}
     {/* {vehicules.map((vehicule, index) => (
                <div className="duplique" key={index}>
                <AppelAPIVLoc voyage={voyage} kmConnu={typeKilo} km={valeurInput} setLancer={setLancer} Lancer={true} setApiResponse={updateApiResponse}/>
                
                Affichage des valeurs spécifiques pour ce voyage
                    <div className="row">
                    <div className="col-12">
                        <div className='h61'>Valeurs spécifiques pour Véhicule {index + 1} :</div>
                        <div>IdPage : PVoitureLoc</div>
                        
                        <div>IdVoitureLoc : {vehicule.id}</div>
                        <div>Type Carburant : {vehicule.typeCarburant}</div>
                        <div>Type Véhicule : {vehicule.typeVehicule}</div>
                        <div>NbPersonnes: {vehicule.ajoutPersoV ? vehicule.ajoutPersoV.valeur : 'Non défini'}</div>
                        <div>Voyages :</div> 
                        {vehicule.voyages.map((voyage, voyageIndex) => (
                        <div key={voyageIndex}>
                        <div>typeVoyage : {voyage.typeVoyage}</div>
                        <div>depart : {voyage.depart.name}</div> 
                        <div>latitudeDepart : {voyage.depart.latitude}</div>
                        <div>longitudeDepart : {voyage.depart.longitude}</div>
                        <div>arrive : {voyage.arrive.name}</div> 
                        <div>latitudeArrive : {voyage.arrive.latitude}</div>
                        <div>longitudeArrive : {voyage.arrive.longitude}</div>
                        <div>frequence : {voyage.ajout.frequence}</div>
                        <div>unit : {voyage.ajout.unit}</div>
                        <div>message : {voyage.emptyFieldsMessage}</div>
                        </div>
                        ))}
                        
                    </div>
                    </div>
                </div>
                ))} */}
        <div className="row">
        <div className="col-12 d-flex justify-content-end">
            <button
            style={{ marginTop: "10px", marginBottom: "20px" }}
            type="button"
            className="custom-button-droit"
            onClick={addVehicule}
            >
            Ajouter un véhicule
            </button>
        </div>
        </div>


        
    </form>
    );
    
}

export default FormVTC;
