import React, { createContext, useState, useContext, useEffect } from 'react';

export const UUIDContext = createContext({
    uuid: null,
    setUUID: () => {}
});

export const UUIDProvider = ({ children }) => {
    const [uuid, setUUID] = useState(() => {
    // Récupération de la valeur depuis le localStorage au montage initial
    const storedUUID = localStorage.getItem('uuid');
    return storedUUID ? storedUUID : "123"; // Si aucune valeur stockée, on utilise "123" comme valeur par défaut
    });

    useEffect(() => {
    // Chaque fois que la valeur de 'uuid' change, on la stocke dans le localStorage
    localStorage.setItem('uuid', uuid);
    }, [uuid]);

    return (
    <UUIDContext.Provider value={{ uuid, setUUID }}>
        {children}
    </UUIDContext.Provider>
    );
};

export function useUUID() {
    return useContext(UUIDContext);
}

