import React from 'react';
import Voyages from './cityForm';

function MonFormulaireB() {
  const formulaireId = 'formulaire2'; // Remplacez par un identifiant unique pour chaque formulaire

  return (
    <div>
      <h2>Mon Formulaire</h2>
      <Voyages formulaireId={formulaireId} />
      {/* Le reste du contenu de votre formulaire */}
    </div>
  );
}

export default MonFormulaireB;
