import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from "../../../components/Titre/index";
import Header from "../../../components/Header/index";
import Stepperline from "../../../components/StepperLine/index-test";
import "../../../Font/font-family.css";
import "../../../Font/font-google-api.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Avion.css"; 
import FormVoiture from "../../../components/Form/Form Particulier/FormVoiture";
import Ensavoir from "../../../components/Ensavoir";
import BilanInter from "../../../components/BilanInter";
import Footer from "../../../components/Footer/Index";
import FormMoto from '../../../components/Form/Form Particulier/FormMoto';
import StepperLineVoyage from '../../../components/StepperLine/index-voyage';

const MotoVoyage = () => {
    const navigate = useNavigate();


    return (
    <>
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLineVoyage/>

        <div className="container">
            <div className="row">
            <div className="col-lg-10 offset-lg-1 col-12">
                <Titre Titre="Moto"    place="start"/>
            </div>
            </div>
            <div className="row col-lg-10 offset-lg-1 col-12">
            <FormMoto />
            </div>

                
            <div className="row">
            <div className="col-12">
            <Ensavoir contenu={
    <div>
        <div className='h41'>Émissions liées aux voyages en voiture</div>
        <p>L’empreinte carbone d’un voyage en voiture se calcule par véhicule. En soi, une voiture qui circule consomme autant de carburant et émet autant de CO₂e qu’elle transporte à son bord 1 ou 5 personnes. Ce qui change en revanche est le calcul par personne (remplir une voiture réduit l’empreinte carbone par personne). Si vous avez l’habitude de transporter d’autres voyageurs (covoiturage), vous pouvez alors indiquer le nombre de passagers pour chaque voyage. Dans ce cas, l’empreinte carbone de votre voyage sera divisée par le nombre de sièges occupés.</p>
        <p>Sinon, si vous indiquez un nombre de kilomètres, l’ADEME considère par défaut un taux de remplissage moyen annuel de 1,6 passager pour des trajets mixtes (courtes et longues distances).</p>
        
        <p>Outre les émissions liées à la combustion du carburant, il faut également ajouter celles liées à la construction du véhicule, une étape particulièrement émettrice. Les émissions dépendent du poids du véhicule et donc de la catégorie du véhicule (citadine, berline, SUV…)</p>
        
        <p>Les cas particuliers de la location de voiture et des courses de taxi :</p>
        <p>Dans le cas où vous n’êtes pas propriétaire de votre véhicule, les émissions liées à la fabrication du véhicule incombent à l’ensemble des utilisateurs du véhicule, au prorata du nombre de kilomètres effectués en moyenne par le véhicule dans l’année. Nous choisissons l’hypothèse qu’un véhicule partagé parcourt en moyenne 16300 km par an. <a href="https://librairie.ademe.fr/cadic/460/enquete-autopartage-2019-rapport.pdf" target="_blank" rel="noopener noreferrer">Source : Enquête nationale sur l’autopartage – édition 2019</a></p>
        
        <p>Dans le cas des taxis, le conducteur ne compte pas comme un passager supplémentaire dans la voiture.</p>
    </div>
} />

            </div>
            </div>

            <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div>

        </div> 
        </div>
    </div>
    </>
    );
};

export default MotoVoyage;

