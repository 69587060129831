import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';
import { IdBCContext } from '../IdBCContext';
import { useFormData } from '../FormDataContext';

const AppelAPIVoiture = ({index,idVoiture,voyage,typeVehicule,typeCarburant,valeurInput,selectedUnite, consommation,kilometrageConnu, unit, save, setLancer, Lancer, setApiResponse, setConsoVoyage, setAppelVoiture, setIdVoiture, filledData, usedUrl, lancerSave, setLancerSave, id}) => {
  const { token, fetchToken } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { idBC } = useContext(IdBCContext);
  const {formData, setFormData} = useFormData();
  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    console.log("l'ID de l'appel est : ", id)
    if (token && Lancer) { 
       fetchData();
       setLancer(false);
     }
  }, [token, Lancer]); // Se ré-exécute lorsque `token` change

  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if(token && lancerSave){
       fetchDataToSave(filledData, usedUrl);
       setLancerSave(false)
     }
  }, [token, lancerSave]); // Se ré-exécute lorsque `token` change

  const fetchDataToSave = async (filledData, usedUrl) => {
    setLoading(true);
    filledData.save = true
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    try {
      const result = await axios.post(usedUrl, filledData, options);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');

    } finally {
      setLoading(false);
      

    }
  }

   const fetchData = async () => {
     setLoading(true);
    const apiUrlConnu = 'https://dev-goodplanet.crm12.dynamics.com/api/data/v9.1/aps_Pvoiture_Global';
    const apiUrlInconnu= 'https://dev-goodplanet.crm12.dynamics.com/api/data/v9.1/aps_Pvoiture_Detail';
    
    let apiUrl = ''; 

    // Déterminer l'URL de l'API en fonction de la valeur de `kmConnu`
    if (kilometrageConnu === 'oui') {
        apiUrl = apiUrlConnu;
      } else if (kilometrageConnu === 'non') {
        apiUrl = apiUrlInconnu;
      }

    const data = (kilometrageConnu ==='oui')
      ? {
      IdBC: idBC,
      IdPage: "Pvoiture",
      ConsoConnue: consommation !== '',
      TypeVehicule: typeVehicule,
      TypeEnergie: typeCarburant,
      Consommation: consommation || 0,
      UnitConsommation: unit,
      KilometrageConnu: "True",
      Kilometrage: valeurInput,
      unit: selectedUnite, 
      save: save,
    }: (kilometrageConnu === 'non' && idVoiture.length > 8) ? // Test de la longueur de idVoiture pour savoir s'il est bien différent de vide et de "demande"
    {
        IdBC: idBC,
        IdPage: "Pvoiture",
        IdVoiture: idVoiture,
        typeVoyage: voyage.typeVoyage,
        depart: voyage.depart.name,
        latitudeDepart: voyage.depart.latitude,
        longitudeDepart: voyage.depart.longitude,
        arrivee: voyage.arrive.name,
        latitudeArrivee: voyage.arrive.latitude,
        longitudeArrivee: voyage.arrive.longitude,
        frequence: parseFloat(voyage.ajout.frequence),
        unit: voyage.ajout.unit,
        NbPersonnes: voyage.ajoutPerso.valeur,
        save: save
      }:null;
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

    //Faire le if sur la condition km connu =true
    try {
      const result = await axios.post(apiUrl, data, options);
      // Stocker à jour l'appel à l'API pour plus tard
        setAppelVoiture(index, data, apiUrl)
       // Mettre à jour la réponse de l'API dans le composant parent
       setApiResponse(result.data);
       if (valeurInput == '0' && kilometrageConnu === 'oui' && result.data.IdVoiture) {
        setIdVoiture(result.data.IdVoiture)
       }
       if (kilometrageConnu === 'non') {
        setConsoVoyage(index, result.data.ConsoVoyage)
       }

       setLancer(false);
       setFormData({...formData, voiture: data});

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
      // setIdVoiture('');
    } finally {
      setLoading(false);
    }
  };
  return (null);
};

export default AppelAPIVoiture;
