import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from '../../Titre';
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import "./form.css";
import Compteur from '../../Compteur/Compteur';
import BilanInter from '../../BilanInter';
import Bouton from '../Bouton';
import CheckboxChoixUnique from '../checkBox/checkbox';
import BilanInterEnt from '../../BilanInter/indexEntreprise';

const FormDeplacement2 = ({}) => {
  const navigate = useNavigate();
  const [Flaute, setFlaute] = useState(false);
  const [consommationConnue, setConsommationConnue] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [categories, setCategories] = useState([
    {
      titre: 'Voiture',
      compteurs: [
        { titre: 'Kilométrage Essence', unite: 'km', valeur: '0' },
        { titre: 'Kilométrage Diesel', unite: 'km', valeur: '0' },
        { titre: 'Kilométrage Hybride', unite: 'km', valeur: '0' },
        { titre: 'Kilométrage Electrique', unite: 'km', valeur: '0' }
      ]
    },
    {
      titre: 'Moto',
      compteurs: [
        { titre: 'Kilométrage Essence', unite: 'km', valeur: '0' },
        { titre: 'Kilométrage Diesel', unite: 'km', valeur: '0' },
        { titre: 'Kilométrage Hybride', unite: 'km', valeur: '0' },
        { titre: 'Kilométrage Electrique', unite: 'km', valeur: '0' }
      ]
    },
    {
      titre: 'Transport en commun',
      compteurs: [
        { titre: 'Kilométrage Métro/Tram', unite: 'km', valeur: '0' },
        { titre: 'Kilométrage RER/Transilien', unite: 'km', valeur: '0' },
        { titre: 'Kilométrage Bus & Autocars', unite: 'km', valeur: '0' },
      ]
    },
    {
      titre: 'Train',
      compteurs: [
        { titre: 'Kilométrage TGV & Grandes lignes', unite: 'km', valeur: '0' },
        { titre: 'Kilométrage TER', unite: 'km', valeur: '0' },
      ]
    }
  ]);

  const [categoriesConso, setcategoriesConso] = useState([
    {
      titre: 'Voiture',
      compteurs: [
        { titre: 'Consommation Essence', unite: 'L', valeur: '0' },
        { titre: 'Consommation Diesel', unite: 'L', valeur: '0' },
        { titre: 'Consommation Hybride', unite: 'L', valeur: '0' },
        { titre: 'Consommation Electrique', unite: 'L', valeur: '0' }
      ]
    },
    {
      titre: 'Moto',
      compteurs: [
        { titre: 'Consommation Essence', unite: 'L', valeur: '0' },
        { titre: 'Consommation Diesel', unite: 'L', valeur: '0' },
        { titre: 'Consommation Hybride', unite: 'L', valeur: '0' },
        { titre: 'Consommation Electrique', unite: 'L', valeur: '0' }
      ]
    },
    {
      titre: 'Transport en commun',
      compteurs: [
        { titre: 'Kilométrage Métro/Tram', unite: 'km', valeur: '0' },
        { titre: 'Kilométrage RER/Transilien', unite: 'km', valeur: '0' },
        { titre: 'Kilométrage Bus & Autocars', unite: 'km', valeur: '0' },
      ]
    },
    {
      titre: 'Train (TGV, Grandes lignes & TER)',
      compteurs: [
        { titre: 'Kilométrage TGV & Grandes lignes', unite: 'km', valeur: '0' },
        { titre: 'Kilométrage TER', unite: 'km', valeur: '0' },
      ]
    }
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log(formSubmitted);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/Entreprise/Voyage'); // Ajout de la navigation après la soumission du formulaire
  };

  const handleChangeCompteur = (categoryIndex, compteurIndex, value) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      const category = updatedCategories[categoryIndex];
      if (category) {
        const updatedCompteurs = [...category.compteurs];
        updatedCompteurs[compteurIndex].valeur = value;
        category.compteurs = updatedCompteurs;
      }
      return updatedCategories;
    });
  };


  return (
    <form onSubmit={handleSubmit}>
      <div className="conso">
        <div className="h61 vert">
        Consommation annuelle
        </div>
        <div className="p1">
          Renseigner le kilométrage cumulé sur la durée du bilan par tous les employés :
        </div>
  
        <div className="row">
          <div className="col-12">
            <CheckboxChoixUnique
              onChange={(value) => setFlaute(value)}
              titre="Possédez-vous une flotte de véhicule pour les déplacements professionnels ?"
              rep1="oui"
              rep2="non"
            />
          </div>
        </div>
  
        {Flaute && (
          <div>
            <div className="row">
              <div className="col-12">
                <CheckboxChoixUnique
                  onChange={(value) => setConsommationConnue(value)}
                  titre="Connaissez-vous votre consommation totale en carburant ?"
                  rep1="oui"
                  rep2="non"
                />
              </div>
            </div>
  
            {consommationConnue === "" ? (
              ""
            ) : consommationConnue ? (
              <div>
                {categoriesConso.map((category, categoryIndex) => (
                  <div key={categoryIndex}>
                    <div className="h61 vert" style={{ marginTop: "5vh" }}>
                      {category.titre}
                    </div>
                    <div className="row">
                      {category.compteurs.map((compteur, compteurIndex) => (
                        <div key={compteurIndex} className="col-12 col-md-6">
                          <Compteur
                            titre={compteur.titre}
                            unit={compteur.unite}
                            valeur={compteur.valeur}
                            onValeurChange={(value) =>
                              handleChangeCompteur(categoryIndex, compteurIndex, value)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {categories.map((category, categoryIndex) => (
                  <div key={categoryIndex}>
                    <div className="h61 vert" style={{ marginTop: "5vh" }}>
                      {category.titre}
                    </div>
                    <div className="row">
                      {category.compteurs.map((compteur, compteurIndex) => (
                        <div key={compteurIndex} className="col-12 col-md-5">
                          <Compteur
                            titre={compteur.titre}
                            unit={compteur.unite}
                            valeur={compteur.valeur}
                            onValeurChange={(value) =>
                              handleChangeCompteur(categoryIndex, compteurIndex, value)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
  
        <div className="row">
          <div className="Bouton col-12">
            <Bouton titre="Soumettre" />
          </div>
        </div>
  
        {formSubmitted && (
          <div>
            <div className="row">
              <div className="col-12">
                <BilanInterEnt voyage="non" numeroEtape={3} />
              </div>
            </div>
  
            <div className="row">
              <div className="col-12">
                <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
              </div>
            </div>
  
            {/* Affichage des valeurs des compteurs */}
            <div className="row">
              <div className="col-12">
                <h3>Valeurs des compteurs :</h3>
                {categories.map((category, categoryIndex) => (
                  <div key={categoryIndex}>
                    <h4>{category.titre}</h4>
                    <div className="row">
                      {category.compteurs.map((compteur, compteurIndex) => (
                        <div key={compteurIndex} className="col-12 col-md-5">
                          <p>
                            {compteur.titre}: {compteur.valeur} {compteur.unite}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
  
};

export default FormDeplacement2;
