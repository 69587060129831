import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Titre from '../../Titre';
import InputFieldNb from '../InputNombre';
import InputFieldNbUnique from '../InputNombre/indexUnit';
import Compteur from '../../Compteur/Compteur';


function FormDeplacementEquipe({ onSubmit }) {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false); // nouvel état pour suivre la soumission du formulaire

  const handleSubmit = (event) => {
  event.preventDefault();
  setFormSubmitted(true); // indique que le formulaire a été soumis
  console.log(formSubmitted);
  };

  const handleButtonClick = (event) => {
  event.preventDefault();
  navigate("/Evenement/Deplacement-Participant");
  };

  const [domicile, setDomicile] = useState({
  routier: {
    voiture: '',
    "2 roues": '',
    bus: ''
  },
  aerien: {
    sup: '',
    entre: '',
    inf: ''
  },
  ferroviaire: {
    metro: '',
    trainFrance: '',
    trainHorsFrance: ''
  }
  });

  const [hebergement, setHebergement] = useState({
  routier: {
    voiture: '',
    "2 roues": '',
    bus: ''
  },
  aerien: {
    sup: '',
    entre: '',
    inf: ''
  },
  ferroviaire: {
    metro: '',
    trainFrance: '',
    trainHorsFrance: ''
  }
  });

  const handleChange = (category, type, value, isHebergement = false) => {
  if (isHebergement) {
    setHebergement((prevHebergement) => ({
    ...prevHebergement,
    [category]: {
      ...prevHebergement[category],
      [type]: value
    }
    }));
  } else {
    setDomicile((prevDomicile) => ({
    ...prevDomicile,
    [category]: {
      ...prevDomicile[category],
      [type]: value
    }
    }));
  }
  };


  return (
  <form onSubmit={handleSubmit}>
    <div className="row col-12">
    <div className='h61 vert'>Trajet Domicile - Evénement</div>
    <p className='p1'>Pour l’ensemble de l’événement, il faut multiplier les km parcourus par le nombre de personnes qui ont voyagé.</p>
    </div>

    <div className='row col-12'>
    <div className='h61 vert'>Déplacements routiers</div>
    </div>

    <div className='row col-12'>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="Voiture"
      unit="pers.km"
      value={domicile.routier.voiture}
      onChange={(value) => handleChange('routier', 'voiture', value)}
      />
    </div>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="2 roues"
      unit="pers.km"
      value={domicile.routier["2 roues"]}
      onChange={(value) => handleChange('routier', '2 roues', value)}
      />
    </div>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="Bus"
      unit="pers.km"
      value={domicile.routier.bus}
      onChange={(value) => handleChange('routier', 'bus', value)}
      />
    </div>
    </div>

    <div className='row col-12'>
    <div className='h61 vert'>Déplacements aériens</div>
    </div>

    <div className='row col-12'>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="Distance supérieure à 4000km"
      unit="pers.km"
      value={domicile.aerien.sup}
      onChange={(value) => handleChange('aerien', 'sup', value)}
      />
    </div>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="Entre 3000 et 4000km"
      unit="pers.km"
      value={domicile.aerien.entre}
      onChange={(value) => handleChange('aerien', 'entre', value)}
      />
    </div>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="Distance inférieure à 3000km"
      unit="pers.km"
      value={domicile.aerien.inf}
      onChange={(value) => handleChange('aerien', 'inf', value)}
      />
    </div>
    </div>

    <div className='row col-12'>
    <div className="SousTitre">
      <div className='h61 vert'>Déplacements ferroviaires</div>
    </div>
    </div>

    <div className='row col-12'>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="Métro/RER/Tramway"
      unit="pers.km"
      value={domicile.ferroviaire.metro}
      onChange={(value) => handleChange('ferroviaire', 'metro', value)}
      />
    </div>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="Train en France"
      unit="pers.km"
      value={domicile.ferroviaire.trainFrance}
      onChange={(value) => handleChange('ferroviaire', 'trainFrance', value)}
      />
    </div>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="Train hors France"
      unit="pers.km"
      value={domicile.ferroviaire.trainHorsFrance}
      onChange={(value) => handleChange('ferroviaire', 'trainHorsFrance', value)}
      />
    </div>
    </div>

    <div className="row col-12">
    <div className="col-12" style={{marginTop : "5vh"}}>
      <Titre Titre="Trajet Hébergement sur place - Evénement" />
      <p className="col-12">Indiquer le pourcentage de chaque mode de déplacement pour avoir un total de 100%</p>
    </div>
    </div>

    <div className='row col-12'>
    <div className="SousTitre">
      <div className='h61 vert'>Déplacements routiers</div>
    </div>
    </div>


    <div className='row col-12'>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="Voiture"
      unit="%"
      value={hebergement.routier.voiture}
      onChange={(value) => handleChange('routier', 'voiture', value, true)}
      />
    </div>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="2 roues"
      unit="%"
      value={hebergement.routier["2 roues"]}
      onChange={(value) => handleChange('routier', '2 roues', value, true)}
      />
    </div>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="Bus"
      unit="%"
      value={hebergement.routier.bus}
      onChange={(value) => handleChange('routier', 'bus', value, true)}
      />
    </div>
    </div>

    <div className='row col-12'>
    <div className="SousTitre">
      <div className='h61 vert'>Déplacements ferroviaire</div>
    </div>
    </div>

    <div className='row col-12'>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="Métro/RER/Tramway"
      unit="%"
      value={hebergement.aerien.sup}
      onChange={(value) => handleChange('aerien', 'sup', value, true)}
      />
    </div>
    <div className='col-md-6 col-12'>
    </div>
    <div className='col-md-6 col-12'>
    </div>
    </div>

    <div className='row col-12'>
    <div className="SousTitre">
      <div className='h61 vert'>Autres moyens de transport </div>
    </div>
    </div>

    <div className='row col-12'>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="Transport doux : marche/vélo"
      unit="%"
      value={hebergement.ferroviaire.metro}
      onChange={(value) => handleChange('ferroviaire', 'metro', value, true)}
      />
    </div>
    <div className='col-md-6 col-12'>
      <InputFieldNbUnique
      titre="Mode inconnu"
      unit="% (calcul automatique)"
      value={hebergement.ferroviaire.trainFrance}
      onChange={(value) => handleChange('ferroviaire', 'trainFrance', value, true)}
      />
    </div>
    <div className='col-md-6 col-12'>
    </div>
    </div>

    <div className="row col-12">
    <Compteur
      titre="Distance moyenne entre les sites d’hébergement et le lieu de l’événement"
      value={hebergement.ferroviaire.trainHorsFrance}
      onChange={(value) => handleChange('ferroviaire', 'trainHorsFrance', value, true)}
      />
   </div>

    <div className="row">
    <div className="col-12">
      <Bouton titre="Suivant : Déplacement - Participants" onClick={handleButtonClick}/>
    </div>
    </div>
  
    {/* {formSubmitted && ( 
    <div>
      <div className="row">
      <div className="col-12">
        <BilanInter numeroEtape={2} voyage="non" />
      </div>
      </div>
  
      <div className="row">
      <div className="col-12">
        <Bouton titre="Catégorie suivante" onClick={handleButtonClick}/>
      </div>
      </div>
    </div>
    )} */}
  
  </form>
  );
}  

export default FormDeplacementEquipe;
