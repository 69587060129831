import React from 'react';
import './Graphique.css';
import './ligne3.css';
import Bouton from '../Form/Bouton';

const Ligne3 = ({chiffre, unit, chiffre1, unit1}) => {
  // Données pour les éléments du graphique
  
  return (
    <div className="row">
    <div className="jaune col-9 text-center row">

      <div className="col-4 row align-items-center justify-content-center">
        <div className='TitreGrand '>{chiffre}</div>
        <div className='TitrePetit'>{unit}</div>
      </div>

      <div className="col-2 TitreGrand align-items-center justify-content-center">=</div>

      <div className="col-5 row align-items-center justify-content-center">
        <div className='TitreGrand'>{chiffre1}</div>
        <div className='TitrePetit'>{unit1}</div>
      </div>


      <div className="col-12 row">
        <p className = "p3">il vous faut multiplier votre quantité en tonne par 24 (par ex. pour 5 tonnes, le montant de la contribution sera de 5×24 soit 120 euros).</p>
      </div>

     </div>

    <div className="boutonL3 col-3 row align-items-center justify-content-center">

        <button type="button" className="custom-button" style={{fontSize : "14px", marginBottom : "2vh"}}>
         Créer un compte        
        </button>

        <Bouton titre = {"cONTRIBUER"}/>
    </div>
    </div>
  );
};

export default Ligne3;
